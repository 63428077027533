<div class="flex flex-row w-full items-center mb-6">
  <h1 class="m-0 mr-8 font-medium">{{ title | translate }}</h1>
</div>
<flow-stepper *ngIf="!initializingForm"
              [steps]="steps"
              [(activeStep)]="selectedIndex"
              (activeStepChange)="selectedTabChange($event)"
              [isEdit]="userId != null">
  <flow-tabs-item [error]="userValidationErrors.length > 0">
    <form [formGroup]="form" class="input-override">
      <div class="flex flex-col p-4 mb-5 text-brand-red rounded-lg bg-red-50"
           role="alert"
           *ngIf="userValidationErrors?.length > 0">
        <div class="flex flex-row items-center">
          <span class="font-medium">{{'createUser.validationErrors.requirementsMet' | translate}}</span>
        </div>
        <ul class="mt-1.5 list-disc list-inside"
            *ngFor="let validation of userValidationErrors">
          <li>{{validation}}</li>
        </ul>
      </div>

      <div class="grid grid-cols-3 gap-x-10 input-override">
        <div class="grid grid-cols-1 gap-4 col-span-1">
          <div class="flex flex-row mb-5">
            <app-icon stroke="#598FAE"
                      fill="#BFD6E4"
                      iconName="annotation-info"
                      size="xl"></app-icon>
            <h3 class="m-0 ml-3 text-text-primary font-semibold">{{'createUser.subTitles.generalInfo' | translate}}</h3>
          </div>

          <div class="flex flex-col">
            <mat-label>{{ 'createUser.form.email' | translate }} *</mat-label>
            <mat-form-field appearance="outline"
                            class="w-full">
              <input matInput
                     type="email"
                     formControlName="email">
            </mat-form-field>
          </div>

          <div class="flex flex-col" *ngIf="isAdmin && !userId">
            <mat-label>{{ 'createUser.form.role' | translate }} *</mat-label>
            <mat-form-field appearance="outline" class="multi-select-field w-full mt-1">
              <mat-select formControlName="roleId">
                <mat-option *ngFor="let role of userRoles" [value]="role.id">
                  {{role.name | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="flex flex-col bg-gray-50 p-4" *ngIf="form.getRawValue().roleId">
            <mat-label *ngIf="(form.getRawValue().roleId !== admin && (isAdmin || (!userId && !customerId)))">{{ 'createUser.form.customer' | translate }} *</mat-label>
            <mat-form-field *ngIf="(form.getRawValue().roleId !== admin && (isAdmin || (!userId && !customerId)))"
                            appearance="outline"
                            class="multi-select-field w-full bg-white"
                            aria-placeholder="Select">
              <mat-select formControlName="selectedCustomerId">
                <mat-option *ngFor="let customer of filteredCustomers"
                            [value]="customer.id">
                  {{ customer.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-label class="mt-4">{{ 'createUser.form.name' | translate }} *</mat-label>
            <div class="flex flex-row items-center">
              <mat-form-field appearance="outline" class="w-full bg-white">
                <input matInput type="text" formControlName="profileName" placeholder="Name">
              </mat-form-field>
              <div class="ml-2 flex items-center" *ngIf="form.getRawValue().roleId !== admin">
                <button mat-flat-button
                  *ngIf="(customerId && customersArray.length == 0) || !customerId"
                  (click)="addCustomerProfile()"
                  [disabled]="!form.getRawValue().profileName || !form.valid"
                   color="primary" class="min-w-0 p-1 mt-1 rounded">
                   <app-icon class="pointer" iconName="plus" size="xl" stroke="#fff" fill="#fff"></app-icon>
                </button>
              </div>
            </div>

            <div formArrayName="customersArray" *ngIf="form.getRawValue().roleId !== admin">
              <div *ngFor="let customer of customersArray.controls; let i = index" [formGroupName]="i">
                <div class="flex justify-between items-center bg-white mt-4 p-2">
                  <div class="flex flex-col">
                    <mat-label class="text-base">{{ customer.get('name')?.value }}</mat-label>
                    <mat-label class="text-sm text-gray-600">{{ customer.get('profileName')?.value }}</mat-label>
                  </div>
                  <button [disabled]="!form.valid" mat-icon-button (click)="removeCustomerProfile(i,customer)">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-4 col-span-2">
          <div class="row row-col text-xs">
            <p class="border-l-4 border-chathams-blue-600 pl-1"> {{'createUser.notes.title' | translate}}</p>
            <p>{{'createUser.notes.generalInfo' | translate}}</p>
          </div>
          <div class="flex flex-row justify-end">
            <div>
              <button type="button"
                      mat-stroked-button
                      class="btn-cancel mr-3"
                      [routerLink]="['/users']">
                {{ "global.cancel" | translate }}
              </button>
              <button [disabled]="(loading$.save | async) || form.invalid || (form.getRawValue().roleId == 1 && !form.getRawValue().profileName) || (form.getRawValue().roleId == 2 && customersArray?.length == 0)"
                      mat-flat-button
                      class="btn-save"
                      color="primary"
                      (click)="onSaveClick()">
                {{ (userId ? "global.save" : "global.continue") | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </flow-tabs-item>
  <flow-tabs-item [error]="userValidationErrors.length > 0" [disabled]="disablePermissions">
    <div class="w-full">
      <div class="flex flex-col p-4 mb-5 text-brand-red rounded-lg bg-red-50" role="alert" *ngIf="userValidationErrors?.length > 0">
        <div class="flex flex-row items-center">
          <span class="font-medium">{{'createUser.validationErrors.requirementsMet' | translate}}</span>
        </div>
        <ul class="mt-1.5 list-disc list-inside"
            *ngFor="let validation of userValidationErrors">
          <li>{{validation}}</li>
        </ul>
      </div>

      <ng-container *ngIf="form.getRawValue()?.roleId && form.getRawValue()?.roleId == admin; else showFormContent">
        <div class="flex flex-row justify-end">
          <button type="button"
                  mat-stroked-button
                  class="btn-cancel mr-3"
                  [routerLink]="['/users']">
            {{ "global.cancel" | translate }}
          </button>
        </div>

        <div class="flex flex-col flex-1 items-center mt-5">
          <app-icon width="380px"
                    height="212.88px"
                    iconName="tech-life-media"
                    size="lg"></app-icon>
          <p class="mt-4">{{ 'createUser.adminUser' | translate }}</p>
          <p class="mt-1">{{ 'createUser.adminMessage' | translate }}</p>
        </div>
      </ng-container>

      <ng-template #showFormContent>
        <div class="grid grid-cols-3 gap-x-10 input-override">
          <div class="grid grid-cols-1 gap-4 col-span-1">
            <div class="flex flex-row">
              <app-icon stroke="#598FAE"
                        fill="#BFD6E4"
                        iconName="lock"
                        size="xl"></app-icon>
              <h3 class="m-0 ml-3 text-text-primary font-semibold">{{'createUser.subTitles.permissions' | translate}}
              </h3>
            </div>
            <form [formGroup]="groupForm" class="input-override" *ngIf="groups.length > 0">
              <div class="flex flex-col gap-4">
                <div *ngFor="let group of groups">
                  <mat-label>{{ group.customerName }}</mat-label>
                  <mat-form-field appearance="outline" class="multi-select-field w-full mt-1">
                    <mat-select [formControlName]="'group' + group.customerId" placeholder="{{'createUser.chooseGroup' | translate}}" (selectionChange)="onGroupChange(group.customerId, $event.value)">
                      <mat-option [value]="null" selected>{{'createUser.chooseGroup' | translate}}</mat-option>
                      <mat-option *ngFor="let role of group.roles" [value]="role.id">
                        {{ role.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </div>

          <div class="grid grid-cols-2 gap-4 col-span-2">
            <div class="row row-col">
              <p class="border-l-4 border-chathams-blue-600 pl-1"> {{'createUser.notes.title' | translate}}</p>
              <div *ngIf="groups.length > 0;">
                <p>{{'createUser.notes.permissionInfo' | translate}}</p>
              </div>
              <div *ngIf="groups.length === 0;">
                <p>{{'createUser.notes.permissionGroupMissing' | translate}}</p>
              </div>
            </div>
            <div class="flex flex-row justify-end">
              <div>
                <button type="button"
                        mat-stroked-button
                        class="btn-cancel mr-3"
                        [routerLink]="['/users']">
                  {{ "global.cancel" | translate }}
                </button>
                <button [disabled]="!userId || (loading$.save | async)"
                        mat-flat-button
                        class="btn-save"
                        color="primary"
                        (click)="onPermissionSave()">
                  {{ "global.save" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <div class="grid grid-cols-1 mt-10">
        <flow-permissions-table [permissionList]="permissionList"
                                [selectablePermissions]="selectablePermissions"
                                [headerTitle]="'createUser.form.customer' | translate"
                                [isEditable]="isEditable">
        </flow-permissions-table>
      </div>
    </div>
  </flow-tabs-item>
</flow-stepper>
