import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'flow-cloud-storage',
  templateUrl: './cloud-storage.component.html',
  styleUrls: ['./cloud-storage.component.scss']
})
export class CloudStorageComponent implements OnInit {
  @Input() sizeOfMediaAndFolders: number;
  @Input() storageLimitSize: number;
  @Input() showSizeOfFolder: boolean;
  @Input() sizeOfFolder: number;
  iconColor: string = '#DADADA';
  textColorClass: string = 'text-gray-900';

  constructor() { }

  ngOnInit(): void {
    this.calculateIconColors();
  }

  gbToBytes(gb: number): number {
    const bytesPerGB = 1024 ** 3;
    return gb * bytesPerGB;
  }

  calculateUsedStoragePercentage(): number {
    const storageLimitSizeInBytes = this.gbToBytes(this.storageLimitSize);  
    const usedPercentage = (this.sizeOfMediaAndFolders / storageLimitSizeInBytes) * 100;
    return Math.min(usedPercentage, 100);
  }

  calculateIconColors(): void {
    const usedPercentage = this.calculateUsedStoragePercentage();

    if (usedPercentage === 100) {
      this.iconColor = '#fc4b47';
      this.textColorClass = 'text-brand-red';
    } else if (usedPercentage >= 90) {
      this.iconColor = '#fc4b47';
    } else if (usedPercentage >= 80) {
      this.iconColor = '#fa6200';
    } else {
      this.iconColor = '#177578';
    }
  }

}
