
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, of, takeUntil, Subject } from 'rxjs';
import { DateTime } from 'luxon';

import { BookingList } from '@api/models/booking';
import { Category } from '@api/models/category';
import { Client } from '@api/models/client';
import { EngagementRequest } from '@api/models/engagement-request';
import { ActiveReportEnum } from '@api/models/active-report-enum';
import { IdName } from '@api/models/idname';
import { PaginatorParams } from '@api/models/paginator';
import { StatisticsFilterInputs } from '@app/models/statistics-filter-inputs';
import { ClientApi } from '@api/services/client-api';
import { Customer, CustomerApi, Engagement, EngagementsAdminApi, EngagementTypeEnum, Placement, ReportsResponse, ScreenApi, StatisticsApi, TimeframeEnum } from '@api/index';
import { MonthTranslations } from '@app/core/translations';
import { AuthService, UserRoleEnum } from '@core/auth/auth.service';
import { EnumService } from '@core/services/enum.service';
import { TranslateConfigService } from '@core/translate-config.service';
import { StatisticsService } from '../statistics.service';
import filterConfig from './reports-filter-config';
import reportsFormConfig from './reports-form-config';
import { ChannelsApi } from '@api/services/channels-api';
import { BookingPageExposureDetails } from '@app/admin/engagement/bookings/model/bookings.model';

@Component({
  selector: 'flow-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() reportType: ActiveReportEnum;
  @Input() currentPage?: number;
  @Input() selectedIndex?: number;
  @Input() fetchedData?: any;
  @Input() permissions?: string[];
  @Output() fetchNextBookings = new EventEmitter();
  @Output() searchBookings = new EventEmitter();
  isAdmin: boolean;
  customerId: number;
  placements: Placement[] = [];
  engagements: Engagement[] = [];
  medias: IdName[];
  filteredEngagements: Engagement[];
  filteredMedias: IdName[];
  reports: ReportsResponse;
  isDownloadEnabled: boolean = false;
  activeReport: ActiveReportEnum = ActiveReportEnum.PeopleCounter;
  ActiveReportEnum = ActiveReportEnum;
  title: string = 'reports.titlePeopleCounter';
  info: string = 'reports.infoPeopleCounter';
  startDate: any;
  endDate: any;
  preselectedStartDate: any;
  preselectedEndDate: any;
  filtersData = {
    channelIds: [],
    screenIds: [],
    placement: [],
    bookingNumbers: [],
    customerId: []
  };
  preSelectedFilters = false;
  categories: Category[];
  pois: IdName[];
  filteredPOIs: IdName[];
  includeAdminDetailsPOI: boolean;
  includeSmsDetailsPOI: boolean;
  includeVehicleDataReach: boolean;
  includeAvgTimeInChannel: boolean;
  isFilterDataReady: boolean = false;
  filterInputs = new Map<string, StatisticsFilterInputs>();
  timeframeTypes = [];
  trackingTypes = [];
  engagementTypes = [];
  clients: Client[] = [];
  nonAdmincustomerIds: number[];
  params: PaginatorParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'Date',
    sortDirection: 'asc'
  }
  destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private channelsApi: ChannelsApi,
    private customerApi: CustomerApi,
    private screenApi: ScreenApi,
    private formBuilder: FormBuilder,
    private enumService: EnumService,
    private cdr: ChangeDetectorRef,
    private statisticsApi: StatisticsApi,
    private translateConfigService: TranslateConfigService,
    private activatedRoute: ActivatedRoute,
    private engagementsAdminApi: EngagementsAdminApi,
    public statisticsService: StatisticsService,
    private clientApi: ClientApi
  ) {
    this.statisticsService.form = this.formBuilder.group({});
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.routeConfig.path == 'statistics/exposures-reports' || this.reportType === ActiveReportEnum.Exposures) {
      this.activeReport = ActiveReportEnum.Exposures;
      this.title = 'reports.titleExposures';
      this.info = 'reports.infoExposures';
    } else if (this.activatedRoute.snapshot.routeConfig.path === "statistics/reach-reports" || this.reportType === ActiveReportEnum.Reach) {
      this.title = 'reports.titleReachReports';
      this.info = 'reports.infoReach';
      this.activeReport = ActiveReportEnum.Reach;
    } else if (this.activatedRoute.snapshot.routeConfig.path === 'statistics/poi-reports' || this.reportType === ActiveReportEnum.POI) {
      this.title = 'reports.titlePOI';
      this.activeReport = ActiveReportEnum.POI;
      this.info = 'reports.infoPOI';
    }
    this.timeframeTypes = this.enumService.timeframeTypes;
    this.trackingTypes = this.enumService.trackingTypes;
    this.engagementTypes = this.enumService.engagementTypes;


    const user = this.authService.userData;
    this.customerId = user?.currentCustomerId || user?.customerId;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
    this.initForm();
    this.getActivatedRoute();
  }

  getActivatedRoute(): void {
    this.activatedRoute.data.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      const preselectedEngagements: BookingPageExposureDetails = data?.engagement;
      this.getData(preselectedEngagements);
    })
  }

  getData(preselectedEngagements?: BookingPageExposureDetails): void {
    forkJoin([
      this.isAdmin ? this.customerApi.getCustomers() : this.customerApi.getCustomerById(this.customerId),
      this.activeReport == ActiveReportEnum.Exposures ? this.clientApi.getClients(this.isAdmin ? undefined : [this.customerId]) : of(null)
    ])
      .pipe(takeUntil(this.destroy$)).subscribe(([response, clients]) => {
        let customers = (Array.isArray(response) ? response : [response]) as Customer[];
        this.clients = clients;
        this.isFilterDataReady = true;
        if(preselectedEngagements) {
          this.preselectedEngagements(preselectedEngagements, customers);          
        } else {
          this.fillInputData('customerIds', customers);
          if (this.customerId) {
            this.fillInputData('clientIds', clients);
          }
          if (!this.isAdmin) {
            this.fillInputData('channelIds', customers[0].channels);
            this.nonAdmincustomerIds = customers.map(c => c.id);
            this.statisticsService.form.get('customerIds').setValue(this.nonAdmincustomerIds);
            this.statisticsService.form.get('customerIds').disable({ onlySelf: true });
            if (this.activeReport === ActiveReportEnum.POI)
              this.getCategories([this.customerId]);
          }
        }
      });
  }

  preselectedEngagements(preselectedEngagements: BookingPageExposureDetails, customers: Customer[]): void {
    this.preSelectedFilters = true;
    const uniqueScreens = preselectedEngagements.screens
    const uniqueChannels = preselectedEngagements.channels;
    const engagementsArray = [{
      id: preselectedEngagements.booking?.id,
      name: preselectedEngagements.booking.name,
      // presentationId: preselectedEngagements?.engagement?.presentationId
    }];
    this.preselectedStartDate = preselectedEngagements?.startDate;
    this.preselectedEndDate = preselectedEngagements?.endDate;
    this.placements = preselectedEngagements?.placements.map((placement) => {
      return {
        id: placement.id,
        name: placement.name
      } 
    })

    this.fillInputData('customerIds', customers);
    this.fillInputData('channelIds', uniqueChannels);
    this.fillInputData('screenIds', uniqueScreens);
    this.fillInputData('placement', this.placements);
    this.fillInputData('bookingNumbers', engagementsArray);

    this.filtersData['customerId'] = [preselectedEngagements.customer.id];
    this.filtersData['channelIds'] = uniqueChannels.map(c => c.id);
    this.filtersData['screenIds'] = uniqueScreens.map(s => s.id);
    this.filtersData['placement'] = this.placements.map(p => p.id);
    this.filtersData['bookingNumbers'] = engagementsArray.map(e => e.id);
    this.setFiltersValue();
    this.getMediaFiles(this.filtersData['bookingNumbers']);
  }

  setFiltersValue(): void {
    this.statisticsService.form.get('customerIds').setValue(this.filtersData.customerId);
    this.statisticsService.form.get('channelIds').setValue(this.filtersData.channelIds);
    this.statisticsService.form.get('screenIds').setValue(this.filtersData.screenIds);
    this.statisticsService.form.get('placement').setValue(this.placements.map(p => p.id));
    this.statisticsService.form.get('bookingNumbers').setValue(this.filtersData.bookingNumbers);
    this.statisticsService.form.get('timeframe').setValue(TimeframeEnum.Custom);
    this.statisticsService.form.get('start').setValue(this.preselectedStartDate);
    this.statisticsService.form.get('end').setValue(this.preselectedEndDate);
  }

  ngAfterViewInit(): void {
    if (this.preSelectedFilters) {
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fetchedData && changes.fetchedData.previousValue && changes.fetchedData.currentValue) {
      this.fillInputData('bookingNumbers', changes.fetchedData.currentValue);
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getChannels(customerIds?: number[]) {
    if (this.preSelectedFilters) {
      this.statisticsService.form.get('channelIds').setValue(this.filtersData.channelIds);
    }
    let callApi = customerIds && customerIds.length > 0 ? this.channelsApi.getChannels({ customerIds }) : of(null);
    callApi.pipe(takeUntil(this.destroy$)).subscribe((channelsResponse) => {
      this.fillInputData('channelIds', channelsResponse || []);
      if (this.preSelectedFilters) {
        this.statisticsService.form.get('bookingNumbers').setValue(this.filtersData.bookingNumbers)
      }
    });
  }

  getScreens(channelIds: number[]) {
    // Add includeSensors param to getScreens as false on Exposures report
    const includeSensors = this.activeReport === ActiveReportEnum.PeopleCounter || this.activeReport === ActiveReportEnum.POI;
    const tracking = this.statisticsService.form.get('tracking');
    const trackingTypes = tracking ? tracking.value : [];

    const fetchScreens = (channelIds: number[]) => {
      this.statisticsApi.getFilteredScreensByPlacements(channelIds, trackingTypes, includeSensors)
      .pipe(takeUntil(this.destroy$))
        .subscribe((data: IdName[]) => {
          if (!this.preSelectedFilters) {
            this.fillInputData('screenIds', data || []);
            this.getEngagements();
          } else {
            const screen = [data.find((x: IdName) => x.id === this.filtersData.screenIds[0])];
            this.fillInputData('screenIds', screen);
            this.toggleSelectDeselect(true, 'screenIds', screen);
            this.toggleSelectDeselect(true, 'bookingNumbers', this.engagements);
          }
        });
    };

    if (channelIds.length > 0) {
      fetchScreens(channelIds);
    } else {
      this.fillInputData('screenIds', []);
    }
  }

  getPlacements() {
    this.placements = [];
    if (this.statisticsService.form.get('customerIds').value.length === 0 || this.statisticsService.form.get('channelIds').value.length === 0) {
      this.fillInputData('placement', []);
      return;
    }

    let channelIds: number[] = [];

    if (this.statisticsService.form.get('channelIds').value && this.statisticsService.form.get('channelIds').value.length > 0) {
      channelIds = this.statisticsService.form.get('channelIds').value;
    }

    this.screenApi.getUniquePlacements({ channelIds }).pipe(takeUntil(this.destroy$)).subscribe({
      next: (placements) => {
        this.placements = placements;
        
        const formatedPlacements = this.placements.map(p => ({ id: p.id, name: p.placement }));
        const placementsArray = formatedPlacements.length > 0 ? formatedPlacements : [];
        this.fillInputData('placement', placementsArray);
        
        const placementsIds = formatedPlacements.map(p => p.id);
        this.statisticsService.form.get('placement').setValue(placementsIds);     

        this.getScreens(placementsIds);
      },
      error: () => {
        console.log('error');
      }
    });
  }

  getEngagements() {
    this.engagements = [];
    this.filteredEngagements = [];
    let isScreensIdsArrayNull = this.statisticsService.form.get('screenIds').value == null || this.statisticsService.form.get('screenIds')?.value?.length == 0;
    let isBookingTypesArrayNull = false;

    if (this.statisticsService.form.get('bookingTypes')) {
      isBookingTypesArrayNull = this.statisticsService.form.get('bookingTypes').value == null || this.statisticsService.form.get('bookingTypes')?.value?.length == 0;
    }

    let isTimeFrameNull = this.statisticsService.form.get('timeframe').value == null;
    let isStartEndDateNull = this.checkTimeFrame() ? (this.statisticsService.form.get('start').value == null || this.statisticsService.form.get('end').value == null)
      : (!this.startDate || !this.endDate);

    if (isTimeFrameNull || isStartEndDateNull || isScreensIdsArrayNull || isBookingTypesArrayNull || this.placements.length == 0) {
      return
    };

    let body: EngagementRequest = {
      customerIds: this.statisticsService.form.get('customerIds').value || [],
      startDate: this.statisticsService.form.get('start').value,
      endDate: this.statisticsService.form.get('end').value,
      channelIds: [],
      screenIds: this.statisticsService.form.get('screenIds').value || [],
      clientIds: this.statisticsService.form.get('clientIds').value || [],
      name: "",
      types: this.statisticsService.form.get('bookingTypes').value || [],
      placements: this.placements.map(p => { return p.id })
    };
    if (this.getTimeFrame()) {
      body.startDate = this.getTimeFrame()[0];
      body.endDate = this.getTimeFrame()[1];
    }
    this.engagementsAdminApi.getStatisticsEngagements({ body }).pipe(takeUntil(this.destroy$))
      .subscribe((engagements) => {
        this.engagements = engagements;
        this.filteredEngagements = engagements;
        this.fillInputData('bookingNumbers', engagements);
        // @ts-ignore
        const engagementItems = engagements.items ? engagements.items : engagements;
        this.getMediaFiles(engagementItems.map(e => e.id));
      })
  }

  getMediaFiles(engagementIds: number[]) {
    if (engagementIds.length === 0) return;
    this.engagementsAdminApi.getEngagementsMedias({ engagementIds }).pipe(takeUntil(this.destroy$))
      .subscribe((medias) => {
        this.medias = medias;
        this.filteredMedias = medias;
        this.fillInputData('mediaFiles', medias);
        if (this.preSelectedFilters) {
          this.toggleSelectDeselect(true, 'mediaFiles', medias)
          this.onCreateReport();
        }
      })
  }

  getClients(customerIds: number[]) {
    this.clientApi.getClients(customerIds).subscribe((clients) => {
      this.clients = clients;
      this.fillInputData('clientIds', clients || []);
    });
  }

  getCategories(customerIds: number[]) {
    this.toggleSelectDeselect(false, 'poiCategoryName', []);
    if (customerIds.length == 0) return;
    this.statisticsApi.getStatisticsCategories({ customerIds }).pipe(takeUntil(this.destroy$))
      .subscribe((categories) => {
        this.categories = categories;
        this.fillInputData('poiCategoryName', categories);
        this.statisticsService.form.get('poiCategoryName')?.setValue(this.categories.map(c => c.id));
        this.getPOI(customerIds);
      });
  }

  getPOI(customerIds: number[]) {
    this.toggleSelectDeselect(false, 'poiName', []);
    if (customerIds.length == 0 || this.statisticsService.form.get('poiCategoryName').value.length == 0) return;

    this.statisticsApi.getStatisticsPersonsOfInterest({ customerIds }).pipe(takeUntil(this.destroy$))
      .subscribe((pois) => {
        this.pois = pois;
        this.filteredPOIs = [{ id: -1, name: 'reports.form.includeDeletedPOI' }].concat(this.pois);
        this.fillInputData('poiName', this.filteredPOIs);
        this.statisticsService.form.get('poiName')?.setValue(this.pois.map(c => c.id));
        this.onCreateReport();
      });
  }

  reportShown(data) {
    if (this.activeReport == ActiveReportEnum.Exposures) {
      this.toggleSelectDeselect(true, 'mediaFiles', this.medias);
      this.preSelectedFilters = false;
    }
  }

  checkTimeFrame() {
    return this.statisticsService.form.get('timeframe')?.value === TimeframeEnum.Custom
  }

  getTimeFrame() {
    return (this.statisticsService.form.get('timeframe').value != 5 && this[`get${TimeframeEnum[this.statisticsService.form.get('timeframe').value]}`]()) || null;
  }

  getCurrentWeek() {
    const { start, end } = this.getStartWeekAndEndWeekDate();

    const startOfCurrentWeek = start.toISODate();
    const endOfCurrentWeek = end.toISODate();
    return [startOfCurrentWeek, endOfCurrentWeek]
  }

  getPreviousWeek() {
    const { start, end } = this.getStartWeekAndEndWeekDate();
    const startOfPreviousWeek = start.minus({ weeks: 1 }).toISODate();
    const endOfPreviousWeek = end.minus({ weeks: 1 }).toISODate();
    return [startOfPreviousWeek, endOfPreviousWeek];
  }

  private getStartWeekAndEndWeekDate() {
    const today = DateTime.local();
    const start = today.startOf('week');
    const end = today.endOf('week');
    return { start, end };
  }

  getCurrentMonth() {
    const { start, end } = this.getStartMonthAndEndMonthDate();
    const startOfCurrentMonth = start.toISODate();
    const endOfCurrentMonth = end.toISODate();
    return [startOfCurrentMonth, endOfCurrentMonth]
  }

  getPreviousMonth() {
    const { start, end } = this.getStartMonthAndEndMonthDate();
    const startOfPreviousMonth = start.minus({ month: 1 }).toISODate();
    const endOfPreviousMonth = end.minus({ month: 1 }).endOf('month').toISODate();
    return [startOfPreviousMonth, endOfPreviousMonth]
  }
  private getStartMonthAndEndMonthDate() {
    const today = DateTime.local();
    const start = today.startOf('month');
    const end = today.endOf('month');
    return { start, end }
  }

  generateBody() {
    let body = {
      ...this.params,
      ...this.statisticsService.form.value,
      customerIds: this.statisticsService.form.get('customerIds').value || [this.customerId]
    }
    if (this.statisticsService.form.get('timeframe').value === TimeframeEnum.Custom) {
      body.startDate = new Date(this.statisticsService.form.get('start').value);
      body.endDate = new Date(this.statisticsService.form.get('end').value);
    }

    if (this.statisticsService.form.get('timeframe').value !== TimeframeEnum.Custom) {
      let selectedTimeFrame = `get${TimeframeEnum[this.statisticsService.form.get('timeframe').value]}`;
      const [startDate, endDate] = this[selectedTimeFrame]();
      body.startDate = startDate;
      body.endDate = endDate;
    }

    if (this.title === 'reports.titleReachReports') {
      body.IncludeTrackingType = false;
    }

    if (this.title === 'reports.titlePeopleCounter') {
      body.IncludeTrackingType = true;
    }

    body.placements = this.statisticsService.form.get('placement').value;
    const { created, timeframe, placement, ...filteredBody } = body;

    return filteredBody;
  }

  onDownloadReport() {
    if (!this.statisticsService.form.valid) return;

    const body = this.generateBody();
    const callFunction = this.statisticsApi[`download${ActiveReportEnum[this.activeReport]}Reports`](body);

    callFunction.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      if (data) {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const a = document.createElement('a');
        const today = DateTime.local();
        const formattedDate = today.toISODate();
        a.href = window.URL.createObjectURL(blob);
        let text
        if (this.activeReport === ActiveReportEnum.Exposures) text = "BizLab Flow Exposures Report";
        if (this.activeReport === ActiveReportEnum.PeopleCounter) text = "BizLab Flow People Counter Report";
        if (this.activeReport === ActiveReportEnum.Reach) text = "BizLab Flow Reach Report";
        if (this.activeReport === ActiveReportEnum.POI) text = "BizLab Flow POI Report";
        a.download = `${text} ${formattedDate}`;
        a.click();
      }
    })
  }
  onCreateReport() {
    if (!this.statisticsService.form.valid) return;

    const body = this.generateBody();
    const callFunction = this.statisticsApi[`get${ActiveReportEnum[this.activeReport]}Reports`](body);

    callFunction.subscribe((reports) => {
      this.reports = reports;
      this.includeVehicleDataReach = reports.includeVehicleData || false;
      this.includeAdminDetailsPOI = body.includePOIAdministrativeDetails;
      this.includeSmsDetailsPOI = body.includeSMSNotifications;
      if (this.isAdmin)
        this.includeAvgTimeInChannel = body.includeAverageTimeInChannel;
      this.isDownloadEnabled = true;
    })
  }

  loadMoreItems(event?: any) {
    this.fetchNextBookings.emit(event);
  }

  searchBooking(value: string) {
    this.searchBookings.emit(value);
  }

  getNewReport(event) {
    this.params = event;
    this.onCreateReport();
  }

  translateMonth(month: string) {
    if (month.length > 0)
      return `${this.translateConfigService.instant(MonthTranslations[month])}`
    else return '';
  }

  toggleSelectDeselect(selectAll: boolean, formControlName: string, array: any): void {
    if (this.activeReport === ActiveReportEnum.Exposures) {
      if (selectAll)
        this.statisticsService.form.get(formControlName).setValue(array.map((a) => a.id));
      else
        this.statisticsService.form.get(formControlName).setValue([]);
    }
  }

  stopEventPropagation(event: Event): void {
    event.stopPropagation();
  }

  // Statistics form START
  private initForm(): void {
    let filterArray: string[] = ['timeframe', 'start', 'end', 'customerIds'];

    const filterMapping = {
      [ActiveReportEnum.Reach]: filterConfig.reach,
      [ActiveReportEnum.Exposures]: filterConfig.exposures,
      [ActiveReportEnum.PeopleCounter]: [ ...filterConfig.peopleCounter, ...(this.isAdmin ?  ['includeAverageTimeInChannel'] : [])],
      [ActiveReportEnum.POI]: filterConfig.poi,
    };

    const filters = filterMapping[this.activeReport];
    filterArray = [...filterArray,...filters];

    filterArray.forEach(item => {
      const filter = reportsFormConfig(item, this.activeReport);
      this.filterInputs.set(item, filter);
    });

    this.fillInputData('timeframe', this.timeframeTypes);
    this.fillInputData('tracking', this.trackingTypes);
    this.fillInputData('bookingTypes', this.engagementTypes);
    this.fillInputData('placement', [])
  }

  fillInputData(formControlName: string, data: any) {
    let item = this.filterInputs.get(formControlName);
    if (item) {
      const responseData = data?.items ? data?.items : data;
      const transformedArray: any[] = (responseData || []).map((item) => {
        const { id, name, presentationId } = item;
        return {
          id: formControlName == 'bookingTypes' ? EngagementTypeEnum[id] : id,
          name: presentationId ? `<div><strong>${presentationId}</strong>: ${name}</div>` :
            (name.split('-')[1] && formControlName == 'timeframe' ?
              `${name.split('-')[0]}-${this.translateMonth(name.split('-')[1])}`
              : name)
            || ''
        };
      });
      item.data = transformedArray;
      item.filteredData = transformedArray;
    }
  }

  // Construct an array of fields that need to be reset when a filter is changed
  resetFlow(formControlName: string) {
    const state = {
      customerIds: () => {
        const fields = ["screenIds", "channelIds", "placement"];

        if (this.activeReport === ActiveReportEnum.Exposures) {
          fields.push("mediaFiles", "bookingNumbers")
        }

        if(this.activeReport === ActiveReportEnum.POI) {
          fields.push("poiCategoryName", "poiName")
        }

        return fields;
      },
      channelIds: () => {
        const fields = ["screenIds"];

        if (this.activeReport === ActiveReportEnum.Exposures) {
          fields.push("mediaFiles", "bookingNumbers")
        }
        return fields;
      },
      placement: () => {
        const fields = [];

        if (this.activeReport === ActiveReportEnum.Exposures) {
          fields.push("mediaFiles", "bookingNumbers")
        }
        return fields;
      },
      screenIds: () => {
        const fields = [];

        if (this.activeReport === ActiveReportEnum.Exposures) {
          fields.push("mediaFiles", "bookingNumbers")
        }
        return fields;
      },
      poiCategoryName: () => {
        const fields = [];
        if(this.activeReport === ActiveReportEnum.POI) {
          fields.push("poiName")
        }
        return fields;
      },
      timeframe: () => {},
      tracking: () => {},
      end: () => {},
      start: () => {},
      bookingNumbers: () => {}
    }

    return state[formControlName]();
  }

  // Reset the form fields to their default values
  resetFilterFormFields(property: string) {
    const fields = this.resetFlow(property);

    fields.forEach(field => {
      this.statisticsService.form.get(field).setValue([]);
      this.fillInputData(field, []);
    });
  }

  onFilterChanged = (values: { filter: number[] | string[] | number | string, type: string, form: FormGroup, allFilters: any }): void => {
    this.isDownloadEnabled = false;

    switch (values.type) {
      case 'customerIds': {
        this.resetFilterFormFields('customerIds');     
        this.getChannels(values.allFilters.customerIds);
        this.activeReport === ActiveReportEnum.Exposures && this.getClients(values.allFilters.customerIds);
        this.activeReport === ActiveReportEnum.POI && this.getCategories(values.allFilters.customerIds);
      } break;
      case 'channelIds': {
        this.resetFilterFormFields('channelIds');
        this.getPlacements();        
      } break;
      case 'placement': {  
        this.resetFilterFormFields('placement');
        this.getScreens(this.statisticsService.form.get('placement').value);
      } break;
      case 'screenIds': {
        this.resetFilterFormFields('screenIds');
        this.activeReport === ActiveReportEnum.Exposures && this.getEngagements();
      } break;
      case 'poiCategoryName': {
        this.resetFilterFormFields('poiCategoryName');      
        this.getPOI(this.statisticsService.form.get('customerIds').value);
      } break;
      case 'timeframe': this.changeTimeframe(values.filter as number); break;
      case 'tracking': this.getScreens(this.statisticsService.form.get('placement').value); break;
      case 'end': this.activeReport === ActiveReportEnum.Exposures && this.getEngagements(); break;
      case 'start': this.activeReport === ActiveReportEnum.Exposures && this.getEngagements(); break;
      case 'bookingNumbers': this.activeReport === ActiveReportEnum.Exposures && this.getMediaFiles(values.allFilters.bookingNumbers); break;
    }
  }

  changeTimeframe(value: number) {
    if (value === TimeframeEnum.Custom) {
      this.statisticsService.form.get('start').setValidators(Validators.required);
      this.statisticsService.form.get('end').setValidators(Validators.required);
      this.startDate = undefined;
      this.endDate = undefined;
    }
    if (value !== TimeframeEnum.Custom) {
      this.statisticsService.form.get('start').removeValidators([Validators.required]);
      this.statisticsService.form.get('end').removeValidators([Validators.required]);
      this.statisticsService.form.get('start').setValue(null);
      this.statisticsService.form.get('end').setValue(null);
      const [startDate, endDate] = this[`get${TimeframeEnum[value]}`]();
      this.startDate = startDate;
      this.endDate = endDate;
    }
    if (this.activeReport === ActiveReportEnum.Exposures) this.getEngagements();
  }
  
  ngOnDestroy(): void {
    this.statisticsService.form = this.formBuilder.group({});
    this.destroy$.next();
    this.destroy$.complete();
  }
}
