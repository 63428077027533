<div mat-dialog-title
     class="flex flex-row justify-between border-b border-gray-200 p-0">
    <h3 class="font-semibold">{{'media.filters.title' | translate}}</h3>
    <a class="cursor-pointer"
       (click)="close()">
        <app-icon stroke="#202020"
                  size="md"
                  iconName="x"></app-icon>
    </a>
</div>

<mat-dialog-content class="overflow-hidden">
    <form [formGroup]="form"
          class="input-override">
        <div class="grid grid-cols-2 gap-4">

            <div class="flex flex-col"
                 *ngIf="types.length > 0">
                <mat-label>{{'media.filters.types' | translate}}</mat-label>
                <mat-form-field class="w-full">
                    <mat-select formControlName="filterTypes"
                                multiple>
                        <mat-option *ngFor="let type of types"
                                    [value]="type.id">
                            {{type.name | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="flex flex-col">
                <mat-label>{{'media.filters.tags' | translate}}</mat-label>
                <mat-form-field class="w-full">
                    <mat-select formControlName="tags"
                                multiple>
                        <mat-option *ngFor="let tag of tags"
                                    [value]="tag.id">
                            {{tag.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

    </form>
</mat-dialog-content>
<mat-dialog-actions class="mt-5">
    <div class="flex flex-row justify-end w-full items-center">
        <button class="btn-save"
                type="button"
                color="primary"
                mat-flat-button
                (click)="onSubmit()">
            {{ 'media.filters.showNumberOfMedias' | translate: {number: ''} }}
        </button>
    </div>
</mat-dialog-actions>