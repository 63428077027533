import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { Router } from "@angular/router";
import { defer, of, switchMap, tap } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

import { AppService } from "@app/app.service";
import { Customer, UsersApi } from "@api/index";
import { LanguageModalComponent } from "@shared/language-modal/language-modal.component";
import { UserProfileResponse } from '@api/models/user-login-response';
import { unsubscribeMixin } from "../unsubscribe";
import { AuthService, UserRoleEnum } from "../auth/auth.service";
import { TranslateConfigService } from "../translate-config.service";
import { CurrentUser } from "../auth/current-user";

@Component({
  selector: 'flow-nav-settings',
  templateUrl: './nav-settings.component.html',
  styleUrls: ['./nav-settings.component.scss']
})
export class NavSettingsComponent extends unsubscribeMixin() implements OnInit {
  @Input() adminUser!: { id: number, name: string };
  @Input() currentUser!: CurrentUser;
  @Input() initials!: string;
  @Input() customers!: Customer[];
  @Output() closeNavSettings = new EventEmitter<boolean>();

  selectedCustomer: Customer;
  isAdmin: boolean = false;
  currentLanguage: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private usersApi: UsersApi,
    public appService: AppService,
    private dialog: MatDialog,
    private transalteConfigService: TranslateConfigService,
  ) {
    super();
  }

  ngOnInit(): void {
    const user = this.authService.userData;
    this.isAdmin = user?.roleId === UserRoleEnum.Admin;
    this.currentLanguage = this.transalteConfigService.currentLanguage;
    this.setSelectedCustomer(user);
  }

  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl('/login');
    this.closeNavSettings.emit(true);
  }

  closeNav() {
    this.closeNavSettings.emit(true);
  }

  onCustomerChange(customer: number) {
    const newCustomerId = customer === -1 ? null : customer;

    defer(() => {
      return this.isAdmin ? of(this.authService.user) : this.usersApi.selectProfile(newCustomerId, this.authService.user?.email).pipe(
        tap((customer: any) => {
          this.authService.updateUserInfo(customer.email, customer.name);
          this.authService.processToken({ token: customer.token, refreshToken: customer.refreshToken });
          this.authService.setLanguage(customer.language);
        })
      )
    }).pipe(
      switchMap((customer: any) => this.setCustomer(newCustomerId, customer))
    ).subscribe({
      next: (currentUser: CurrentUser) => {
        this.setSelectedCustomer(currentUser);
        this.router.navigate(['/home']).then(() => window.location.reload());
      },
      error: () => { }
    })
  }

  setCustomer(newCustomerId: number, customer: UserProfileResponse) {
    const currentUser = this.authService.setCustomer({ ...customer, customerId: this.authService.user?.customerId }, newCustomerId);
    return of(currentUser);
  }

  setSelectedCustomer(currentUser) {
    const id = currentUser.currentCustomerId || currentUser.customerId;
    const customer = this.customers?.find(c => c.id === id) || this.adminUser;
    this.selectedCustomer = customer;
  }

  checkIfAdminCustomerOrUser() {
    return this.isAdmin && this.selectedCustomer?.id === -1;
  }

  openLanguageModal(): void {
    this.closeNavSettings.emit(true);
    this.dialog.open(LanguageModalComponent, {
      panelClass: 'new-modal',
      width: '600px',
      height: '294px',
      data: {
        changeLanguage: (lang: string) => this.changeLanguage(lang)
      }
    });
  }

  changeLanguage(lang: string) {
    const newLanguage = lang
    this.transalteConfigService.changeLanguage(newLanguage)
    this.currentLanguage = newLanguage
  }
}
