import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs";
import jwt_decode from 'jwt-decode';

import { unsubscribeMixin } from "@core/unsubscribe";
import { TranslateConfigService } from "@core/translate-config.service";
import { SystemMessageService } from "@core/services/system-message.service";
import { MediaApi } from "@api/services/media-api";
import { SelectedFile, MediaFormat } from "@models/media-files";
import { TokenSettings } from './media-upload-external.model';

@Component({
  selector: 'flow-media-upload-external',
  templateUrl: './media-upload-external.component.html',
  styleUrls: ['./media-upload-external.component.scss']
})
export class MediaUploadExternalComponent extends unsubscribeMixin() implements OnInit {
  token: string;
  settings: TokenSettings;
  selectedBody: SelectedFile;
  isDragging = false;
  filesToUpload: SelectedFile[] = [];
  uploadProgress = 0
  uploading = false
  invalidToken = false;
  availableMediaToUpload: number = 0;
  isCompleted = false;
  errorUploading: string = "";
  remainingFilesLimit: number;
  loading = true;

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(private route: ActivatedRoute,
    private systemMessageService: SystemMessageService,
    private translateConfigService: TranslateConfigService,
    private mediaApiService: MediaApi) {
    super()
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('id');
    this.settings = this.decodeToken(this.token);
    this.getMediaTransferAvailableFileCount(this.token);
  }

  private decodeToken(token: string): TokenSettings {
    try {
      return jwt_decode(token);
    } catch (error) {
      this.invalidToken = true;
      return null;
    }
  }

  private getMediaTransferAvailableFileCount(token: string) {
    const body = {
      token: token
    }

    this.mediaApiService.getMediaTransferAvailableFileCount(body).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (remainingFileCount: number) => {
          this.availableMediaToUpload = remainingFileCount;
          this.remainingFilesLimit = this.availableMediaToUpload
        },
        error: (error) => {
          console.error('File upload error:', error);
        },
        complete: () => {
          this.loading = false;
        }
      });
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  upload() {
    const body = {
      token: this.token as string,
      files: this.filesToUpload
    }

    this.mediaApiService.uploadMediaForExternalUsers(body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (progress: number) => {
          this.uploading = true;
          this.uploadProgress = progress;
        },
        error: (error) => {
          console.error('File upload error:', error);
          this.errorUploading = error.title;
        },
        complete: () => {
          this.isCompleted = true;
        }
      });
  }

  selectFile(event: Event): void {
    const input = event.target as HTMLInputElement;
    const selectedFiles = input.files;

    if (!selectedFiles) {
      console.warn('No file selected');
      return;
    }

    if (selectedFiles.length > this.remainingFilesLimit) {
      const errMsg = this.remainingFilesLimit === 0 || this.remainingFilesLimit === +this.settings.limitForNumberOfLinks ? `The max limit for files is ${+this.settings.limitForNumberOfLinks}` : `The remaining limit for files is ${this.remainingFilesLimit}`
      this.systemMessageService.error(errMsg)
      return;
    }

    Array.from(selectedFiles).forEach((file) => {
      this.readyFile(file);
    });
  }

  async readyFile(selectedFile) {
    const file = {
      index: null,
      selectedFile,
      fileSize: selectedFile.size,
      filePreview: null,
      name: selectedFile.name,
      format: this.getFileFormat(selectedFile),
    };

    if (!this.isValidFileType(file?.selectedFile?.type)) {
      this.systemMessageService.error(this.translateConfigService.instant("createMedia.error.supports"))
      return;
    }

    let sizeInKB = +this.settings.fileSize * 1000000
    if (selectedFile.size > sizeInKB) {
      this.systemMessageService.error(`The file with name ${selectedFile.name} is to big. Max file size is ${+this.settings.fileSize}MB `)
      return;
    }

    if (file?.format === MediaFormat.Video) {
      try {
        const duration = await this.getVideoDuration(selectedFile)
        file.selectedFile.videoDuration = duration.toFixed(2);
      } catch (error) {
        file.selectedFile.videoDuration = 0;
      }
    }

    const reader = new FileReader();

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      file.filePreview = e.target?.result as string;

      this.selectedBody = file;
      this.filesToUpload.push(file);
      this.remainingFilesLimit = this.availableMediaToUpload - this.filesToUpload.length;
    };

    reader.onerror = () => {
      console.error('File reading failed');
    };

    reader.readAsDataURL(selectedFile);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;

    const droppedFiles = event.dataTransfer?.files;
    if (droppedFiles) {
      Array.from(droppedFiles).forEach((file) => {
        this.readyFile(file);
      });
    }
  }

  isValidFileType(fileType: string): boolean {
    const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];
    return allowedTypes.includes(fileType);
  }

  getFileFormat(file: File): MediaFormat {
    if (file.type.startsWith('image/')) {
      return MediaFormat.Image;
    }

    return MediaFormat.Video;
  }

  getVideoDuration(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = window.URL.createObjectURL(file);
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };
      video.onerror = (error) => {
        reject(error);
      };
    });
  }

  removeFile(i: number) {
    this.filesToUpload.splice(i, 1);
    this.remainingFilesLimit = this.availableMediaToUpload - this.filesToUpload.length
  }
}