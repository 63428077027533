import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpEventType } from '@angular/common/http';
import { uniqueId } from 'lodash-es';
import {
  Subject,
  catchError,
  concatMap,
  filter,
  forkJoin,
  from,
  of,
  takeUntil,
  tap,
} from 'rxjs';

import { Customer, CustomerApi, MediaFile, MediaTypeEnum, StandardMedia, Tag } from '@api/index';
import { AppService } from '@app/app.service';
import { AuthService, UserRoleEnum } from '@core/auth/auth.service';
import { SystemMessageService } from '@core/services/system-message.service';
import { TranslateConfigService } from '@core/translate-config.service';
import { unsubscribeMixin } from '@core/unsubscribe';
import { CustomSnackbarComponent } from '@shared/custom-snackbar/custom-snackbar.component';
import { Client } from '@api/models/client';
import { ClientApi } from '@api/services/client-api';
import { TagsApi } from '@api/services/tags-api.service';
import { MediaApi } from '@api/services/media-api';
import { environment } from '@environments/environment';
import { MediaModalService } from '@core/services/media-modal.service';
import { MediaFilesFormGroup, SelectedFile, MediaFiles, MediaFormat, UploadStatus } from '@app/models/media-files';
import { MediaService } from '@core/services/media.service';

@Component({
  selector: 'flow-media-create',
  templateUrl: './media-create.component.html',
  styleUrls: ['./media-create.component.scss'],
})
export class MediaCreateComponent
  extends unsubscribeMixin()
  implements OnInit, OnDestroy
{
  @ViewChild('top') topElement: ElementRef;
  cancelUploads$ = new Subject<void>();
  destroy$ = new Subject<void>()
  
  media: MediaFile;
  tags: Tag[];
  form: FormGroup;
  MediaTypeEnum = MediaTypeEnum;
  customers: Customer[];
  clients: Client[];
  isAdmin: boolean;
  customerId: number;
  medias: MediaFile[];
  mediasFormArray: FormArray<FormGroup<MediaFilesFormGroup>>;
  selectedFiles: SelectedFile[] = [];
  selectedMedia: MediaFiles;
  selectedBody: SelectedFile;
  uploadingFilesCount: number = 1;  // Added so that the progress loader will count uploading files instead uploaded
  fileSizes = [];
  uploadedData: number = 0;
  totalUploadedData: number = 0;
  mediaId: number = null;
  isReady: boolean = false;
  isNameUnique : boolean;
  apiBaseUrl: string = environment.apiBaseUrl;
  uploadingDone: boolean = false;
  disableUpload: boolean = false;
  uploadError: boolean = false;

  constructor(
    private customerApi: CustomerApi,
    private tagsApi: TagsApi,
    private authService: AuthService,
    private router: Router,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    private translateConfigService: TranslateConfigService,
    private dialog: MatDialog,
    private clientApi: ClientApi,
    private activatedRoute: ActivatedRoute,
    private mediaApi: MediaApi,
    private mediaModalService: MediaModalService,
    private mediaService: MediaService
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
  }

  ngOnInit(): void {
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId && !user.customerId;
    this.customerId = user?.currentCustomerId || user?.customerId;
    this.mediaId = +this.activatedRoute.snapshot.params['id'] || null;
    if (this.mediaId) this.getEditData();
    else {
      this.getData();
    }
  }

  override ngOnDestroy(): void {
    this.cancelUploads$.next();
    this.cancelUploads$.complete();
    this.destroy$.next();
  }

  private getEditData() {
    this.mediaApi.getMediaById(this.mediaId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((media: MediaFile) => {
        this.media = media;
        this.customerId = media.customer.id;
        forkJoin([
          this.customerApi.getCustomerById(this.customerId),
          this.tagsApi.getTags({ customerIds: [this.customerId] }),
          this.clientApi.getClients([this.customerId])
        ])
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(([customer, tags, clients]) => {
            this.customers = [customer];
            this.tags = tags;
            this.clients = clients;
            this.initForm();
            this.isReady = true;
          });
      });
  }

  private getData() {
    forkJoin([
      this.tagsApi.getTags({ customerIds: this.customerId ? [this.customerId] : [] }),
      this.isAdmin ? this.customerApi.getCustomers() : of(null),
      !this.isAdmin ? this.customerApi.getCustomerById(this.customerId) : of(null),
      !this.isAdmin ? this.clientApi.getClients([this.customerId]) : of(null)
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([tags, customers, customer, clients]) => {
        this.customers = customers || [customer];
        this.tags = tags || [];
        this.clients = clients;
        this.initForm();
        this.isReady = true;
      });
  }

  getDataByCustomer() {
    const customerId = this.form.get('customerId').value;
    forkJoin([
      this.tagsApi.getTags({ customerIds: [customerId] }),
      this.clientApi.getClients([customerId]),
    ]).subscribe(([tags, clients]) => {
      this.tags = tags || [];
      this.clients = clients || [];
    });
  }

  onCancelUploads(): void {
    this.uploadingFilesCount = 0;
    this.fileSizes = [];
    this.uploadedData = 0;
    this.totalUploadedData = 0;
    this.cancelUploads$.next();
  }

  selectFile(event: any, id?: any): void {
    const updatedFile = {...event, index: id};
    this.selectedBody = updatedFile;
  }

  addMediaRow() {
    if (!this.selectedBody) {
      this.systemMessageService.error(this.translateConfigService.instant("notifications.error.pleaceSelectFile"));
      return;
    }
    if (!this.mediasFormArray.value[this.mediasFormArray.length - 1].name) {
      this.systemMessageService.error(this.translateConfigService.instant("notifications.error.nameRequired"));
      return;
    }
    
    const customerId = this.customerId || this.form?.getRawValue()?.customerId;
    const formArray = this.mediasFormArray.value;
    this.mediaService.handleMediaValidation(formArray , customerId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (isValid) => {
            if (isValid) {
              const lastMediaGroup = this.mediasFormArray.at(this.mediasFormArray.length - 1) as FormGroup;
              lastMediaGroup.get('selectedFile')?.setValue(this.selectedBody);
              this.mediasFormArray.push(this.toMediaFormGroup());
            } else {
              this.systemMessageService.error(
                this.translateConfigService.instant('notifications.error.uniqueNameRequired')
              );
            }
          },
          error: (err) => {
            this.systemMessageService.error(
              this.translateConfigService.instant('notifications.error.genericError')
            );
          }
        });
  }

  saveMediaRow() {
    this.selectedBody = null;
    this.selectedMedia = null;
  }
  onMediaFileSettingsRow(fg: FormGroup<MediaFilesFormGroup>, index: number): void {
    this.mediasFormArray.removeAt(index);
  }

  openViewMedia(standardMedia: StandardMedia, $event): void {
    $event.preventDefault();
    $event.stopPropagation();
    if (standardMedia.type !== MediaTypeEnum.Folder)
      this.mediaModalService.openViewMediaModal(standardMedia);
  }
  
  getVideoDuration(selectedFile: File, file: SelectedFile): void {
    const video = document.createElement('video');
    video.src = window.URL.createObjectURL(selectedFile);
    video.preload = 'metadata';
    video.onloadedmetadata = () => (file.videoDuration = +video.duration.toFixed(2));
  }
  gigabytesToBytes(gigabytes) {
    const bytesInGigabyte = 1024 * 1024 * 1024;
    const bytes = gigabytes * bytesInGigabyte;
    return bytes;
  }
  onCancelClick() {
    this.router.navigate(['/medias']);
  }
  onSaveClick(): void {
    if (this.mediaId) {
      if (!this.form.valid) return;
      const mediaValues = this.form.getRawValue();
      this.mediaApi.editMedia({
        id: this.media.id,
        customerId: mediaValues.customerId,
        name: mediaValues.name,
        tags: JSON.stringify(mediaValues.tags || []),
        clientId: mediaValues.client
      }).pipe(
        takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.systemMessageService.success(this.translateConfigService.instant("notifications.success.mediaEdit", this.form.value.name));
          this.router.navigate(['/medias']);
        });
    } else {
      this.mediasFormArray.value.filter((ma, index) => {
        if (!ma.name && !ma.selectedFile) this.mediasFormArray.removeAt(index);
      });
      if (!this.mediasFormArray.valid) {
        this.systemMessageService.error(this.translateConfigService.instant("notifications.error.pleaseAddFile"));
        return;
      }

      const isExceeded = this.checkIfStorageIsExceeded();

      if (isExceeded) {
        this.dialog.open(CustomSnackbarComponent, {
          width: '600px',
          data: {
            msg: {
              title: "notifications.error.storageExceededTitle",
              content: this.translateConfigService.instant("notifications.error.storageExceeded")
            }
          }
        });
        return;
      }

      for (let i = 0; i < this.form.get('medias').value.length; i++) {
        const file = this.form.get('medias').value[i];
        if (file.selectedFile.fileSize > 1073741824) {
          this.systemMessageService.error(this.translateConfigService.instant("notifications.error.fileToLarge"));
          return;
        }
      }

      this.uploadFiles();
    }
  }

  uploadFiles() {
    this.uploadingDone = false;
    this.disableUpload = true;

    from(this.form.get('medias').value).pipe(
      filter((media: any) => media.status !== UploadStatus.success),
      concatMap(media => {
        return this.processFile(media).pipe(
          tap(() => this.updateStatus(media, UploadStatus.success)),
          catchError((error) => {
            this.updateStatus(media, UploadStatus.error, error.message);
            return of(null);
          }),
          takeUntil(this.cancelUploads$)
        )
      }),
      takeUntil(this.cancelUploads$)
    ).subscribe({
      error: (error) => {
        this.systemMessageService.error(this.translateConfigService.instant(error));
      },
      complete: () => {
        this.disableUpload = false;
        this.uploadingDone = true;

        this.uploadError = this.form.get('medias').value.some(media => media.status === UploadStatus.error);

        if (this.form.get('medias').value.every(media => media.status === UploadStatus.success)) {
          this.onUploadComplete();
        }
      }
    })
  }

  processFile(media) {
    const paramsString = this.formatParamsString(media);
    const uploadRequest = media?.selectedFile?.format ===  MediaFormat.Image ? 'mediaUploadImage' : 'mediaUploadVideo';
    media.uploadProgress = null;

    return this.mediaApi[uploadRequest]({
      file: media?.selectedFile?.selectedFile,
      paramsString
    }).pipe(
      tap((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          media.uploadProgress = Math.round((event.loaded / (event.total ?? 1)) * 100);
        }
      })
    );
  }

  formatParamsString(media): string {
    const tagsJson = JSON.stringify(media?.tags || []);
    let paramsString = '';

    if (this.form?.getRawValue().customerId) {
      paramsString += `customerId=${this.form?.getRawValue().customerId}`;
    }
    if (media?.name && media?.name.length > 0) {
      paramsString += `&name=${media?.name}`;
    }
    if (media?.client) {
      paramsString += `&clientId=${media?.client}`;
    }
    if (media?.selectedFile?.selectedFile?.videoDuration) {
      paramsString += `&duration=${media?.selectedFile?.selectedFile?.videoDuration}`;
    }
    if (!this.mediaId) {
      paramsString += `&shouldCreateFolder=${this.form.get('isClientBoxChecked').value || false}`;
    }
    paramsString += `&tags=${tagsJson}`;

    return paramsString;
  }

  private onUploadComplete(): void {
    this.systemMessageService.success(this.translateConfigService.instant("notifications.success.mediaCreate"));
    this.router.navigate(['/medias']);
    this.disableUpload = false;
  }
  
  updateStatus(media, status: UploadStatus, errorMessage?: string) {
    media.status = status;
    status === UploadStatus.error && (media.errorMessage = errorMessage);
  }

  triggerFileInput() {
    const fileInput = document.getElementById('fileInput') as HTMLElement;
    fileInput.click();
  }

  private checkIfStorageIsExceeded() {
    const customer = this.customers.find((c) => c.id === this.customerId || this.form.get('customerId').value);
    const mediaTotalSize = this.form.get('medias').value.reduce((acc, curr) => acc + curr.fileSize, 0);
    return (mediaTotalSize + customer.currentStorageSize) > this.gigabytesToBytes(customer.storageLimitSize);
  }

  displayTag(tag: Tag): string {
    return tag?.name ?? '';
  }

  newTag(name: string): Tag {
    return { id: null, name };
  }

  get mediasArray(): FormArray {
    return this.form.get('medias') as FormArray;
  }
  private toMediaFormGroup(): FormGroup<MediaFilesFormGroup> {
    return this.formBuilder.group({
      id: [uniqueId()],
      name: [null, Validators.required],
      client: [],
      tags: this.formBuilder.control([]),
      selectedFile: [null, Validators.required],
      status: [],
      uploadProgress: []
    });
  }
  private initForm(): void {
    if (this.mediaId) {
      this.form = this.formBuilder.group({
        customerId: [this.customerId || null, Validators.required],
        name: [this.media.name, Validators.required],
        client: [this.media.client?.id],
        tags: [this.media.tags],
      });
    } else {
      this.mediasFormArray = this.formBuilder.array([this.toMediaFormGroup()]);
      this.form = this.formBuilder.group({
        customerId: [this.customerId || null, Validators.required],
        medias: this.mediasFormArray,
        isClientBoxChecked: [true]
      });
    }
  }

  selectMedia(media) {
    this.selectedBody = media.selectedFile;
    this.selectedMedia = media;
  }
}

