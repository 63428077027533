<div *ngIf="isReady">
  <div *ngIf="dataSource?.data?.length!==0" class="flex flex-col gap-2 flex-grow">
    <span> {{ 'cleanUpMedia.resultMsg' | translate }}</span>
    <div class="w-full justify-between items-center flex">
      <button 
        mat-flat-button     
        type="button"
        color="warn"
        *flowHasPermission="['UpdateMediaCms']; disabled true;"
        [ngClass]="!isAnySelected ? 'opacity-50 cursor-not-allowed pointer-events-none' : ''"
        [disabled]="!isAnySelected"
        (click)="onDelete()">
        <span>{{'media.deleteMedias' | translate}}</span>
      </button>
      <mat-paginator showFirstLastButtons
                      (page)="handlePageEvent($event)"
                      [pageSize]="params.pageSize"
                      [pageSizeOptions]="pageSizeOptions"
                      [pageIndex]="params.pageNumber"                 
                      [length]="totalCount"
                      [disabled]="!isReady">
      </mat-paginator>
    </div>

      <mat-table
        matSort
        class="overflow-auto"
        [dataSource]="dataSource"
        [matSortDisableClear]="true"
        (matSortChange)="handleSortEvent($event)"
        >
        <ng-container matColumnDef="checkbox">
          <mat-header-cell *matHeaderCellDef class="min-w-12 max-w-12 mr-5">
            <mat-checkbox
              id="select-media-checkbox-all"
              [checked]="allSelected"
              color="primary"
              (change)="toggleAllSelection()">
           </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="min-w-12 max-w-12 mr-5">
            <mat-checkbox
              [id]="'select-media-checkbox'+ row.id"
              color="primary"
              [checked]="row.isSelected"
              (change)="toggleRowSelection(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container> 

        <ng-container *ngFor="let column of columns" [matColumnDef]="column.key">
          <mat-header-cell *matHeaderCellDef class="min-w-28 max-w-28 text-gray-900 text-xs font-semibold"> 
            <span *ngIf="column.key !== 'thumbnail' && column.key !== 'tags'"
                mat-sort-header>
            {{ ('media.table.'+ column.label) | translate }}
            </span>
            <span *ngIf="column.key === 'thumbnail' || column.key === 'tags'">
            {{ ('media.table.'+ column.label) | translate }}
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="min-w-28 max-w-28">

            <ng-container *ngIf="column.key === 'lastUsed' || column.key === 'created'; else tagsField">
              <span>{{ row[column.key] | date: 'yyyy-MM-dd HH:mm' }}</span>
            </ng-container>

            <ng-template #tagsField>
              <ng-container *ngIf="column.key === 'tags' && row.tags?.length > 0; else defaultField">
                <div class="flex flex-wrap gap-2">           
                  <span *ngFor="let tag of row.tags" class="flex p-1 bg-chathams-blue-400 rounded text-center text-gray-900 text-xs font-normal leading-tight">
                    {{ tag.name }}
                  </span>
                </div>
              </ng-container>
            </ng-template>      

          <div *ngIf="column.key === 'thumbnail'"
               (click)="openViewMedia(row, $event)">
            <div class="w-[100px] my-[0.5rem] rounded flex items-center justify-start cursor-pointer">
              <flow-media-preview 
                [media]="row"                                    
                [type]="row?.type">
              </flow-media-preview>
            </div>
          </div>

            <ng-template #defaultField>
              {{ row[column.key] }}
            </ng-template>
          </mat-cell>
        </ng-container>        
      
        <!-- Table Header Row -->
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"  class="bg-gray-200 w-min min-w-full z-0"></mat-header-row>
      
        <!-- Table Data Rows -->
        <mat-row *matRowDef="let row; let index = index;  columns: displayedColumns" class="min-h-[60px] w-min min-w-full"
                 [ngClass]="index % 2 == 0 ? 'bg-gray-50' : 'bg-white'"></mat-row>
    </mat-table>
  </div>
  <ng-container *ngIf="dataSource?.data?.length===0">
    <div class="flex justify-center flex-col items-center">
      <img src="../../../../assets/no-data.png" class="max-w-[220px] my-6 mx-0"/>
      <p>{{'global.noDataFound' | translate }}</p>
      <p class="text-[12px]">{{'global.pleaseSelectDifferentFilters' | translate}}</p>
    </div>
  </ng-container>
</div>