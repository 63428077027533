export enum LastUsedMonthValues {
  OneMonth = 1,
  TwoMonths = 2,
  ThreeMonths = 3,
  FourMonths = 4,
  FiveMonths = 5,
  SixMonths = 6  
}

function generateEnumTranslations<T>(enumObject: T, prefix: string): Record<number, string> {  //TODO: Move in a separate file to be used as a method for getting all enums'translations
  return Object.fromEntries(
    Object.values(enumObject)
      .filter(value => typeof value === 'number')
      .map(value => [
        value,
        `${prefix}.${enumObject[value].charAt(0).toLowerCase()}${enumObject[value].slice(1)}`
      ])
  );
}

export const LastUsedMonthValuesTranslations = generateEnumTranslations(
  LastUsedMonthValues,
  'cleanUpMedia.lastUsedSelectionValues'
);  