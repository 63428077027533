import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { Placement, Screen } from '..';
import { BrokenScreensResponse } from '@admin/start/start.models';
import { PaginationResult } from '@api/models/paginator';
import {UnusedScreen} from '@admin/start/start.models';

@Injectable({
  providedIn: 'root',
})
export class ScreenApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }


  static readonly GetScreensPath = '/screens';


  getScreens(params?) {
    return this.http.get<any>(`${this.rootUrl}${ScreenApi.GetScreensPath}`, params ? { params } : {})
  }

  getScreenById(screenId, params) {
    return this.http.get<Screen>(`${this.rootUrl}${ScreenApi.GetScreensPath}/${screenId}`, { params });
  }

  editScreen(screen): Observable<any> {
    return this.http.put(`${this.rootUrl}${ScreenApi.GetScreensPath}`, screen);
  }

  saveScreen(screen): Observable<any> {
    return this.http.post(`${this.rootUrl}${ScreenApi.GetScreensPath}`, screen);
  }

  removeScreen(screenId) {
    return this.http.delete(`${this.rootUrl}${ScreenApi.GetScreensPath}/${screenId}`)
  }

  getUnusedScreens(params): Observable<PaginationResult<UnusedScreen>> {
    return this.http.get<PaginationResult<UnusedScreen>>(`${this.rootUrl}${ScreenApi.GetScreensPath}/unused`, { params });
  }

  getBrokenScreens(params): Observable<any> {
    return this.http.get<BrokenScreensResponse>(`${this.rootUrl}${ScreenApi.GetScreensPath}/broken`, { params });
  }

  getUniquePlacements(params?): Observable<any> {
    return this.http.get<Array<Placement>>(`${this.rootUrl}${ScreenApi.GetScreensPath}/placements`, { params });
  }

  getBookPlacements(params?): Observable<any> {
    return this.http.get<Array<Placement>>(`${this.rootUrl}${ScreenApi.GetScreensPath}/book/placements`, { params });
  }

  versions(): Observable<any> {
    return this.http.get(`${this.rootUrl}${ScreenApi.GetScreensPath}/versions`);
  }

  activateScreen(screenId: number, isActive) {
    return this.http.put(`${this.rootUrl}${ScreenApi.GetScreensPath}/${screenId}/status`, isActive)
  }
}
