import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Subject, takeUntil } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationModalComponent } from '@app/shared/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Client } from '@api/models/client';
import { AuthService, UserRoleEnum } from '@app/core/auth/auth.service';
import { MatSort, Sort } from '@angular/material/sort';
import { ClientApi } from '@api/services/client-api';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { SystemMessageService } from '@app/core/services/system-message.service';
import { AdvertisingTypeTranslations } from '@api/models/advertising-type';
import { PaginatorParams } from '@api/models/paginator';
import { PageEvent } from '@angular/material/paginator';
import { PermissionsService } from '@app/core/services/permissions.service';
@Component({
  selector: 'flow-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['id', 'name', 'vatNumber', 'department', 'costCenter', 'frameworkAgreementEnabled', 'advertisingType', 'actions'];
  dataSource: MatTableDataSource<Client>;
  hideBox = false;
  isAdmin: boolean;
  AdvertisingTypeTranslations = AdvertisingTypeTranslations;
  params: PaginatorParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'name',
    sortDirection: 'desc'
  }
  totalCount: number = 0;
  pageSizeOptions = [5, 10, 25, 100];

  constructor(
    private clientApi: ClientApi,
    private router: Router,
    private dialog: MatDialog,
    public platform: Platform,
    private authService: AuthService,
    private translateConfigService: TranslateConfigService,
    private systemMessageService: SystemMessageService,
    private permissionsService: PermissionsService) {
  }

  ngOnInit(): void {
    const user = this.authService.userData;
    const userCustomerId = user?.currentCustomerId || user?.customerId;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
    if (this.isAdmin && this.displayedColumns.indexOf('customer') === -1) {
      this.displayedColumns.splice(1, 0, 'customer')
    }

    this.getClients(userCustomerId);
  }

  getClients(customerId?: number): void {
    this.clientApi.getClientsWithPaginationAndSorting({ ...this.params }, customerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.dataSource = new MatTableDataSource(response.clients);
        this.params.pageNumber = response.pageNumber;
        this.params.pageSize = response.pageSize;
        this.totalCount = response.totalCount;
      });
  }

  onDeleteClick(event: Event, client: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: {
        remove: () => this.onRemoveClient(client),
        logic: true
      }
    })
  }

  private onRemoveClient(client: any) {
    this.clientApi.deleteClient(client.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialog.closeAll();
        const filteredList = this.dataSource.data.filter(x => x.id !== client.id);
        this.dataSource.data = filteredList;
        this.systemMessageService.success(this.translateConfigService.instant("notifications.success.clientDeleted"));
      });
  }

  redirectToEditPage(clientId: number): void {
    if (this.permissionsService.hasPermissions(['UpdateScreensAdmin'])) {
      this.router.navigate(['/client', clientId]);
    }
  }

  redirectToCreatePage(): void {
    if (this.permissionsService.hasPermissions(['UpdateScreensAdmin'])) {
      this.router.navigate(['/client/create']);
    }
  }

  handlePageEvent(e: PageEvent) {
    const user = this.authService.userData;
    const userCustomerId = user.roleId === UserRoleEnum.Admin ? user.currentCustomerId : user.customerId;
    this.params = { ...this.params, pageNumber: e.pageIndex, pageSize: e.pageSize };
    this.clientApi.getClientsWithPaginationAndSorting({ ...this.params }, userCustomerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.dataSource = new MatTableDataSource(response.clients);
        this.params.pageNumber = response.pageNumber;
        this.params.pageSize = response.pageSize;
        this.totalCount = response.totalCount;
      });
  }

  handleSortEvent(sort: Sort) {
    this.params = { ...this.params, pageNumber: 0, pageSize: 10, sortBy: sort?.active, sortDirection: sort.direction || (this.params.sortDirection == 'asc' ? 'desc' : 'asc') || 'asc' };
    const user = this.authService.userData;
    const userCustomerId = user.roleId === UserRoleEnum.Admin ? user.currentCustomerId : user.customerId;
    this.clientApi.getClientsWithPaginationAndSorting({ ...this.params }, userCustomerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.dataSource = new MatTableDataSource(response.clients);
        this.params.pageNumber = response.pageNumber;
        this.params.pageSize = response.pageSize;
        this.totalCount = response.totalCount;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete();
  }
}
