import { CreateCategoryComponent } from "@admin/person-of-interest/category/create-category/create-category.component";
import { CreateScreenGroupModalComponent } from "@admin/screen/create-screen-group-modal/create-screen-group-modal.component";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChannelComponent } from './admin/channel/channel.component';
import { ChannelCreateComponent } from './admin/channel/create/channel-create.component';
import { ClientCreateComponent } from "./admin/client/client-create/client-create.component";
import { ClientComponent } from './admin/client/client.component';
import { CustomerCreateComponent } from './admin/customer/create/customer-create.component';
import { CustomerComponent } from './admin/customer/customer.component';
import { MediaCreateComponent } from './admin/media/media-create/media-create.component';
import { FolderCreateComponent } from './admin/media/folder-create/folder-create.component';
import { MediaComponent } from './admin/media/media.component';
import { CategoryComponent } from "./admin/person-of-interest/category/category/category.component";
import { PersonOfInterestCreateComponent } from "./admin/person-of-interest/create/person-of-interest-create.component";
import { PersonOfInterestComponent } from "./admin/person-of-interest/person-of-interest.component";
import { ScreenCreateComponent } from './admin/screen/create/screen-create.component';
import { ScreenComponent } from './admin/screen/screen.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { SmsComponent } from "./admin/sms/sms.component";
import { StartComponent } from './admin/start/start.component';
import { PeopleCountComponent } from './admin/statistics/people-count/people-count.component';
import { ReachComponent } from './admin/statistics/reach/reach.component';
import { ReportsComponent } from './admin/statistics/reports/reports.component';
import { ViewsComponent } from './admin/statistics/views/views.component';
import { UserCreateComponent } from './admin/user/create/user-create.component';
import { UserComponent } from './admin/user/user.component';
import { AdminGuard } from './core/auth/admin-guard.service';
import { AuthGuard } from './core/auth/auth-guard.service';
import { ConfirmEmailComponent } from './core/auth/confirm-email/confirm-email.component';
import { LoginComponent } from './core/auth/login/login.component';
import { RegisterComponent } from './core/auth/register/register.component';
import { ChooseTenantComponent } from './core/auth/choose-tenant/choose-tenant.component';
import { PublicLayotComponent } from "@app/layout/public-layot/public-layot.component";
import { PrivateLayotComponent } from "@app/layout/private-layot/private-layot.component";
import { MediaUploadExternalComponent} from "@admin/media-upload-external/media-upload-external.component";
import { MediaCleanUpComponent } from "./admin/media/media-clean-up/media-clean-up.component";
import { BookingsResolverService } from "./admin/engagement/bookings/resolver/bookings.resolver.service";

const PUBLIC_ROUTES: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login', component: LoginComponent,
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'choose-tenant', component: ChooseTenantComponent,
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'register', component: RegisterComponent,
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'confirm-email', component: ConfirmEmailComponent,
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'uploadMediaFiles/:id',
    component: MediaUploadExternalComponent,
  },
]

const PRIVATE_ROUTES: Routes = [
  {
    path: 'home', component: StartComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'medias', component: MediaComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewMediaCms']
    }
  },
  {
    path: 'medias/clean-up', component: MediaCleanUpComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateMediaCms']
    }
  },
  {
    path: 'medias/create', component: MediaCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateMediaCms']
    }
  },
  {
    path: 'medias/:id', component: MediaCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateMediaCms']
    }
  },
  {
    path: 'folders/create', component: FolderCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateMediaCms']
    }
  },
  {
    path: 'folders/:id', component: FolderCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateMediaCms']
    }
  },
  {
    path: 'bookings',
    loadChildren: () =>
      import('./admin/engagement/bookings/bookings.module').then((m) => m.BookingsModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewBookingCms']
    }
  },
  {
    path: 'media-transfer',
    loadChildren: () =>
      import('./admin/media-transfer/media-transfer.module').then((m) => m.MediaTransferModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
    }
  },
  {
    path: 'permissions',
    loadChildren: () =>
      import('./admin/permissions/permissions.module').then((m) => m.PermissionsModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewUserPermissionsAdmin']
    }
  },
  {
    path: '',
    loadChildren: () =>
      import('./admin/engagement/book/book.module').then((m) => m.BookModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewBookingCms']
    }
  },
  {
    path: 'customers', component: CustomerComponent,
    canActivate: [AuthGuard, AdminGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'customers/create', component: CustomerCreateComponent,
    canActivate: [AuthGuard, AdminGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'customers/:id', component: CustomerCreateComponent,
    canActivate: [AuthGuard, AdminGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'channels', component: ChannelComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewChannelAdmin']
    }
  },
  {
    path: 'sms', component: SmsComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewSmsAdmin']
    }
  },
  {
    path: 'channels/create', component: ChannelCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateChannelAdmin']
    }
  },
  {
    path: 'channels/:id', component: ChannelCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateChannelAdmin']
    }
  },
  {
    path: 'person-of-interest', component: PersonOfInterestComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewPoiInsights']
    }
  },
  {
    path: 'person-of-interest/create', component: PersonOfInterestCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdatePoiInsights']
    }
  },
  {
    path: 'person-of-interest/:id', component: PersonOfInterestCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdatePoiInsights']
    }
  },
  {
    path: 'categories', component: CategoryComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewPoiCategoriesInsights']
    }

  },
  {
    path: 'categories/create', component: CreateCategoryComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdatePoiCategoriesInsights']
    }

  },
  {
    path: 'categories/:id/:customerId', component: CreateCategoryComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdatePoiCategoriesInsights']
    }

  },
  {
    path: 'screens', component: ScreenComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewScreensAdmin']
    }
  },
  {
    path: 'screens/create', component: ScreenCreateComponent,
    canActivate: [AuthGuard, AdminGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateScreensAdmin']
    }
  },
  {
    path: 'screens/:id', component: ScreenCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateScreensAdmin']
    }
  },
  {
    path: 'screenGroup/create', component: CreateScreenGroupModalComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateScreensAdmin']
    }
  },
  {
    path: 'screenGroup/:id', component: CreateScreenGroupModalComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateScreensAdmin']
    }
  },
  {
    path: 'statistics/people-count', component: PeopleCountComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewPeopleCounterInsights']
    }
  },
  {
    path: 'statistics/people-counter-reports', component: ReportsComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewPeopleCounterReportsInsights']
    }
  },
  {
    path: 'statistics/exposures-reports', component: ReportsComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewExposuresReportsAnalytics']
    }
  },
  {
    path: 'statistics/poi-reports', component: ReportsComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewPoiReportsInsights']
    }
  },
  {
    path: 'statistics/reach', component: ReachComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewReachAnalytics', 'ViewReachReportsAnalytics']
    }
  },
  {
    path: 'statistics/reach-reports', component: ReportsComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewReachReportsAnalytics']
    }
  },
  {
    path: 'statistics/views', component: ViewsComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewExposuresAnalytics']
    },
    resolve: { engagement: BookingsResolverService }
  },
  {
    path: 'clients', component: ClientComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewClientsAdmin']
    }
  },
  {
    path: 'client/create', component: ClientCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateClientsAdmin']
    }
  },
  {
    path: 'client/:id', component: ClientCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateClientsAdmin']
    }
  },
  {
    path: 'contact-persons',
    loadChildren: () => import('./admin/contact-person/contact-person.module').then(m => m.ContactPersonModule),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewContactPersonAdmin']
    }
  },
  {
    path: 'users', component: UserComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['ViewUsersAdmin']
    }
  },
  {
    path: 'users/create', component: UserCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateUsersAdmin']
    }
  },
  {
    path: 'users/:id', component: UserCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      authorize: true,
      permissions: ['UpdateUsersAdmin']
    }
  },
  {
    path: 'settings', component: SettingsComponent,
    canActivate: [AuthGuard, AdminGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  }
]

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: '',
    component: PublicLayotComponent,
    children: PUBLIC_ROUTES
  },
  {
    path: '',
    component: PrivateLayotComponent,
    children: PRIVATE_ROUTES,
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
