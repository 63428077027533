import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, filter, forkJoin, of, takeUntil } from 'rxjs';
import {
  Channel,
  Customer,
  CustomerApi,
  MediaTypeEnum,
  Placement,
  Screen,
  ScreenApi,
  StandardMedia
} from '@api/index';
import { AppService } from '@app/app.service';
import { AuthService, UserRoleEnum } from '@core/auth/auth.service';
import { Media } from '@core/services/media.service';
import { SystemMessageService } from '@core/services/system-message.service';
import { TranslateConfigService } from '@core/translate-config.service';
import { unsubscribeMixin } from '@core/unsubscribe';
import { MediaModalService } from '@core/services/media-modal.service';
import { OrientationEnum, OrientationEnumTranslations } from '@api/models/orientation-enum';
import { indicateLoading } from "@core/indicate-loading";
import { PowerSchedule } from "@api/models/screen";
import { PaginatorParams } from '@api/models/paginator';
import { MediaTypes } from '@app/shared/enums/media-types.enum';
import { ChannelsApi } from '@api/services/channels-api';

@Component({
  selector: 'flow-screen-create',
  templateUrl: './screen-create.component.html',
  styleUrls: ['./screen-create.component.scss'],
})
export class ScreenCreateComponent
  extends unsubscribeMixin()
  implements OnInit {
  screenId: number = null;
  screen: Screen;
  channels: Channel[];
  aiVersions: string[];
  advertiserVersions: string[];
  generalInfoForm: FormGroup<generalInfoFormGroup>;
  detailsForm: FormGroup<detailsFormGroup>;
  selectedMedia: Media;
  MediaTypeEnum = MediaTypeEnum;
  customers: Customer[];
  isAdmin: boolean;
  filteredChannels: Channel[] = [];
  customerId: number;
  placements: Placement[];
  filteredPlacements: Placement[];
  inputTouched: boolean = false;
  selectedStandardMedias: StandardMedia[] = [];
  orientationArray: OrientationEnum[] = [];
  OrientationEnumTranslations = OrientationEnumTranslations;
  defaultPresentationTime: number
  selectedIndex: number = 0;
  isEdit: boolean = false;
  generalInfoValidationErrors: string[] = []
  detailsValidationErrors: string[] = [];
  mediaTypesEnum = MediaTypes;
  weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  params: PaginatorParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'name',
    sortDirection: 'asc'
  };
  steps = [
    { title: 'createScreen.screenOrSensor', subtitle: 'createScreen.generalInfo' },
    { title: 'createScreen.screenOrSensor', subtitle: 'createScreen.details' }
  ];
  loading$ = {
    init: new BehaviorSubject(true),
    save: new BehaviorSubject(false),
  }
  protected readonly indicateLoading = indicateLoading;

  constructor(
    private customerApi: CustomerApi,
    private channelsApi: ChannelsApi,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    private appService: AppService,
    private translateConfigService: TranslateConfigService,
    private mediaModalService: MediaModalService,
    private screenApi: ScreenApi
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
    this.screenId = +this.activatedRoute.snapshot.params['id'] || null;
    this.orientationArray = Object.keys(OrientationEnumTranslations).map(o => Number(o));
    this.initForm();
  }

  get customerIdFormControl(): FormControl {
    return this.generalInfoForm.get('customerId') as FormControl;
  }

  ngOnInit(): void {
    this.isEdit = this.screenId != null;
    const user = this.authService.userData;
    this.isAdmin = user.roleId === UserRoleEnum.Admin && !user.currentCustomerId;
    if (!this.isAdmin) {
      const userCustomerId = user?.currentCustomerId || user?.customerId;
      this.generalInfoForm.get('customerId').setValue(userCustomerId);
      this.customerId = userCustomerId;
      this.getData([userCustomerId]);
    } else {
      this.customerId = user.customerId;
      this.getData();
    }
  }


  validateNumber(event: any) {
    const input = event.target.value;

    // Allow input of just a single '-' or '.' at the start
    if (input === '-' || input === '.') {
      return;
    }

    // Regex to match a valid negative or positive decimal number
    const validNumber = /^-?\d*\.?\d*$/;

    // If input doesn't match the valid number pattern, revert to the previous valid value
    if (!validNumber.test(input)) {
      // Remove the last character that was just entered
      event.target.value = input.slice(0, -1);
    }
  }


  getData(customerIds?: number[]) {
    let params: any = { status: true };
    if (customerIds && customerIds.length > 0)
      params.customerIds = customerIds;
    forkJoin([
      this.channelsApi.getChannels(params),
      this.screenApi.versions(),
      this.screenId ? this.screenApi.getScreenById(this.screenId, {}) : of(null),
      this.isAdmin ? this.customerApi.getCustomers() : of(null),
      !this.isAdmin ? this.customerApi.getCustomerById(this.customerId) : of(null),
      this.screenApi.getUniquePlacements(params)
    ]).pipe(takeUntil(this.ngUnsubscribe),
      indicateLoading(this.loading$.init))
      .subscribe(([channels, versions, screen, customers, customer, placements]) => {
        this.channels = channels || [];
        this.aiVersions = versions.aiVersions;
        this.advertiserVersions = versions.advertiserVersions;
        this.customers = customers || [customer];
        this.placements = placements;
        if (this.screenId) {
          this.screen = screen;
          this.defaultPresentationTime = this.customers?.filter(x => x.id === screen?.customer.id)[0].presentationTime
          this.filteredChannels = this.channels.filter((l) => l.customerId === screen?.customer.id);
          this.filteredPlacements = placements.filter((f) => f.customerId === screen?.customer?.id);
        }
        this.initForm(screen)
      })
  }

  selectedTabChange(index) {
    this.selectedIndex = index;
  }

  checkChannelDescription() {
    // We have to manually check if channelDescription is null or empty array
    // Then we set inputTouched and the input in the flow-multi-select-autocomplete will turn red
    // That input has seperate formControl
    if (
      this.generalInfoForm.get('channelDescription').value?.length === 0 ||
      !this.generalInfoForm.get('channelDescription').value
    ) {
      this.inputTouched = true;
    }
  }

  setValidationMessages(formGroup: FormGroup) {
    const validationErrors = []
    Object.keys(formGroup.controls).forEach((controlKey: any) => {
      const control = formGroup.get(controlKey);

      if (control instanceof FormGroup) {
        this.setValidationMessages(control);
      } else {
        if (control && control.errors) {
          for (const [key, value] of Object.entries(control.errors)) {

            if (key === 'required') {
              const isRequired = this.translateConfigService.instant(`createScreen.validationErrors.${controlKey}`) + " " + this.translateConfigService.instant(`createScreen.validationErrors.requred`)
              validationErrors.push(isRequired);
            }
          }
        }
      }
    })

    return validationErrors;
  }

  onSaveClick(): void {
    if (!this.generalInfoForm.valid || !this.detailsForm.valid) {
      this.detailsValidationErrors = this.setValidationMessages(this.detailsForm);
      this.generalInfoValidationErrors = this.setValidationMessages(this.generalInfoForm);
      this.checkChannelDescription();

      return;
    }
    let request = this.requestValues();

    const mode = this.screenId ? 'editScreen' : 'saveScreen';
    this.screenApi[mode](this.screenId ? [request] : request)
      .pipe(takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe(() => {
        this.systemMessageService.success(
          this.translateConfigService.instant(
            this.generalInfoForm.get('isSensor').value ? 'notifications.success.sensorCreate' : 'notifications.success.screenCreate',
            this.generalInfoForm.value.humanUniqueIdentifier
          )
        );
        this.router.navigate(['/screens']);
      });
  }

  resetDefaultMedia() {
    this.detailsForm.get('standardMedia').setValue(null);
    this.detailsForm.get('presentationTime').enable();
  }

  openCreateMedia(propertyName: string): void {
    this.openMediaModal(propertyName, true, null, false);
  }

  openSelectMedia(propertyName: string): void {
    if (this.generalInfoForm.get('customerId').value) {
      this.openMediaModal(propertyName, null, null, true);
    } else {
      this.systemMessageService.info(this.translateConfigService.instant('notifications.error.customerRequired'));
    }
  }

  openMediaModal(propertyName: string, isDefault: boolean = false, index?: number, isSelectModal: boolean = false): void {
    const openModalMethod = isSelectModal
      ? this.mediaModalService.openSelectMediaModal
      : this.mediaModalService.openCreateMediaModal;

    openModalMethod.call(this.mediaModalService, { 
      customerIdModal: this.customerIdFormControl.getRawValue(), 
      isDefault 
    })
      .pipe(filter((media: Media) => !!media))
      .subscribe({
        next: (media: Media) => {
          if (Array.isArray(media)) {
            media.map((m) => {
              this.mediaModalService.setModal(m, this.detailsForm, propertyName, this.selectedStandardMedias, isDefault, isSelectModal)
            });
          } else {
            this.mediaModalService.setModal(media, this.detailsForm, propertyName, this.selectedStandardMedias, isDefault, isSelectModal);
          }        },
        error: (error) => {
          console.error('Failed to fetch media:', error);
        }
      });
  }

  openViewMedia(standardMedia: StandardMedia, $event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.mediaModalService.openViewMediaModal(standardMedia)
  }

  @HostListener('keydown', ['$event'])
  disableEnterKey(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  onNextTab(val: number) {
    this.selectedIndex = val;
    if (this.selectedIndex == 1 && val == 1) {
      this.generalInfoValidationErrors = this.setValidationMessages(this.generalInfoForm);
      this.selectedIndex = val;
    } else if (this.selectedIndex == 0 && val == 0) {
      this.detailsValidationErrors = this.setValidationMessages(this.detailsForm);
      this.selectedIndex = val;
    }
  }

  private requestValues() {
    const {
      channelId, customerId, humanUniqueIdentifier,
      id, channelDescription, note, isSensor, isUsedForTrackingVehicle
    } = this.generalInfoForm.getRawValue();

    const {
      advertiserVersion,
      standardMedia,
      tracking,
      orientation,
      presentationTime,
      latitude,
      longitude,
      aiVersion,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    } = this.detailsForm.getRawValue();


    const dayBasedSchedule = {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday
    }

    let powerSchedule = []

    if (this.detailsForm.get('scheduleOptions').value) {
      powerSchedule = this.reverseTransformSchedule(dayBasedSchedule)
    }

    let request = {
      advertiserVersion: advertiserVersion || this.screen?.advertiserVersion,
      aiVersion: aiVersion || this.screen?.aiVersion,
      defaultMediaId: standardMedia?.mediaId || this.screen?.defaultMediaId,
      humanUniqueIdentifier:
        humanUniqueIdentifier || this.screen?.humanUniqueIdentifier,
      id: id || this.screen?.id,
      channelDescription: channelDescription[0]?.placement,
      placementId: channelDescription[0]?.id,
      channelId: channelId || this.screen?.channelId,
      note: note,
      customerId: customerId || this.screen?.customer.id,
      isSensor: isSensor,
      trackingType: tracking,
      orientation: orientation || 0,
      defaultMediaPresentationTime: presentationTime,
      latitude: latitude,
      longitude: longitude,
      powerSchedule: powerSchedule,
      isUsedForTrackingVehicle: isUsedForTrackingVehicle
    };


    if (!this.detailsForm.get('scheduleOptions').value) {
      delete request.powerSchedule
    }

    return request;
  }

  private initForm(screen?: Screen): void {
    const placement: Placement[] = screen?.channelDescription ? [{
      id: screen?.placementId,
      placement: screen?.channelDescription
    }] : [];

    let standardMedia: StandardMedia;
    if (screen?.defaultMediaId) {
      standardMedia = {
        mediaId: screen.defaultMediaId,
        name: screen.defaultMediaName,
        imageUrl: screen.defaultMediaImageUrl,
        type: screen.defaultMediaType,
        thumbnailImageUrl: screen.defaultMediaThumbnailImageUrl,
        filename: screen.defaultMediaFilename
      }
      this.selectedStandardMedias.push(standardMedia)
    }

    this.generalInfoForm = this.formBuilder.group({
      id: this.screenId,
      humanUniqueIdentifier: [screen?.humanUniqueIdentifier, Validators.required],
      channelId: [screen?.channelId, Validators.required],
      channelDescription: [placement, Validators.required],
      isSensor: [screen?.isSensor ?? false],
      note: [screen?.note],
      customerId: [this.screen?.customer.id || this.customerId, Validators.required],
      isUsedForTrackingVehicle: [screen?.isUsedForTrackingVehicle || false]
    });

    this.detailsForm = this.formBuilder.group({
      tracking: [screen?.trackingType, Validators.required],
      latitude: [screen?.latitude != null || screen?.latitude != undefined ? screen?.latitude : 59.334135, Validators.required],
      longitude: [screen?.longitude != null || screen?.longitude != undefined ? screen?.longitude : 18.066333, Validators.required],
      orientation: [screen?.orientation, Validators.required],
      standardMedia: [standardMedia],
      presentationTime: [(+standardMedia?.duration || screen?.defaultMediaPresentationTime) || this.defaultPresentationTime],
      aiVersion: [screen?.aiVersion],
      advertiserVersion: [screen?.advertiserVersion],
      scheduleOptions: [screen?.powerSchedule.length > 0]

    })

    if (screen?.powerSchedule) {
      this.transformPowerSchedule(this.formBuilder, this.detailsForm, screen.powerSchedule)
    }

    if (+standardMedia?.duration > 0) {
      this.detailsForm.get('presentationTime').disable()
    }

    if (screen?.defaultMediaType === this.mediaTypesEnum.Video) {
      this.detailsForm.get('presentationTime').disable();
    }

    this.generalInfoForm.get('isSensor').valueChanges.subscribe(value => {
      if (!value) {
        this.detailsForm.get('standardMedia').setValidators([Validators.required])
        this.detailsForm.get('orientation').setValidators([Validators.required])
      } else {
        this.detailsForm.get('standardMedia').setValue(null)
        this.detailsForm.get('orientation').setValue(null);

        this.detailsForm.get('standardMedia').clearValidators();
        this.detailsForm.get('orientation').clearValidators();

        this.detailsForm.get('standardMedia').updateValueAndValidity();
        this.detailsForm.get('orientation').updateValueAndValidity();
      }
    })
    if (this.screenId && !screen) {
      this.generalInfoForm.get('isSensor').disable();
    } else {
      this.generalInfoForm.get('isSensor').enable();
    }

    this.generalInfoForm.get('customerId').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(customerId => {
        if (this.isAdmin) {
          this.defaultPresentationTime = this.customers?.filter(x => x.id === customerId)[0].presentationTime
          this.detailsForm.get('presentationTime').setValue(this.defaultPresentationTime)
          this.generalInfoForm.get('channelId').setValue(null);
          this.detailsForm.get('standardMedia').setValue(null);
          this.generalInfoForm.get('channelDescription').setValue(null);
          this.filteredChannels = this.channels?.filter(
            (loc) => loc.customerId === customerId
          );
          this.filteredPlacements = this.placements?.filter(
            (p) => p.customerId === customerId
          );
        }
      });
    this.generalInfoForm.get('channelId').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (this.isAdmin) {
          this.detailsForm.get('standardMedia').setValue(null);
        }
      });

    this.detailsForm.get('standardMedia').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(media => {
        if (media?.duration) {
          this.detailsForm.get('presentationTime').setValue(+media?.duration)
          this.detailsForm.get('presentationTime').disable()
        } else {
          this.detailsForm.get('presentationTime').setValue(this.defaultPresentationTime)
          this.detailsForm.get('presentationTime').enable()
        }
      })
    if (!this.isAdmin) {
      const disableInputs = [
        'aiVersion',
        'advertiserVersion',
        'orientation'
      ];
      const disableGeneralInputs = [
        'customerId',
        'channelId'
      ];
      disableInputs.forEach((i) =>
        this.detailsForm.get([i]).disable({ onlySelf: true })
      );
      disableGeneralInputs.forEach((i) =>
        this.generalInfoForm.get([i]).disable({ onlySelf: true })
      );
      this.generalInfoForm.get('channelDescription').enable();
    }

    this.detailsForm.get('scheduleOptions').valueChanges.subscribe(value => {
      if (value) {
        this.weekDays.forEach((day: any) => {
          this.detailsForm.addControl(day, this.createDayFormGroup(this.formBuilder,));
        });
      } else {
        this.weekDays.forEach((day: any) => {
          this.detailsForm.removeControl(day);
        });
      }
    })

  }

  restrictNonNumericalInput(event: KeyboardEvent): void {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  removeLeadingZero(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length > 1 && input.value.startsWith('0')) {
      input.value = input.value.replace(/^0+/, '');
    }
  }

  private createDayFormGroup(formBuilder?: FormBuilder, powerUp?: string, powerDown?: string): FormGroup {
    return formBuilder.group({
      powerUp: [powerUp || null, Validators.required],
      powerDown: [powerDown || null, Validators.required]
    });
  }

  private transformPowerSchedule(formBuilder: FormBuilder, formGroup: FormGroup, schedule: PowerSchedule[]): { [key: string]: { powerUp: string, powerDown: string } } {
    const transformedSchedule: { [key: string]: { powerUp: string, powerDown: string } } = {};

    schedule.forEach((entry, index) => {


      const dayName = this.weekDays[index];
      const powerUp = `${entry.powerUpHour.toString().length > 1 ? entry.powerUpHour : `0${entry.powerUpHour}`}:${entry.powerUpMinutes.toString().length > 1 ? entry.powerUpMinutes : `0${entry.powerUpMinutes}`}`
      const powerDown = `${entry.powerDownHour.toString().length > 1 ? entry.powerDownHour : `0${entry.powerDownHour}`}:${entry.powerDownMinutes.toString().length > 1 ? entry.powerDownMinutes : `0${entry.powerDownMinutes}`}`;

      transformedSchedule[dayName] = { powerUp, powerDown };

      formGroup.addControl(dayName, this.createDayFormGroup(formBuilder, powerUp, powerDown));
    });

    return transformedSchedule;
  }

  private reverseTransformSchedule(schedule: any): PowerSchedule[] {
    return this.weekDays.map((dayName, index) => {
      const daySchedule = schedule[dayName];
      const [powerUpHour, powerUpMinutes] = daySchedule.powerUp.split(':').map(Number);
      const [powerDownHour, powerDownMinutes] = daySchedule.powerDown.split(':').map(Number);

      return {
        powerUpHour,
        powerUpMinutes,
        powerDownHour,
        powerDownMinutes,
        day: index + 1
      };
    });
  }
  displayPlacement(placement: Placement): string {
    return placement?.placement ?? '';
  }

  newPlacement(placement: string): Placement {
    return {
      placement: placement,
    };
  }

}


interface generalInfoFormGroup {
  id: FormControl<number>;
  humanUniqueIdentifier: FormControl<string>;
  channelId: FormControl<number>;
  channelDescription: FormControl<any>;
  isSensor: FormControl<boolean>;
  note: FormControl<string>;
  customerId: FormControl<number>;
  isUsedForTrackingVehicle: FormControl<boolean>;
}

interface detailsFormGroup {
  presentationTime: FormControl<number>;
  latitude: FormControl<number>;
  longitude: FormControl<number>;
  orientation: FormControl<number>;
  standardMedia: FormControl<StandardMedia>;
  aiVersion: FormControl<string>;
  advertiserVersion: FormControl<string>;
  tracking: FormControl<number>;
  scheduleOptions: FormControl<boolean>,
  monday?: FormGroup<any>;
  tuesday?: FormGroup<any>;
  wednesday?: FormGroup<any>;
  thursday?: FormGroup<any>;
  friday?: FormGroup<any>;
  saturday?: FormGroup<any>;
  sunday?: FormGroup<any>;
}



