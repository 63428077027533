import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AuthService, UserRoleEnum } from '../auth/auth.service';
import { BehaviorSubject, defer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Customer } from '@api/models/customer';
import { User } from '@api/models/user';
import { UsersApi } from '@api/services/users-api';
import { CurrentUser } from '../auth/current-user';
import { unsubscribeMixin } from '../unsubscribe';
import { indicateLoading } from '../indicate-loading';
import { CustomerApi } from '@api/index';

@Component({
  selector: 'flow-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent extends unsubscribeMixin() implements OnInit {
  @Input() currentUser!: CurrentUser;

  customers: Customer[];
  selectedCustomer: Customer;
  isAdmin: boolean;
  initials = ''
  showNavSettings = false;
  adminUser = { id: -1, name: 'Admin' };

  loading$ = {
    customers: new BehaviorSubject(false)
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private customerApi: CustomerApi,
    private usersApi: UsersApi,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    const user = this.authService.userData;
    this.isAdmin = user?.roleId === UserRoleEnum.Admin;
    this.currentUser = this.authService.getUser()
    this.initials = this.getInitials(this.currentUser?.fullName)
    this.getCustomers(user);
  }

  getCustomers(user: User): void {
    defer(() => {
      return this.isAdmin
        ? this.customerApi.getCustomers()
        : this.usersApi.getUserCustomers(user.id);
    }).pipe(
      indicateLoading(this.loading$.customers, this.cdr),
      takeUntil(this.ngUnsubscribe),
    ).subscribe({
      next: (customers) => {
        this.customers = [
          ...(this.isAdmin ? [this.adminUser] : []),
          ...customers
        ];
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  getInitials(name) {
    return name?.split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');
  }

  showSettings(e) {
    e.stopPropagation();
    e.preventDefault()
    this.showNavSettings = true
  }

  closeSettings(e) {
    this.showNavSettings = false
  }

  redirectToHomePage() {
    this.router.navigate(['/home']);
  }
}