import {Component, HostListener, Inject, OnInit, Renderer2} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { CustomEmailValidator } from '@app/core/services/media.service';
import { ChannelsApi } from '@api/services/channels-api';
import { Customer, CustomerApi } from '@api/index';
import { ContactPersonApi } from '@api/services/contact-person-api';
import { ContactPerson } from '@api/models/contact-person';
import { IdName } from '@api/models/idname';


@Component({
  selector: 'flow-contact-person-create',
  templateUrl: './contact-person-create.modal.html',
  styleUrls: ['./contact-person-create.modal.scss']
})
export class ContactPersonCreateModal extends unsubscribeMixin() implements OnInit {
  customers: Customer[];
  channels: IdName[];
  form: FormGroup<CustomerPersonFormGroup>;
  loading$ = {
    save: new BehaviorSubject(false)
  }
  isLimitReached: boolean = false;
  limit: number;
  title: string = 'contactPerson.modal.titleCreate';
  constructor(
    public dialogRef: MatDialogRef<ContactPersonCreateModal>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private formBuilder: FormBuilder,
    private customerApi: CustomerApi,
    private channelsApi: ChannelsApi,
    private contacPersonApi: ContactPersonApi,
    private renderer: Renderer2
  ) {
    super();
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const elementExists = document.getElementsByClassName('country-search').length
    if (elementExists) {
      const element = this.renderer?.selectRootElement('.country-search');
      const x = element as HTMLElement;
      setTimeout(() => x.focus(), 200);
    }
  }

  ngOnInit(): void {
    if (this.data.isAdmin){
      if (this.data.selectedContactPerson){
        this.title = 'contactPerson.modal.titleEdit';
        this.customers = [this.data.selectedContactPerson.customer];
        this.channelsApi.getChannels({ customerIds: [this.data.selectedContactPerson.customer.id] }).subscribe(data => {
          this.channels = data.length > 0 ? data.map(ch => { return { id: ch.id, name: ch.name} }) : [];
          if (this.data.selectedContactPerson.channels.length > 0)
            this.form.get('channelIds').setValue(this.data.selectedContactPerson.channels.map(ch => ch.id));
        });
      } else {
        this.customerApi.getCustomers().subscribe(data => {
          this.customers = data;
        });
      }
    } else {
      if (this.data.selectedContactPerson || this.data.customerPerson)
        this.title = 'contactPerson.modal.titleEdit';
      if (this.data.currentCustomerId){
        this.channelsApi.getChannels({ customerIds: [this.data.currentCustomerId] }).subscribe(data => {
          this.channels = data.length > 0 ? data.map(ch => { return { id: ch.id, name: ch.name} }) : [];
          if (this.data.customerPerson)
            this.form.get('channelIds').setValue(this.data.customerPerson.channels.map(ch => ch.id));
          else if (this.data.selectedContactPerson)
            this.form.get('channelIds').setValue(this.data.selectedContactPerson.channels.map(ch => ch.id));
        });
      }
    }
    this.initForm(this.data.selectedContactPerson || this.data.customerPerson);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (!this.form.valid) {
      return;
    }

    if(!this.data.isEdit){
      this.contacPersonApi.createContactPerson(this.form.value as ContactPerson).subscribe((result) => {
        if (result){
            this.dialogRef.close(result);
        }
      })
    }
    else{
      this.contacPersonApi.editContactPersons(this.form.value as ContactPerson).subscribe((result) => {
        if (result){
          this.dialogRef.close(result);
        }
      });
  
    }
  }

  toggleSelectDeselectChannels(selectAll: boolean): void {
    if (selectAll)
      this.form.get('channelIds').setValue(this.channels.map(ch => ch.id));
    else
      this.form.get('channelIds').setValue([]);
  }
  
  onSelectedCustomer() {
    const selectedCustomer = this.customers.filter(c => c.id === this.form.get('customerId').value)[0];
    this.channels = [];
    this.form.get('channelIds').setValue([]);
    this.channelsApi.getChannels({ status: true, customerIds: [this.form.get('customerId').value] }).subscribe(data => this.channels = data.map(ch => { return { id: ch.id, name: ch.name} }));
    this.isLimitReached = selectedCustomer.contactPersonLimit <= this.data.contactPersons.filter(cp => cp.customer.id === selectedCustomer.id).length;
    this.limit = selectedCustomer.contactPersonLimit;
  }

  private initForm(person: ContactPerson): void {
    this.form = this.formBuilder.group({
      id: [person?.id || null],
      customerId: [person?.customer.id || this.data.currentCustomerId || 0, Validators.required],
      channelIds: [],
      name: [person?.name || '', Validators.required],
      email: [person?.email || '', [Validators.required, CustomEmailValidator()]],
      phoneNumber: [person?.phoneNumber || '', Validators.required]
    });
  }
}

interface CustomerPersonFormGroup {
  id: FormControl<number>;
  customerId: FormControl<number>;
  channelIds: FormControl<number[]>;
  name: FormControl<string>;
  email: FormControl<string>;
  phoneNumber: FormControl<string>;
}

interface ModalData {
  customerPerson: ContactPerson;
  selectedContactPerson: ContactPerson;
  contactPersons: ContactPerson[];
  isAdmin: boolean;
  currentCustomerId?: number;
  isCustomerPage: boolean;
  isEdit: boolean;
}
