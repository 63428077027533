import { Injectable } from "@angular/core";
import { CustomerTypeEnum, EngagementTypeEnum, TargetGroupEnum, TimeframeEnum, TypeEnum, WeekDayEnum } from "@api/index";
import { CountryEnum } from "@api/models/countries";
import { InvoiceDeliveryTypeEnum } from "@api/models/invoice-delivery-type-enum";
import { MonthEnum } from "@api/models/months-enum";
import { TranslationsEnum } from "@api/models/translations-enum";
import { CountryCodes, CountryTranslations } from "@app/admin/customer/create/country-codes";
import { EngagementTypeTranslations, PlacementTypeTranslations } from "@app/admin/engagement/engagement-type-translations";
import { TimeframeTranslations } from "@app/admin/statistics/timeframe-translations";
import { GroupByData, GroupDataTranslations } from "@app/admin/statistics/views/abstract-views-component";
import { CustomerTypeTranslations } from "../../admin/customer/customer-type-translations";
import { InvoiceDeliveryTypeTranslations } from "../../admin/customer/invoice-delivery-type-translations";
import { ScreenOrientationEnum, ScreenOrientationTranslations } from "../../admin/screen/screen-orientation";
import { TargetGroupEnumTranslations } from "../../admin/target-group/target-group-enum-translations";
import { UserRoleEnum } from "../auth/auth.service";
import { UserRoleTranslations } from "../auth/user-role-translations";
import { TrackingTypeTranslations, Translations } from "../translations";
import {AdvertisingType, AdvertisingTypeTranslations } from "../../../_api/models/advertising-type";
import { TrackingEnum } from "@api/models/tracking.enum";
import { MediaLinks, MediaLinksTranslations } from "@app/shared/enums/media-links.enum";
import { MonthTranslations } from "@app/models/month-translation";
import { WeekdayTranslations } from "@app/models/weekday-translation";
import { MediaTypes, MediaTypesTranslations } from "@app/shared/enums/media-types.enum";
import { LastUsedMonthValues, LastUsedMonthValuesTranslations } from "@app/shared/enums/last-used-month-values-enum";

@Injectable({
  providedIn: 'root'
})
export class EnumService {

  userRoles = this.createEnumObjects(UserRoleTranslations) as EnumObject<UserRoleEnum>[];
  segmentTypes = this.createEnumObjects(PlacementTypeTranslations) as EnumObject<TypeEnum>[];
  invoiceDeliveryTypes = this.createEnumObjects(InvoiceDeliveryTypeTranslations) as EnumObject<InvoiceDeliveryTypeEnum>[];
  customerTypes = this.createEnumObjects(CustomerTypeTranslations) as EnumObject<CustomerTypeEnum>[];
  targetGroups = this.createEnumObjects(TargetGroupEnumTranslations) as EnumObject<TargetGroupEnum>[];
  screenOrientations = this.createEnumObjects(ScreenOrientationTranslations) as EnumObject<ScreenOrientationEnum>[];
  weekDays = this.createEnumObjects(WeekdayTranslations) as EnumObject<WeekDayEnum>[];
  months = this.createEnumObjects(MonthTranslations) as EnumObject<MonthEnum>[];
  translations = this.createEnumObjects(Translations) as EnumObject<TranslationsEnum>[];
  countries = this.createEnumObjects(CountryTranslations) as EnumObject<CountryEnum>[];
  countryCodes = this.createEnumObjects(CountryCodes) as EnumObject<CountryEnum>[];
  groupData = this.createEnumObjects(GroupDataTranslations) as EnumObject<GroupByData>[];
  engagementTypes = this.createEnumObjects(EngagementTypeTranslations) as EnumObject<EngagementTypeEnum>[];
  timeframeTypes = this.createEnumObjects(TimeframeTranslations) as EnumObject<TimeframeEnum>[];
  trackingTypes = this.createEnumObjects(TrackingTypeTranslations) as EnumObject<TrackingEnum>[];
  advertisingType = this.createEnumObjects(AdvertisingTypeTranslations) as EnumObject<AdvertisingType>[];
  mediaLinks = this.createEnumObjects(MediaLinksTranslations) as EnumObject<MediaLinks>[];
  mediaTypes = this.createEnumObjects(MediaTypesTranslations) as EnumObject<MediaTypes>[];
  lastUsedMonthValues = this.createEnumObjects(LastUsedMonthValuesTranslations) as EnumObject<LastUsedMonthValues>[];
  
  constructor() { }

  private createEnumObjects(enumTranslations: { [key: number]: string }): EnumObject<number>[] {
    const types = Object.keys(enumTranslations).filter(key => +key >= 0)
      .map(key => {
        const value = +key;
        return new EnumObject(value, enumTranslations[value]);
      });
    return types;
  }
}

export class EnumObject<T> {
  constructor(readonly id: T, readonly name: string) { }
}
