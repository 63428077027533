import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import {EnumService} from "../../../../core/services/enum.service";
import { LastUsedMonthValues } from "@app/shared/enums/last-used-month-values-enum";

@Component({
  selector: 'flow-media-clean-up-filters',
  templateUrl: './media-clean-up-filters.component.html'
})
export class MediaCleanUpFiltersComponent implements OnInit{
  lastUsedMonthValues = [];
  selectedLastUsedValue = null;
  lastUsedOlderThan: number = this.selectedLastUsedValue;

  includeNestedMedia: boolean = true;
  includeNeverUsed: boolean = false;

  @Output() valuesChange = new EventEmitter<{ includeNestedMedia: boolean; includeNeverUsed: boolean, lastUsedOlderThan: number }>()
  
  constructor(
    private enumService: EnumService
  ) {
    this.lastUsedMonthValues = enumService.lastUsedMonthValues;
    this.selectedLastUsedValue = this.lastUsedMonthValues.find(value => value.id == LastUsedMonthValues.ThreeMonths).id;
  }

  ngOnInit() {
   this.onValueChange();
  }

  onValueChange() {
    this.valuesChange.emit({
      includeNestedMedia: this.includeNestedMedia,
      includeNeverUsed: this.includeNeverUsed,
      lastUsedOlderThan: this.selectedLastUsedValue
    });
  }
}
