<div class="flex items-center justify-between">
  <div>
    <h2 class="reach-section-title">{{ 'exposures.hourlyExposures' | translate }}</h2>
  </div>
</div>
<mat-divider></mat-divider>
<div *ngIf="totalNumberOfExposures" class="flex items-baseline justify-between selected-day">
  <div>{{ mediaSubtitleData?.start}} - {{ mediaSubtitleData?.end}}</div>
</div>
<mat-divider *ngIf="totalNumberOfExposures"></mat-divider>
<br>

<div *ngIf="noChartData; else chartTemplate"
     class="flex justify-center flex-col items-center mt-5">
  <img src="../../../../../assets/no-chart-data.png" />
  <p class="mt-5">{{ "reach.noDataAvailable" | translate }}</p>
  <canvas baseChart
          height="50"></canvas>
</div>
<ng-template #chartTemplate>
  <canvas baseChart
          [data]="chartData"
          [type]="'line'"
          [options]="chartOptions"
          [plugins]="chartPlugins"
          height="90vh">
  </canvas>
</ng-template>