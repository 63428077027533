import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CustomerApi, StatisticsApi } from '@api/index';
import { IdName } from '@api/models/idname';
import { CurrentUser } from '@app/core/auth/current-user';
import { MediaService } from '@app/core/services/media.service';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { UserRoleEnum } from '@app/core/auth/auth.service';
import { SystemMessageService } from '@app/core/services/system-message.service';
import { TranslateConfigService } from '@app/core/translate-config.service';

@Component({
  selector: 'flow-media-filter-section',
  templateUrl: './media-filter-section.component.html',
  styleUrls: ['./media-filter-section.component.scss']
})
export class MediaFilterSectionComponent implements OnInit, OnDestroy {
  @Input() currentUser: CurrentUser;
  @Output() onFilterMedias = new EventEmitter();
  @Output() onClearAllFilters = new EventEmitter();


  destroy$ = new Subject();
  formGroup: FormGroup;
  clients: IdName[];
  customerId: number;
  customers: IdName[] = [];
  fromLabel: string;
  isAdmin: boolean;

  constructor(
    private customerApi: CustomerApi,
    private statisticsApi: StatisticsApi,
    private mediaService: MediaService,
    private systemMessageService: SystemMessageService,
    private translateConfigService: TranslateConfigService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getCustomers();
    this.isAdmin = this.currentUser.roleId === UserRoleEnum.Admin && !this.currentUser.currentCustomerId;
  }

  initForm(): void {
    this.formGroup = new FormGroup({
      text: new FormControl(''),
      clientIds: new FormControl([]),
      created: new FormGroup({
        start: new FormControl(null),
        end: new FormControl(null), 
      }),
      customerIds: new FormControl([]),
      showExtendedDetails: new FormControl(null)
    })
  }

  private getSelectedCustomerIds(customerIds: any): number[] {
    if (customerIds?.value?.length > 0) {
      return customerIds.value;
    }
    return this.customers?.map(c => c.id) || customerIds;
  }

  onCustomerSelect(customerIds: any): void {
    const customers = this.getSelectedCustomerIds(customerIds);
  
    forkJoin({
      clients: this.statisticsApi.getClients(customers).pipe(
        catchError((error) => {
          this.systemMessageService.error(this.translateConfigService.instant(error));
          return of([]);
        })
      ),
      tags: this.statisticsApi.getTags(customers).pipe(
        catchError((error) => {
          this.systemMessageService.error(this.translateConfigService.instant(error));
          return of([]);
        })
      )
    })
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(({ clients, tags }) => {
      this.updateClients(clients);
      this.updateTags(tags);
    });
  }

  private updateClients(clients: any[]): void {
    this.clients = clients;
    const control = this.formGroup?.get('clientIds');
    if (control) {
      clients.length > 0 ? control.enable() : (control.reset(), control.disable());
    }
  }
  
  private updateTags(tags: any[]): void {
    this.mediaService.setTags(tags);
  }

  getCustomers(): void {
    this.customerId = this.currentUser?.currentCustomerId || this.currentUser?.customerId;
    const callFunction = this.customerId ? this.customerApi.getCustomerById(this.customerId) : this.statisticsApi.getCustomers();
    callFunction.pipe(takeUntil(this.destroy$))
      .subscribe(customer => {
        this.customers = this.customerId ? [customer] : customer;

        const id = this.customerId ? [this.customerId] : this.customers.map(c => c.id)
        this.onCustomerSelect(id)
      })
  }

  clearDatePicker(): void {
    this.formGroup.get('created')?.patchValue({
      start: null,
      end: null,
    });
  }

  submit(): void {
    this.onFilterMedias.emit(this.formGroup.value);
  }

  clearAll(): void {
    this.mediaService.setFilters(null);
    const customerIds = this.formGroup.get('customerIds')?.value;
    if(Array.isArray(customerIds) && customerIds.length > 0){ 
      this.getCustomers();
    }    
    this.formGroup.reset({
      text: '',
      clientIds: [],
      created: {
        start: null,
        end: null,
      },
      customerIds: [],
      showExtendedDetails: null,
    });
    this.onClearAllFilters.emit();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
