<div class="flex flex-col gap-8">
    <span class="text-gray-900 text-2xl font-semibold leading-normal">{{'cleanUpMedia.title' | translate}}</span>
    <div class="inline-flex justify-start gap-10 pr-[2rem]">
        <div class="w-1/2 h-full gap-10 inline-flex">
            <div class="flex-col  gap-4 flex mx-5">
                <flow-media-clean-up-filters
                    (valuesChange)="handleFilterValues($event)">
                </flow-media-clean-up-filters>
            </div>
        </div>
        <div class="w-full justify-between items-start flex">
            <div class="inline-flex flex-col gap-2 p-4 text-gray text-xs leading-[18px] w-[378px] ">
                <div class="px-2 border-l-4 border-chathams-blue-600 items-start">
                    <span>{{'global.note' | translate}}</span>
                </div>
                <span>{{'cleanUpMedia.note' | translate}}</span>
            </div>
            <div class="button-group gap-2">
                <button (click)="onCancel()"
                    class="btn-cancel"
                        mat-stroked-button
                        type="button"> 
                        {{ 'global.cancel' | translate }}
                </button>
                <button  (click)="getUnusedMediaFiles()"                
                    class="btn-save" 
                        color="primary"
                        mat-flat-button
                        type="button">
                        {{ 'global.search' | translate }}
                </button>
            </div>
        </div>
    </div>
        <flow-media-clean-up-table [data]="data" [totalCount]="totalCount" [params]="defaultParams"
        (paramsChange)="handleParamsChange($event)" (selectedMedia)="handleDeleteMedia($event)"></flow-media-clean-up-table>   
</div>