import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { AppService } from '@app/app.service';

@Component({
  selector: 'flow-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidenavComponent {
  @Input() navMini = false;

  constructor(
    private router: Router,
    public appService: AppService
  ) { }

  isActive(path: string): boolean {
    const matchOptions: IsActiveMatchOptions = {
      paths: 'exact',
      queryParams: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored'
    };
    return this.router.isActive(path, matchOptions);
  }
}