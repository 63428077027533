import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription, debounceTime, distinctUntilChanged, forkJoin, of, takeUntil } from 'rxjs';
import { AuthService, UserRoleEnum } from '@core/auth/auth.service';
import { indicateLoading } from '@core/indicate-loading';
import { unsubscribeMixin } from '@core/unsubscribe';
import { Platform } from '@angular/cdk/platform';
import { PermissionsService } from '@app/core/services/permissions.service';
import {PageEvent} from "@angular/material/paginator";
import {PaginatorParams} from "@api/models/paginator";
import { ChannelsApi } from '@api/services/channels-api';
import { ConfirmationModalComponent } from '@app/shared/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SystemMessageService } from '@app/core/services/system-message.service';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { Channel, Customer, CustomerApi } from '@api/index';

@Component({
  selector: 'flow-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss']
})
export class ChannelComponent extends unsubscribeMixin() implements OnInit{
  displayedColumns: string[] = ['id', 'name', 'customer'];
  dataSource: MatTableDataSource<ChannelListItem>;
  customers: Customer[];
  isAdmin: boolean;
  filterForm: FormGroup<ChannelFilterFormGroup>;
  filterValues: ChannelFilterValues;
  filterRequest: Subscription;
  customerId: number;
  totalCount: number = 0;
  params: ChannelParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'name',
    sortDirection: 'asc'
  }
  pageSizeOptions = [5, 10, 25, 100];
  loading$ = {
    channel: new BehaviorSubject(false),
    save: new BehaviorSubject(false)
  }

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private customerApi: CustomerApi,
    private channelsApi: ChannelsApi,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    public platform: Platform,
    private permissionsService: PermissionsService,
    private dialog: MatDialog,
    private translateConfigService: TranslateConfigService,
    private systemMessageService: SystemMessageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initFilter();
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
    if (!this.isAdmin) {
      this.customerId = user?.currentCustomerId || user?.customerId;
      this.filterForm.get('customers').setValue([this.customerId])
      this.displayedColumns.push('actions');
    } else {
      this.displayedColumns.push('status', 'actions');
      this.getChannels(this.params);
    }
  }

  private initFilter(): void {
    this.filterForm = this.formBuilder.group({
      customers: this.formBuilder.control(null)
    });
    this.filterForm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(50),
      distinctUntilChanged(),
    ).subscribe(this.onFilterChanges);
  }

  getChannels(params: ChannelParams): void {
    this.filterRequest?.unsubscribe();
    this.filterRequest = forkJoin([
      params?.customerIds && params?.customerIds.length > 0 ? this.channelsApi.getChannelsList( params ) : of(null),
      this.isAdmin ? this.customerApi.getCustomers() : of(null),
      !this.isAdmin ? this.customerApi.getCustomerById(this.customerId) : of(null)
    ])
      .pipe(takeUntil(this.ngUnsubscribe), indicateLoading(this.loading$.channel))
      .subscribe(([channelsResponse, customersResponse, customerResponse]) => {
        const customersList = customersResponse || [customerResponse]
        if (!this.customers || this.customers?.length === 0) {
          this.customers = customersList.map((c) => ({ id: c.id, name: c.name }))
        }
        this.totalCount = channelsResponse ? channelsResponse.totalCount : 0
        const channels = !channelsResponse ? [] : channelsResponse?.items.map(loc => {
          const listItem = loc as ChannelListItem;
          listItem.customerName = customersList?.find(c => c.id === loc.customerId)?.name || '';
          return listItem;
        })
        this.dataSource = new MatTableDataSource(channels);
      });
  }

  onToggle(event: Event, channel: ChannelListItem, isActive: boolean): void {
    event.preventDefault();
    event.stopPropagation();
    this.channelsApi.editChannel({ ...channel, isActive})
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe({
        complete: () => {
          this.filterForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        },
        error: () => {
          this.filterForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        }
      });
  }

onDeleteClick(event: Event, channelId: number): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: {
        remove: () => this.onRemoveChannel(channelId),
        logic: true
      }
    })
  }

  private onRemoveChannel(channelId: number) {
    this.channelsApi.deleteChannel(channelId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.dialog.closeAll();
        const filteredList = this.dataSource.data.filter(x => x.id !== channelId);
        this.dataSource.data = filteredList;
        this.systemMessageService.success(this.translateConfigService.instant("notifications.success.clientDeleted"));
      });
  }

  private onFilterChanges = (values: ChannelFilterValues): void => {
    this.filterValues = values;
    this.getChannels({...this.params, customerIds: this.filterValues.customers});
  }

  redirectToEditPage(channelId: number): void {
    if (this.permissionsService.hasPermissions(['UpdateChannelAdmin'])) {
      this.router.navigate(['/channels', channelId]);
    }
  }

  handlePageEvent(e: PageEvent) {
    this.params.pageNumber = e.pageIndex;
    this.params.pageSize = e.pageSize;
    if (this.customerId)
      this.params.customerIds = [this.customerId];
    this.getChannels({...this.params, customerIds: this.filterValues.customers});
  }

  handleSortEvent(sort: Sort) {
    this.params = { ...this.params, pageNumber: 0, pageSize: 10, sortBy: sort?.active, sortDirection: sort.direction || (this.params.sortDirection == 'asc' ? 'desc' : 'asc') || 'asc' };
    this.getChannels({...this.params, customerIds: this.filterValues.customers});
  }
}

interface ChannelParams extends PaginatorParams {
  customerIds?: number[]
}

interface ChannelFilterValues {
  customers?: number[]
}

interface ChannelFilterFormGroup {
  customers: FormControl<number[]>;
}

interface ChannelListItem extends Channel {
  customerName?: string;
}
