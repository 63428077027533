import { unsubscribeMixin } from '@app/core/unsubscribe';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, catchError, defaultIfEmpty, defer, forkJoin, map, mergeMap, Observable, of, switchMap, takeUntil, tap } from 'rxjs';

import { AppService } from '@app/app.service';
import { CustomerProfiles, UserCreateModel, UpdateUserRequest } from '@api/models/user';
import { AuthService, UserRoleEnum } from '@app/core/auth/auth.service';
import { indicateLoading } from '@app/core/indicate-loading';
import { EnumService } from '@app/core/services/enum.service';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { SystemMessageService } from '@app/core/services/system-message.service';
import { CustomerPermissionApi } from '@api/services/customer-permission-api';
import { PermissionsTableComponent } from '@app/shared/permissions-table/permissions-table.component';
import { Customer, CustomerGroups, PermissionsConfig, GroupApi, UserPermissionApi, UsersApi, CustomerApi } from '@api/index';
import { UserInviteRequest } from '@api/models/user-invite-request';
import { CustomEmailValidator } from '@app/core/services/media.service';

@Component({
  selector: 'flow-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent extends unsubscribeMixin() implements OnInit {
  @ViewChild(PermissionsTableComponent) permissionsTableComponent: PermissionsTableComponent;
  userValidationErrors: string[] = [];
  userId: number;
  user: UserCreateModel;
  customers: Customer[];
  filteredCustomers: Customer[];
  form: FormGroup<UserFormGroup>;
  groupForm: FormGroup;
  admin: UserRoleEnum = UserRoleEnum.Admin
  customer: UserRoleEnum = UserRoleEnum.Customer
  edit: UserRoleEnum;
  userRole = UserRoleEnum;
  selectedIndex = 0;
  loading$ = {
    init: new BehaviorSubject(true),
    save: new BehaviorSubject(false)
  }
  initializingForm = true;
  groups: CustomerGroups[] = [];
  selectablePermissions: string[];
  permissionList: PermissionsConfig = { nodes: [] };
  isEditable: boolean = true;
  title: string;
  disablePermissions!: boolean;
  isAdmin: boolean = false;
  customerId: number;
  formRawValues;
  userRoles = this.enumService.userRoles;
  steps = [ 
    {title: 'createUser.steps.title', subtitle: 'createUser.subTitles.generalInfo'},
    {title: 'createUser.steps.title', subtitle: 'createUser.subTitles.permissions'}
  ];

  constructor(
    private usersApi: UsersApi,
    private groupApi: GroupApi,
    private customerPermissionApi: CustomerPermissionApi,
    private userPermissionApi: UserPermissionApi,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    private appService: AppService,
    private customerApi: CustomerApi,
    public enumService: EnumService,
    private translateConfigService: TranslateConfigService,
    private authService: AuthService
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
    this.userId = +this.activatedRoute.snapshot.params['id'] || null;
    const navigation = this.router.getCurrentNavigation();
    this.selectedIndex = navigation?.extras?.state?.selectedIndex || 0;

  }

  ngOnInit(): void {
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId && !user.customerId;
    this.customerId = user.currentCustomerId || user.customerId;
    this.disablePermissions = this.userId ? false : true;
    this.getTitle();

    forkJoin([
      this.customerApi.getCustomers(),
      this.userId ? this.usersApi.getUser({ userId: this.userId, customerId: this.customerId }) : of(null)
    ]).pipe(
      tap(([customers, user]) => {
        this.customers = customers;
        this.filteredCustomers = customers;
        this.user = user;
      }),
      switchMap(() => this.userId ? this.getPermissions() : of(null)),
      takeUntil(this.ngUnsubscribe),
      indicateLoading(this.loading$.init))
      .subscribe(() => {
        if (this.user) {
          const customerIds = this.user.customerProfiles.map(cp => cp.customerId);
          this.filteredCustomers = this.customers.filter(c => !customerIds.includes(c.id));
        }
        this.initForm(this.user);
      });
  }

  getPermissions(): Observable<CustomerGroups[]> {
    const customerIds = this.user?.customerProfiles.map(c => c?.customerId);
    return defer(() => {
      if (customerIds && customerIds.length > 0) {
        return this.loadIndividualPermissions(customerIds).pipe(
          switchMap(() => this.permissionsTableComponent ? this.loadGroups(customerIds) : of(null))
        );
      } else {
        return of(null);
      }
    });
  }

  private loadGroups(customerIds: number[]): Observable<CustomerGroups[]> {
    return this.handleObservable(
      this.groupApi.getGroupsForCustomers(customerIds).pipe(
        tap((data) => {
          this.groups = data;
          if (this.groups.length > 0) {
            this.initGroupForm(this.user);
          }
        })
      )
    );
  }

  private loadIndividualPermissions(customerIds: number[]): Observable<PermissionsConfig> {
    return this.handleObservable(
      this.customerPermissionApi.getPermissionsForCustomers(customerIds).pipe(
        tap((customerData) => {
          this.selectablePermissions = customerData.nodes.flatMap(node => node.permissions);
        }),
        mergeMap((batchData) =>
          this.user
            ? this.userPermissionApi.getPermissionsForUserInCustomers(this.userId, this.customerId).pipe(
              map((customerUserPermissionsData) => {
                return customerUserPermissionsData && customerUserPermissionsData.nodes.length > 0 ? customerUserPermissionsData : batchData
              }),
              defaultIfEmpty(batchData)
            )
            : of(batchData)
        ),
        tap((result) => {
          this.permissionList = result;
        })
      )
    );
  }

  getTitle() {
    this.title = this.userId ? 'editUser.title' : 'createUser.title';
  }

  onSaveClick(): void {
    if (!this.form.valid) {
      this.userValidationErrors = this.setValidationMessages(this.form);
      this.systemMessageService.error(this.translateConfigService.instant('createCustomer.validationErrors.errors'));
      return;
    }
    this.save();
  }

  save() {
    const customerProfiles: CustomerProfiles[] = this.form.value.customersArray.map((customerFormGroup: any) => ({
      customerId: customerFormGroup.id,
      customerName: customerFormGroup.name,
      profileId: customerFormGroup.profileId,
      profileName: customerFormGroup.profileName,
      groupId: customerFormGroup.groupId
    }));

    const inviteRequest: UserInviteRequest = {
      email: this.form.value.email,
      linkPageForPassword: `${window.location.origin}/register`,
      roleId: this.form.value.roleId,
      ...(this.form.value.roleId === UserRoleEnum.Admin && { name: this.form.value.profileName }),
      customerProfiles: customerProfiles
    };

    const updateRequest: UpdateUserRequest = {
      id: this.userId,
      ...(this.edit === UserRoleEnum.Admin && { name: this.form.value.profileName }),
      customerProfiles: customerProfiles
    };

    const apiRequest = this.userId ? "updateUser" : "invite";
    const body = this.userId ? updateRequest : inviteRequest;
    const successMsg = this.userId ? "userUpdate" : "userCreate";

    this.usersApi[apiRequest](body)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe((result) => {
        this.systemMessageService.success(this.translateConfigService.instant("notifications.success." + successMsg, this.userId ? { name: this.form.value.email } : this.form.value.email));
        this.disablePermissions = false;
        this.user = { ...this.user, customerProfiles: result };
        this.router.navigate(['/users']);
        if (!this.userId)
          this.router.navigate(['/users', result || this.userId], { state: { selectedIndex: 1 } });
      });
  }


  onPermissionSave(): void {
    const dropdownFormValue = this.groupForm ? this.groupForm.value : {};

    const selectedGroups = this.groups.map(group => ({
      customerId: group.customerId,
      groupId: dropdownFormValue.hasOwnProperty('group' + group.customerId) ? dropdownFormValue['group' + group.customerId] : null
    }));

    // placed them individually since user create will probably change
    selectedGroups.forEach(group => {
      if (group.groupId) {
        const profileId = this.user?.customerProfiles.find((profile) => group.customerId === profile.customerId)?.profileId;
        this.groupApi.assignGroupToUsers(group.groupId, [profileId]).subscribe({
          next: () => {
            this.systemMessageService.success(this.translateConfigService.instant("notifications.success.userUpdate", { name: this.form.value.email }));
            this.router.navigate(['/users']);
          },
          error: err => {
            this.systemMessageService.error("Failed to assign permissions to user");
            console.error(`Failed to assign group ${group.groupId} to user ${this.userId}`, err)
          }
        });
      }
    });

    const enabledNodes = this.permissionsTableComponent.nodes.controls.filter(node => !node.disabled);
    const selectedPermissionsList = this.permissionsTableComponent.getSelectedPermissions();

    enabledNodes.forEach(node => {
      const nodeValue = node.value;
      const selectedNodePermissions = selectedPermissionsList.find(item => item.id === nodeValue.id);
      const profileId = this.user?.customerProfiles.find((profile) => nodeValue.id === profile.customerId)?.profileId;

      this.userPermissionApi.assignPermissionsToUser(profileId, nodeValue.id, selectedNodePermissions.permissions).subscribe({
        next: () => {
          this.systemMessageService.success(this.translateConfigService.instant("notifications.success.userUpdate", { name: this.form.value.profileName }));
          this.router.navigate(['/users']);
        },
        error: err => {
          this.systemMessageService.error("Failed to assign permissions to user");
          console.error(`Failed to assign permissions to user ${this.userId} for customer ${nodeValue.id}`, err)
        }
      });
    });
  }

  private initGroupForm(user?): void {
    const formGroup = {};
    this.groups.forEach(group => {
      const userGroupId = user?.customerProfiles?.filter(profile => profile.customerId == group.customerId)[0].groupId || null;
      formGroup['group' + group.customerId] = new FormControl(userGroupId);

      if (userGroupId) {
        this.onGroupChange(group.customerId, userGroupId);
      }
    });

    this.groupForm = this.formBuilder.group(formGroup);
  }

  private initForm(user?: UserCreateModel): void {
    const customerFormGroups: FormGroup<CustomerFormGroup>[] = (user?.customerProfiles?.map((customer: CustomerProfiles) => this.createCustomerGroup(customer))) || [];

    this.form = this.formBuilder.group({
      email: new FormControl({ value: user?.email || '', disabled: user ? true : false }, [Validators.required, CustomEmailValidator()]),
      roleId: new FormControl({ value: this.customerId ? 2 : (user?.roleId || ''), disabled: user ? true : false }, Validators.required),
      selectedCustomerId: new FormControl({value: this.isAdmin ? null : user?.customerProfiles[0]?.customerId, disabled: this.customerId ? true : false}),
      profileName: new FormControl(user?.roleId == UserRoleEnum.Admin ? user?.name : null),
      customersArray: this.formBuilder.array(customerFormGroups)
    }) as FormGroup<UserFormGroup>;

    if (user) {
      this.edit = (user.roleId === UserRoleEnum.Admin) ? UserRoleEnum.Admin : UserRoleEnum.Customer;
    }

    if (user && user.customerProfiles && user.customerProfiles.length > 0) {
      this.customersArray.updateValueAndValidity({ emitEvent: true });
    }

    this.initializingForm = false;
  }

  selectedTabChange(index) {
    this.selectedIndex = index;

    if (index === 1) {
      this.getPermissions().subscribe()
    }
  }

  setValidationMessages(formGroup: FormGroup) {
    const validationErrors = []
    Object.keys(formGroup.controls).forEach((controlKey: any) => {
      const control = formGroup.get(controlKey);

      if (control instanceof FormGroup) {
        this.setValidationMessages(control);
      } else {
        if (control && control.errors) {
          for (const [key, value] of Object.entries(control.errors)) {
            switch (key) {
              case 'required':
                const isRequired = this.translateConfigService.instant(`createUser.validationErrors.${controlKey}`) + " " + this.translateConfigService.instant(`createCustomer.validationErrors.required`)
                validationErrors.push(isRequired);
                break;
              case 'email':
                const validEmail = this.translateConfigService.instant(`createUser.validationErrors.${controlKey}`) + " " + this.translateConfigService.instant(`createCustomer.validationErrors.validEmail`)
                validationErrors.push(this.translateConfigService.instant(validEmail));
                break;
            }
          };
        }
      }
    })

    return validationErrors;
  }

  onGroupChange(customerId: number, roleId: number): void {
    this.permissionsTableComponent.onGroupChange(customerId, roleId);
  }

  get customersArray(): FormArray {
    return this.form.get('customersArray') as FormArray;
  }

  getCustomerFormGroup(index: number): FormGroup {
    return this.customersArray.at(index) as FormGroup;
  }

  addCustomerProfile(): void {
    let rawValues = this.form.getRawValue();
    const selectedCustomerId = rawValues.selectedCustomerId || this.customerId;
    const profileName = rawValues.profileName;
    const selectedCustomer = this.customers.find(c => c.id === selectedCustomerId);
    
    if (selectedCustomer) {
      const customerForm = this.createCustomerGroup({
        customerId: selectedCustomer.id,
        customerName: selectedCustomer.name,
        profileName,
        profileId: this.user?.customerProfiles.find((profile) => selectedCustomerId === profile.customerId)?.profileId
      });
      this.filteredCustomers = this.filteredCustomers.filter(fc => fc.id != selectedCustomerId);
      this.customersArray.push(customerForm);

      // Reset the fields
      if (rawValues.roleId == 1)
        this.form.get('selectedCustomerId')?.reset();
      this.form.get('profileName')?.reset();
    }
  }

  removeCustomerProfile(index: number, customer) {
    const oldCustomer = customer.getRawValue();
    this.filteredCustomers.push({id: oldCustomer.id, name: oldCustomer.name});
    this.filteredCustomers = this.filteredCustomers.sort((a, b) => a.name.localeCompare(b.name));
    this.form.get('selectedCustomerId').setValue(null);
    this.customersArray.removeAt(index);
  }

  handleObservable<T>(observable: Observable<T>): Observable<T> {
    return observable.pipe(
      takeUntil(this.ngUnsubscribe),
      indicateLoading(this.loading$.init),
      catchError(error => {
        console.error('An error occurred', error);
        return of(null as T);
      })
    );
  }

  private createCustomerGroup(customerProfile: CustomerProfiles): FormGroup<CustomerFormGroup> {
    return this.formBuilder.group({
      id: new FormControl(customerProfile.customerId),
      name: new FormControl(customerProfile.customerName),
      profileId: new FormControl(customerProfile.profileId),
      profileName: new FormControl(customerProfile.profileName),
      groupId: new FormControl(customerProfile.groupId),
    }) as FormGroup<CustomerFormGroup>;
  }
}

export interface CustomerFormGroup {
  id: FormControl<number>;
  name: FormControl<string>;
  profileId: FormControl<number>;
  profileName: FormControl<string>;
  groupId: FormControl<number>;
}

export interface UserFormGroup {
  email: FormControl<string>;
  roleId: FormControl<number>;
  selectedCustomerId: FormControl<number>;
  profileName: FormControl<string>;
  customersArray: FormArray<FormGroup<CustomerFormGroup>>;
}


