import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { ConfigurationSettings } from '../models/configuration-settings';

@Injectable({
  providedIn: 'root',
})
export class GlobalConfigurationApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  static readonly GlobalConfigurationPath = '/globalConfiguration';
  
  getSettings(): Observable<any> {
    return this.http.get<ConfigurationSettings>(`${this.rootUrl}${GlobalConfigurationApi.GlobalConfigurationPath}`);
  }

  updateSettings(params): Observable<any> {
    return this.http.put<ConfigurationSettings>(`${this.rootUrl}${GlobalConfigurationApi.GlobalConfigurationPath}`, params);
  }
}
