import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MediaUploadStatusEnum } from '../media.model';

@Component({
  selector: 'flow-media-details',
  templateUrl: './media-details.component.html',
  styleUrls: ['./media-details.component.scss']
})
export class MediaDetailsComponent implements OnInit {
  mediaUploadStatusEnum = MediaUploadStatusEnum;

  constructor(public dialogRef: MatDialogRef<MediaDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    let data = this.data;
  }

  close() {
    this.dialogRef.close();
  }
}
