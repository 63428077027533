import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, of, takeUntil } from 'rxjs';
import { AppService } from '@app/app.service';
import { indicateLoading } from '@app/core/indicate-loading';
import { SystemMessageService } from '@app/core/services/system-message.service';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { unsubscribeMixin } from '@app/core/unsubscribe';
import { AuthService, UserRoleEnum } from '@app/core/auth/auth.service';
import { ChannelsApi } from '@api/services/channels-api';
import { Channel, Customer, CustomerApi } from '@api/index';

@Component({
  selector: 'flow-channel-create',
  templateUrl: './channel-create.component.html',
  styleUrls: ['./channel-create.component.scss']
})
export class ChannelCreateComponent extends unsubscribeMixin() implements OnInit {
  isAdmin: boolean;
  channelId: number;
  channel: Channel;
  customers: Customer[];
  form: FormGroup<ChannelFormGroup>;

  loading$ = {
    channel: new BehaviorSubject(false),
    save: new BehaviorSubject(false)
  }

  constructor(
    private customerApi: CustomerApi,
    private channalsApi: ChannelsApi,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    private appService: AppService,
    private translateConfigService: TranslateConfigService,
    private authService: AuthService
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
    this.channelId = +this.activatedRoute.snapshot.params['id'] || null;
    this.initForm();
  }

  ngOnInit(): void {
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;

    forkJoin([
      this.channelId ? this.channalsApi.getChannelById(this.channelId) : of(null),
      this.customerApi.getCustomers(),
    ]).pipe(
      takeUntil(this.ngUnsubscribe),
      indicateLoading(this.loading$.channel))
      .subscribe(([channel, customers]) => {
        this.channel = channel;
        this.customers = customers;
        this.initForm(channel);
      });

  }

  onSaveClick(): void {
    if (!this.form.valid) {
      return;
    }
    const body = {
      ...this.channel,
      ...this.form.value
    }

    const mode = this.channelId ? 'editChannel' : 'saveChannel';
    this.channalsApi[mode](body)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe(() => {
        this.systemMessageService.success(this.translateConfigService.instant("notifications.success.channelCreate", this.form.value.name));
        this.router.navigate(['/channels']);
      });
  }

  private initForm(channel?: Channel): void {
    this.form = this.formBuilder.group({
      id: this.channelId,
      name: [channel?.name, Validators.required],
      customerId: [channel?.customerId, Validators.required]
    });
    if (this.channelId && !channel) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    if (this.channelId) {
      this.form.get('customerId').disable();
    }
  }
}

interface ChannelFormGroup {
  id: FormControl<number>;
  name: FormControl<string>;
  customerId: FormControl<number>;
}
