import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { Channel } from '../models/channels';

@Injectable({
  providedIn: 'root',
})
export class ChannelsApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  static readonly ChannelsPath = '/channels';

  getChannels(params?): Observable<any> {
    return this.http.get(`${this.rootUrl}${ChannelsApi.ChannelsPath}`, params ? { params } : {});
  }

  getChannelsList(params?): Observable<any> {
    return this.http.get(`${this.rootUrl}${ChannelsApi.ChannelsPath}/channelslist`, params ? { params } : {});
  }

  saveChannel(channel): Observable<any> {
    return this.http.post<Channel>(`${this.rootUrl}${ChannelsApi.ChannelsPath}`, channel);
  }

  editChannel(channel): Observable<any> {
    return this.http.put<Channel>(`${this.rootUrl}${ChannelsApi.ChannelsPath}`, channel);
  }

  getChannelById(channelId: number): Observable<any> {
    return this.http.get(`${this.rootUrl}${ChannelsApi.ChannelsPath}/${channelId}`);
  }

  deleteChannel(channelId: number): Observable<any> {
    return this.http.delete(`${this.rootUrl}${ChannelsApi.ChannelsPath}/${channelId}`);
  }
}