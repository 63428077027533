import { Platform } from '@angular/cdk/platform';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ActivatedRoute, Router } from "@angular/router";
import { DateTime } from "luxon";
import { debounceTime, distinctUntilChanged, forkJoin, of, Subscription, takeUntil, map } from 'rxjs';
import { Customer, CustomerApi, PersonOfInteresetApi, PersonOfInterest, StandardMedia, StatisticsApi, Tag } from '@api/index';
import { PermissionsService } from '@core/services/permissions.service';
import { Media, MediaService } from '@core/services/media.service';
import { unsubscribeMixin } from '@core/unsubscribe';
import { ConfirmationModalComponent } from '@shared/confirmation-modal/confirmation-modal.component';
import { PaginatorParams } from "@api/models/paginator";
import { PersonOfInterestResponse } from "@api/models/person-of-interest";
import { ActiveReportEnum } from "@api/models/active-report-enum";
import { TagsApi } from '@api/services/tags-api.service';
import { AuthService, UserRoleEnum } from "../../core/auth/auth.service";
import { MediaModalService } from "../../core/services/media-modal.service";
import { PoiFiltersModalComponent } from './poi-filters-modal/poi-filters-modal.component';

@Component({
  selector: 'flow-person-of-interest',
  templateUrl: './person-of-interest.component.html',
  styleUrls: ['./person-of-interest.component.scss']
})
export class PersonOfInterestComponent extends unsubscribeMixin() implements OnInit {
  displayedColumns: string[] = ['id', 'media', 'name', 'category', 'tags', 'fileName', 'customer', 'size', 'created', 'actions'];
  dataSource: MatTableDataSource<PersonOfInterestListItem>;
  panelOpenState = true;
  tags: Tag[];
  categories: Tag[];
  customers: Customer[];
  filterForm: FormGroup<PoiFilterFormGroup>;
  filterRequest: Subscription;
  customerId: number;
  filterValues: PoiFilterValues;
  isAdmin: boolean;
  isAllSelected = false;
  hideBox = false;
  poiIds: number[] = [];
  selectedPois = []
  numberOfPois: number;
  numberOfMaxPois = 25;
  selectedTabIndex: number = 0;
  ActiveReportEnum = ActiveReportEnum;
  consentGiven: boolean = false;
  consentKey = 'poi-consent';
  params: PaginatorParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'name',
    sortDirection: 'asc'
  }
  totalCount: number = 0;
  filtersNumber: number = 0;
  pageSizeOptions = [5, 10, 25, 100];
  disablePoiInsights: boolean = false;
  disablePoiCategoriesInsights: boolean = false;
  disablePoiReportsInsights: boolean = false;
  deletedPOI: boolean = false;
  @Input() isModal: boolean;
  @Input() customerIdModal: number;


  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private customerApi: CustomerApi,
    private tagsApi: TagsApi,
    private personOfInterestApi: PersonOfInteresetApi,
    private mediaService: MediaService,
    public dialog: MatDialog,
    public platform: Platform,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private statisticsServiceApi: StatisticsApi,
    private mediaModalService: MediaModalService,
    private router: Router,
    private permissionsService: PermissionsService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute
    .paramMap
    .pipe(map(() => window.history.state))
    .subscribe(state => {
      if (state?.page) this.selectedTabIndex = state.page;
    })
    if (!this.permissionsService.hasPermissions(['ViewPoiInsights'])) {
      this.disablePoiInsights = true;
    }
    if (!this.permissionsService.hasPermissions(['ViewPoiCategoriesInsights'])) {
      this.disablePoiCategoriesInsights = true;
    }
    if (!this.permissionsService.hasPermissions(['ViewPoiReportsInsights'])) {
      this.disablePoiReportsInsights = true;
    }
    if (this.disablePoiInsights && !this.disablePoiCategoriesInsights) this.selectedIndex = 1;
    if (this.disablePoiInsights && this.disablePoiCategoriesInsights && !this.disablePoiReportsInsights) this.selectedIndex = 2;
    this.checkConsent();
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
    this.customerId = user?.currentCustomerId || user?.customerId;
    this.initFilter();
    this.getMultipleSelectFilterData();
    if (!this.isAdmin) {
      this.getMultipleSelectFilterData();
      this.displayedColumns.unshift('checkbox');
      this.displayedColumns = this.displayedColumns.filter(c => c !== 'customer')
      this.filterForm.get('customerIds').setValue([this.customerId])
      this.getPersonOfInterests({...this.params, customerIds: [this.customerId] })
    } else {
      if (this.displayedColumns[0] === 'checkbox') {
        this.displayedColumns.shift();
      }
      else {
        this.getPersonOfInterests(this.params);
      }
    }
  }


  getPersonOfInterests(params?: GetPoiParams): void {
    this.personOfInterestApi.getPersonOfInterests(params)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((personOfInterestsResponse: PersonOfInterestResponse) => {
        this.numberOfPois = personOfInterestsResponse.numberOfPois
        this.numberOfMaxPois = personOfInterestsResponse.numberOfMaxPois
        this.params = { ...this.params, pageNumber: personOfInterestsResponse?.currentPage };
        this.totalCount = personOfInterestsResponse.totalCount
        const personOfInterests = personOfInterestsResponse.items
          .map(poi => {
            const listItem = poi as PersonOfInterestListItem;
            let mediaFile = {
              size: poi.size,
              name: poi.name,
              filename: poi.fileName,
              id: poi.id,
              width: poi.width,
              height: poi.height
            }
            listItem.media = this.mediaService.getPoiMedia(mediaFile);
            listItem.createdDate = DateTime.fromISO(poi.createdDate as string).toFormat('yyyy-MM-dd HH:mm');
            return listItem;
          }) as PersonOfInterestListItem[];
        this.dataSource = new MatTableDataSource(personOfInterests);
      });
  }

  onDeleteSelected(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.poiIds.length > 0) {
      this.dialog.open(ConfirmationModalComponent, {
        width: '500px',
        data: {
          remove: () => this.onRemovePOI(this.poiIds),
          logic: true
        }
      })

    }
  }

  handleSortEvent(sort: Sort) {
    this.params = { ...this.params, ...this.filterValues, pageNumber: 0, pageSize: 10, sortBy: sort?.active, sortDirection: sort?.direction || (this.params.sortDirection == 'asc' ? 'desc' : 'asc') || 'asc' };
    this.getPersonOfInterests({ ...this.params });
  }

  selectMedias(event: MatCheckboxChange, poi: PersonOfInterestListItem) {
    this.isAllSelected = event.checked;
    if (!poi && event.checked) {
      this.dataSource.data.filter(m => {
        m.isSelected = true;
        this.poiIds.push(m.id)
        this.selectedPois.push(m)
      });
    } else if (!poi && !event.checked) {
      this.dataSource.data.filter(m => {
        m.isSelected = false;
      });
      this.poiIds = [];
      this.selectedPois = [];
    } else if (poi && event.checked) {
      poi.isSelected = event.checked;
      this.poiIds.push(poi.id);
      this.selectedPois.push(poi)
    } else if (poi && !event.checked) {
      poi.isSelected = event.checked;
      let index = this.poiIds.indexOf(poi.id);
      if (index !== -1) {
        this.poiIds.splice(index, 1);
        this.selectedPois.splice(index, 1);
      }
    }
  }

  onDeleteClick(event: Event, personOfInterest: PersonOfInterestListItem): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: {
        remove: () => this.onRemovePOI(personOfInterest.id),
        logic: true

      }
    })
  }

  openViewMedia(standardMedia: StandardMedia, $event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.mediaModalService.openViewMediaModal(standardMedia)
  }

  isButtonShown() {
    return !this.isAdmin && this.poiIds.length > 0
  }

  private onRemovePOI(ids) {
    let poisToDelete = Array.isArray(ids) ? ids : [ids];

    this.personOfInterestApi.deletePoi(poisToDelete)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.dialog.closeAll();
        if (!this.isAdmin) {
          this.deletedPOI = true;
          this.getPersonOfInterests({ customerIds: [this.customerId] });
          return
        }
        this.getPersonOfInterests()

      });
  }

  openFilters() {
    const dialogRef = this.dialog.open(PoiFiltersModalComponent, {
      width: '420px',
      data: {
        filterForm: this.filterForm,
        customerIds: this.customers,
        categories: this.categories,
        tags: this.tags,
        isAdmin: this.isAdmin
      }
    })

    dialogRef.afterClosed().subscribe(result => {

      if (result?.filter) {
        this.filtersNumber = 0;
        const filterValues = this.filterForm.getRawValue();
        this.getPersonOfInterests(this.getFilterParams(filterValues));
      }
    });
  }

  getFilterParams(filterValues) {
    let requestParams = Object.keys(filterValues).reduce((acc, key) => {
      if (filterValues[key] !== null) {
        if (key == 'created') {
          if (filterValues[key]?.start !== null) {
            acc['dateFrom'] = filterValues[key].start;
            this.filtersNumber++;
          }
          if (filterValues[key]?.end !== null) {
            acc['dateTo'] = filterValues[key].end;
            this.filtersNumber++;
          }
        } else {
          acc[key] = filterValues[key];
          if (key != 'customerIds' || (key == 'customerIds' && !this.customerId))
            this.filtersNumber++;
        }
      }
      if (this.customerId) {
        acc['customerIds'] = [this.customerId];
      }
      return acc;
    }, {});
    return {
      ...requestParams,
      ...this.params
    }
  }

  handlePageEvent(e: PageEvent) {
    this.params = { ...this.params, ...this.filterValues, pageNumber: e.pageIndex, pageSize: e.pageSize };
    this.getPersonOfInterests(this.params);
  }

  showCreatePoiModal(event, poiID?) {
    event.stopPropagation()
    this.router.navigate(['/person-of-interest', poiID]);

  }

  private initFilter(): void {
    this.filterForm = this.formBuilder.group({
      search: this.formBuilder.control(null),
      categories: this.formBuilder.control(null),
      tags: this.formBuilder.control(null),
      customerIds: this.formBuilder.control(null),
      created: this.formBuilder.group({
        start: this.formBuilder.control(null),
        end: this.formBuilder.control(null)
      }),
    });
    this.filterForm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(this.onFilterChanged);
  }

  private getMultipleSelectFilterData(): void {
    this.filterRequest?.unsubscribe();
    this.filterRequest = forkJoin([
      this.tagsApi.getTags({ customerIds: this.customerId ? [this.customerId] : this.filterValues?.customerIds }),
      this.statisticsServiceApi.getStatisticsCategories({ customerIds: this.customerId ? [this.customerId] : [] }),
      this.isAdmin ? this.customerApi.getCustomers() : of(null),
      !this.isAdmin ? this.customerApi.getCustomerById(this.customerId) : of(null)
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([tags, categories, customers, customer]) => {
        this.tags = tags;
        this.categories = categories
        this.customers = customers || [customer];
      });
  }

  private onFilterChanged = (values: PoiFilterValues): void => {
    this.filterValues = values;
  }

  override ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.filterRequest?.unsubscribe();
  }

  tabChange($event: MatTabChangeEvent): void {
    this.selectedTabIndex = $event.index;
  }

  checkConsent(): void {
    const consent = localStorage.getItem(this.consentKey);
    if (consent) {
      this.consentGiven = true;
    }
  }
}

interface PersonOfInterestListItem extends PersonOfInterest {
  media?: Media;
  isSelected?: boolean;

}

interface PoiFilterFormGroup {
  search: FormControl<string>;
  categories: FormControl<number[]>;
  tags: FormControl<number[]>;
  customerIds: FormControl<number[]>;
  created: FormGroup<{
    start: FormControl<DateTime>,
    end: FormControl<DateTime>
  }>;
}

interface PoiFilterValues {
  search?: string;
  categories?: number[];
  tags?: number[];
  customers?: number[];
  customerIds?: number[];
  created?: {
    start?: DateTime;
    end?: DateTime;
  }
}

interface GetPoiParams extends PaginatorParams {
  customerIds?: number[],
  categories?: number[],
  tags?: number[],
  text?: string,
  dateFrom?: string,
  dateTo?: string,
}
