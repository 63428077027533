import { Component, Input, SimpleChanges, ViewChild, OnInit, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';

import { StatisticsApi } from '@api/index';
import { TotalNumberOfExposures } from '@api/models/exposures/TotalNumberOfExposures.model';
import { TargetGroupHelper } from '@app/admin/target-group/target-group.helper';
import { EnumService } from '@core/services/enum.service';
import { HourlySeperatedAttentionTime } from '@app/models/hourly-separated-attention-time';
import { StatisticsService } from '../../statistics.service';
import { MediaSubtitleData } from '../views.model';

@Component({
  selector: 'flow-average-attention-time',
  templateUrl: './average-attention-time.component.html',
  styleUrls: ['./average-attention-time.component.scss']
})
export class AverageAttentionTimeComponent implements OnInit, OnChanges {
  @Input() filter: any;
  @Input() hourlySeperatedAttentionTime: Array<HourlySeperatedAttentionTime>;
  @Input() totalNumberOfExposures: TotalNumberOfExposures[];
  @Input() mediaSubtitleData: MediaSubtitleData;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  public chartPlugins = [DataLabelsPlugin];
  public weekDays = this.enumService.weekDays;
  public chartData: ChartData<'line', number[], string | string[]> = null;
  public chartOptions: ChartConfiguration['options'] = {};
  noChartData: boolean = false;

  constructor(
    protected statisticsApi: StatisticsApi,
    protected statisticsService: StatisticsService,
    protected targetGroupHelper: TargetGroupHelper,
    protected translateService: TranslateService,
    protected enumService: EnumService
  ) { }

  ngOnInit() {
    this.setChartOptions();

    this.translateService.onLangChange.subscribe(() => {
      this.setChartOptions();
      this.updateChartData();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.hourlySeperatedAttentionTime)
      this.updateChartData();
  }

  updateChartData() {
    const color = 'rgba(31, 87, 146, 0.6)';
    const borderColor = 'rgba(31, 87, 146)';
    const dataHours = this.hourlySeperatedAttentionTime.map(h => ({
      hour: h.hour.toString() + ':00',
      attentionTime: h.attentionTime
    }));

    const hours = Array.from({ length: 24 }, (_, i) => i.toString() + ':00');
    const totalArray = dataHours.map(h => h.attentionTime);

    this.hourlySeperatedAttentionTime?.forEach((hour) => {
      totalArray[hour.hour] = hour?.attentionTime;
    });

    this.noChartData = this.hourlySeperatedAttentionTime?.length ? false : true;

    const dataSet = {
      pointRadius: 0,
      label: this.translateService.instant('exposures.averageAttentionTimeGraphLabel'),
      data: totalArray,
      fill: false,
      backgroundColor: color,
      hoverBackgroundColor: color,
      borderColor: borderColor,
      pointBackgroundColor: borderColor
    };

    this.chartData = {
      labels: dataHours.length > 0 ? dataHours.map(h => h.hour) : hours,
      datasets: [dataSet],
    };

    this.chart?.update();
  }

  private setChartOptions(){
    this.chartOptions = {
      elements: {
        line: { tension: 0.5 },
      },
      scales: {
        x: {
          type: 'category', // This line is important for X-axis to recognize labels
          title: {
            display: false,
          },
        },
        y: {
          title: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          position: 'left',
          align: 'start',
          labels: {
            boxWidth: 1,
            boxHeight: 30,
            textAlign: 'left',
            padding: 5
          }
        },
        datalabels: { display: false },
      },
    };
  }
}
export const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0') + ':00');
