import { EngagementTypeEnum } from "@api/models/engagement-type-enum";

export interface MediaSubtitleData {
  mediaName: string,
  engagementName: string,
  engagementType: EngagementTypeEnum,
  start: string,
  end: string
}

export interface SummaryPeriods {
  type: number,
  title: string,
  data: number | string,
  hasTooltip: boolean
  display?: boolean;
}

export enum SummaryCards {
  Total,
  Primary,
  Secondary,
  ShareOfVoice,
  AirTime,
  AttentionTime,
  Impressions
}