import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs';
import { UnusedMediaListRequest } from '@api/models/medias/unused-media-list-request';
import { MediaApi } from '@api/services/media-api';
import { unsubscribeMixin } from '@app/core/unsubscribe';
import { PaginatorParams } from '@api/models/paginator';
import { Router } from '@angular/router';
import { MediaFile } from '@api/index';
import { AuthService } from '@app/core/auth/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@app/shared/confirmation-modal/confirmation-modal.component';
import { SystemMessageService } from '@app/core/services/system-message.service';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { FolderDeletion } from '@api/models/folder-deletion';
import { BackendErrorsEnum } from '@api/models/be-errors-enum';
import { MediaNotificationService } from '../shared/media-notification-service';

@Component({
  selector: 'flow-media-clean-up',
  templateUrl: '/media-clean-up.component.html',
})

export class MediaCleanUpComponent extends unsubscribeMixin() implements OnInit, OnDestroy {
  user = this.authService.userData;

  defaultParams: PaginatorParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'lastUsed',
    sortDirection: 'desc',
  };

  queryParams: UnusedMediaListRequest = {
    includeNestedMedia: true,
    includeNeverUsed: false,
    lastUsedOlderThan: 3,
    customerId: this.user.currentCustomerId || this.user.customerId,
    ...this.defaultParams
  };

  data: MediaFile[];
  totalCount: number;

  constructor(private mediaApi: MediaApi,
    private router: Router,
    private authService: AuthService,
    public dialog: MatDialog,
    private systemMessageService: SystemMessageService,
    private translateConfigService: TranslateConfigService,
    private mediaNotificationService: MediaNotificationService)   
  { super(); }

    ngOnInit(): void {
    this.getUnusedMediaFiles();
  }

  handleFilterValues(values: { includeNestedMedia: boolean; includeNeverUsed: boolean, lastUsedOlderThan: number }) {
      this.queryParams = {
          ...this.queryParams,
          includeNestedMedia: values.includeNestedMedia,
          includeNeverUsed : values.includeNeverUsed,
          lastUsedOlderThan: values.lastUsedOlderThan,
          ...this.defaultParams
      };   
  }

  handleParamsChange(params: PaginatorParams) {
    this.queryParams = {
      ...this.queryParams,
      ...params
    }
    this.getUnusedMediaFiles();
  }

  getUnusedMediaFiles(): void {
    this.mediaApi.getUnusedMediaList(this.queryParams).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
            next: response => {
                this.data = response.items;
                this.totalCount = response.totalCount;
            },
            error: error => { this.systemMessageService.error(this.translateConfigService.instant(error)); }
        })
  }

  handleDeleteMedia(mediaIds: number[]): void {
    const dialogRef: MatDialogRef<ConfirmationModalComponent> = this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: {
        remove: () => this.deleteMediaFiles(mediaIds, dialogRef),
        type: 1,
        logic: true
      }
    })
  }

  deleteMediaFiles(mediaIds: number[], dialogRef: MatDialogRef<ConfirmationModalComponent>) {    
    this.mediaApi.removeMedias({ mediaIds: mediaIds })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          dialogRef.close();
          this.systemMessageService.success(
            this.translateConfigService.instant('notifications.success.mediasDeleted')
          );
          this.router.navigate(['/medias']);
        },
        error: (error) => {
          if (error?.errorType && (error?.errorType === BackendErrorsEnum.mediasInUse || error?.errorType === BackendErrorsEnum.mediaInUse)) {
            this.calculateMediaDeletionErrors(error);
          }
          dialogRef.close();
        }
      });    
  }

  calculateMediaDeletionErrors(result: FolderDeletion): void {
    const message = this.mediaNotificationService.initializeMessage();
    const medias = result.mediasInUse;
  
    this.mediaNotificationService.processMediaList(
      medias,
      result.mediaInUse,
      message,
      result.errorType,
      this.mediaNotificationService.buildMedia.bind(this.mediaNotificationService)
    );
  
    this.mediaNotificationService.openErrorDialog(this.dialog, message);
    }
  
  onCancel() {
    this.router.navigate(['/medias']);
    }
}
