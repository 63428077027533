import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { EnumObject, EnumService } from "../../../core/services/enum.service";
import { AdvertisingType } from "../../../../_api/models/advertising-type";
import { Client } from "../../../../_api/models/client";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, forkJoin, of, takeUntil } from "rxjs";
import { indicateLoading } from "../../../core/indicate-loading";
import { unsubscribeMixin } from "../../../core/unsubscribe";
import { MatOptionSelectionChange } from '@angular/material/core';
import { Country } from '@angular-material-extensions/select-country'
import { COUNTRIES_EN } from "../../../../_api/countries/en-countries";
import { COUNTRIES_SE } from "../../../../_api/countries/se-countries";
import { CURRENCIES } from "../../../../_api/currencies";
import { ClientApi } from '@api/services/client-api';
import { SystemMessageService } from '@app/core/services/system-message.service';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, UserRoleEnum } from '@app/core/auth/auth.service';
import { CustomEmailValidator } from '@app/core/services/media.service';
import { Customer, CustomerApi } from '@api/index';


@Component({
  selector: 'flow-client-create',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.scss'],
})
export class ClientCreateComponent extends unsubscribeMixin() implements OnInit {
  adTypes: EnumObject<AdvertisingType>[];
  countries: Country[] = [];
  countryCodes: Country[] = []
  form: FormGroup;
  countryTranslation: string;
  language: string
  isAdmin: boolean;
  customers: Array<Customer>;
  predefinedCountries: Country[] = [];
  clientId: number;
  public _reload = true;
  isEdit: boolean = false;
  loading$ = {
    init: new BehaviorSubject(true),
    save: new BehaviorSubject(false),
    upload: new BehaviorSubject(false)
  }
  currencies: string[] = CURRENCIES
  customerId = null;
  activeStep: number = 0;
  steps = [
    'createClient.generalInformation',
    'createClient.invoice'
  ];

  constructor(
    public enumService: EnumService,
    private customerApi: CustomerApi,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private clientApi: ClientApi,
    private systemMessageService: SystemMessageService,
    private translateConfigService: TranslateConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    super();
    this.language = this.translateService.currentLang
    if (this.language == 'se') {
      this.predefinedCountries = COUNTRIES_SE
    } else {
      this.predefinedCountries = COUNTRIES_EN
    }
    this.adTypes = this.enumService.advertisingType;
    this.countryTranslation = this.translateService.instant('client.form.country');
    this.translateService.onLangChange.subscribe(() => {
      this.language = this.translateService.currentLang
      const countryValue = this.form.get('country').value
      if (this.language == 'se') {
        this.predefinedCountries = COUNTRIES_SE
        // if the translation changes. Change the value in the input field
        this.form.get('country').setValue({ alpha2Code: `${countryValue.alpha2Code}` })
        this.reload()
      } else {
        this.predefinedCountries = COUNTRIES_EN
        this.form.get('country').setValue({ alpha2Code: `${countryValue.alpha2Code}` })
        this.reload()
      }
      this.countryTranslation = this.translateService.instant('client.form.country');
    })
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const elementExists = document.getElementsByClassName('country-search').length
    if (elementExists) {
      const element = this.renderer?.selectRootElement('.country-search');
      const x = element as HTMLElement;
      setTimeout(() => x.focus(), 200);
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.clientId = +this.activatedRoute.snapshot.params['id'] || null;
    this.isEdit = Boolean(this.clientId);
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId && !user.customerId;
    this.customerId = user?.currentCustomerId || user?.customerId;
    forkJoin([
      this.isAdmin ? this.customerApi.getCustomers() : this.customerApi.getCustomerById(user.currentCustomerId || user.customerId),
      this.isEdit ? this.clientApi.getClientById(this.clientId) : of(null)
    ]).pipe(
      takeUntil(this.ngUnsubscribe),
      indicateLoading(this.loading$.init))
      .subscribe(([customers, client]) => {
        this.customers = (this.isAdmin ? customers : [customers]) as Customer[];
        this.initForm(client);
        if (!this.isAdmin)
          this.form.get('customerId').setValue(this.customers[0].id);
      });
  }

  changeCustomer(id: number, $event: MatOptionSelectionChange<number>) {
  }

  // the translations in the country dropdown will not change without this, if the translation is changed after init
  private reload() {
    setTimeout(() => this._reload = false);
    setTimeout(() => this._reload = true);
  }

  private initForm(client?: Client) {
    this.form = this.formBuilder.group({
      customerId: [this.customerId || client?.customer?.id || '', Validators.required],
      name: [client?.name || '', Validators.required],
      vatNumber: [client?.vatNumber || ''],
      frameworkAgreementEnabled: [client?.frameworkAgreementEnabled || false],
      advertisingType: [client?.advertisingType || null],
      additionalInformation: [client?.additionalInformation || ''],
      address: [client?.address || ''],
      postalCode: [client?.postalCode || ''],
      city: [client?.city || ''],
      country: [{ alpha2Code: client?.country || 'SE' }, Validators.required],
      emailAddress: [client?.emailAddress || '', [CustomEmailValidator(), Validators.required]],
      contactPerson: [client?.contactPerson || ''],
      phoneNumber: [client?.phoneNumber || ''],
      department: [client?.department || ''],
      costCenter: [client?.costCenter || ''],
      currency: [client?.currency || 'SEK', Validators.required],
      paymentTerms: [client?.paymentTerms || ''],
      shouldCreateFolder: [client?.shouldCreateFolder || false]
    });
    if (client?.customer?.id)
      this.form.get('customerId').disable();
  }
  saveClient() {
    const callFunction = this.isEdit ? this.clientApi.editClient(this.form.getRawValue(), this.clientId) : this.clientApi.createClient(this.form.getRawValue());
    callFunction
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.init))
      .subscribe((client: Client) => {
        if (client) {
          this.systemMessageService.success(this.translateConfigService.instant(this.isEdit ? "notifications.success.clientEdited" : "notifications.success.clientCreate", this.form.getRawValue().name));
          this.router.navigate(['/clients']);
        }
      });
  }

  setActiveStep(index: number, event: Event) {
    let element = event.currentTarget as HTMLElement;
    if (!element.classList.contains('disabled')) {
      this.activeStep = index;
    }
    this.activeStep = index;
  }

  onContinueClick() {
    this.activeStep = 1;
  }

  onCancel() {
    this.router.navigate(['/clients']);
  }
}

export interface Currency {
  name: string;
}


