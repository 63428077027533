import { HttpClient, HttpEvent, HttpEventType, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GetMediasAndFoldersResponse } from "@api/models/get-medias-and-folders-response";
import { map, Observable } from "rxjs";
import { ApiConfiguration } from "../api-configuration";
import { BaseService } from "../base-service";
import { SelectedFile, MediaFormat, MediaFile } from "@models/media-files";
import { MediaDetails } from "@app/admin/media/media.model";
import { PaginationResult } from "@api/models/paginator";

@Injectable({
  providedIn: 'root',
})
export class MediaApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  static readonly GetMediaPath = '/medias';

  getMediasAndFolders(params): Observable<any> {
    return this.http.get<GetMediasAndFoldersResponse>(`${this.rootUrl}${MediaApi.GetMediaPath}/mediasAndFolders`, { params });
  }
  getMediaById(id) {
    return this.http.get(`${this.rootUrl}${MediaApi.GetMediaPath}/${id}`);
  }
  editMedia(media): Observable<any> {
    return this.http.put(`${this.rootUrl}${MediaApi.GetMediaPath}`, media);
  }

  validateUniqueName(params): Observable<any> {
    return this.http.get(`${this.rootUrl}${MediaApi.GetMediaPath}/validateuniquename`, { params });
  }

  removeMedias(params): Observable<any> {
    return this.http.delete(`${this.rootUrl}${MediaApi.GetMediaPath}`, { params });
  }
  mediaUploadImage(body) {
    const formData = new FormData();
    formData.append('file', body.file);

    return this.http.post(`${this.rootUrl}${MediaApi.GetMediaPath}/images/upload/?${body.paramsString}`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
  mediaUploadVideo(body) {
    const formData = new FormData();
    formData.append('file', body.file);

    return this.http.post(`${this.rootUrl}${MediaApi.GetMediaPath}/videos/upload/?${body.paramsString}`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  getPendingMedias(params) {
    return this.http.get<any>(`${this.rootUrl}${MediaApi.GetMediaPath}/pending`, { params });
  }

  getMediaTransferAvailableFileCount(params) {
    return this.http.get<any>(`${this.rootUrl}${MediaApi.GetMediaPath}/validate-transfer-limit`, { params });
  }

  approveMediaTransfer(id: number, customerId: number) {
    return this.http.put(`${this.rootUrl}${MediaApi.GetMediaPath}/${id}/approve`, id, {
      params:{
        customerId: customerId
      }
    });
  }

  rejectMediaTransfer(id) {
    return this.http.put(`${this.rootUrl}${MediaApi.GetMediaPath}/${id}/reject`, id);
  }

  crateMediaTransferLink(body) {
    return this.http.post(`${this.rootUrl}${MediaApi.GetMediaPath}/linktransfertoken`, body);
  }

  uploadMediaForExternalUsers(body: { token: string, files: SelectedFile[] }) {
    let params = new HttpParams();
    const formData = new FormData();
    params = params.append('token', body.token);

    body.files.forEach((file: any) => {
      formData.append('duration', file.format === MediaFormat.Video ? file.selectedFile.videoDuration : 0);
      formData.append('media', file.selectedFile);
    });

    return this.http.post(`${this.rootUrl}${MediaApi.GetMediaPath}/transfer`, formData, {
      params,
      reportProgress: true,
      observe: 'events',
    })
    .pipe(
      map((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            // This is done to simulate uploading 100% of the loader when api call is finished!
            return Math.round((100 * event.loaded) / event.total) >= 90 && 90;

          case HttpEventType.Response:
            return 100;

          default:
            return 0;
        }
      }),
    );
  }

  getMediaExtendedDetailsById(mediaId: string): Observable<MediaDetails> {
    return this.http.get<MediaDetails>(`${this.rootUrl}${MediaApi.GetMediaPath}/extended-details/${mediaId}`);
  }
  
  getUnusedMediaList(params): Observable<PaginationResult<MediaFile>> {
    return this.http.get<PaginationResult<MediaFile>>(`${this.rootUrl}${MediaApi.GetMediaPath}/unusedMediaList`, { params });
  }
}
