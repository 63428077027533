import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, UserRoleEnum } from '../../../core/auth/auth.service';
import { SystemMessageService } from 'src/app/core/services/system-message.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject, map, takeUntil } from 'rxjs';
import { TranslateConfigService } from '@core/translate-config.service';
import { unsubscribeMixin } from '@core/unsubscribe';
import { indicateLoading } from '@core/indicate-loading';
import { ScreenGroupApi } from '@api/services/screen-group-api';
import { IdName } from '@api/models/idname';
import { Customer, CustomerApi, StatisticsApi } from '@api/index';
import { ScreenGroup } from '@api/models/screen-group';
import { EnumService } from '@app/core/services/enum.service';

@Component({
  selector: 'flow-create-screen-group-modal',
  templateUrl: './create-screen-group-modal.component.html',
  styleUrls: ['./create-screen-group-modal.component.scss'],
})
export class CreateScreenGroupModalComponent
  extends unsubscribeMixin()
  implements OnInit
{
  orientations: any;
  screensWithOrientation: IdName[] = [];
  form: FormGroup;
  selectedScreens: number[];
  detailsForm: FormGroup;
  isAdmin: boolean;
  customerId: number;
  customers: Customer[];
  editMode: boolean;
  screenGroupId: number;
  screenGroup: ScreenGroup;
  selectedIndex: number = 0;
  generalInfoValidationErrors: string[] = [];
  detailsValidationErrors: string[] = [];
  steps = [
    {title: 'screens.screenGroupBtn', subtitle: 'screens.screenGroup.generalInformation'},
    {title: 'screens.screenGroupBtn', subtitle: 'screens.screenGroup.details'}
  ];
  loading$ = {
    init: new BehaviorSubject(true),
    save: new BehaviorSubject(false),
  };

  protected readonly indicateLoading = indicateLoading;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private customerApi: CustomerApi,
    private statisticsApi: StatisticsApi,
    private screenGroupApi: ScreenGroupApi,
    private enumService: EnumService,
    public systemMessageService: SystemMessageService,
    private translateService: TranslateService,
    private translateConfigService: TranslateConfigService
  ) {
    super();
    this.initForm();
    this.screenGroupId = +this.activatedRoute.snapshot.params['id'] || null;
  }

  ngOnInit(): void {
    this.editMode = !!this.screenGroupId;

    if (this.editMode) {
      this.screenGroupApi
        .getScreenGroupDetails(this.screenGroupId)
        .pipe(
          switchMap((data: ScreenGroup) => {
            this.screenGroup = data;
            let params: any = {};
            if (this.screenGroup?.customerId)
              params.customerId = this.screenGroup.customerId;

            if (this.screenGroup?.orientation)
              params.screenOrientationEnum = this.screenGroup.orientation;

            return this.statisticsApi.getScreensWithOrientation(params).pipe(
              map((screensData: any) => {
                const screens =
                  data?.screens.map((s) => ({
                    id: s.id,
                    name: s.humanUniqueIdentifier,
                  })) || [];
                this.selectedScreens = screens.map((x) => x.id);
                this.screensWithOrientation = [...screensData, ...screens];
                this.initForm(this.screenGroup);
              })
            );
          })
        )
        .pipe(
          takeUntil(this.ngUnsubscribe),
          indicateLoading(this.loading$.init)
        )
        .subscribe();
    } else {
      this.initForm();
    }

    this.orientations = this.enumService.screenOrientations;
    const user = this.authService.userData;
    this.isAdmin =
      user.roleId === UserRoleEnum.Admin && !user.currentCustomerId;
    if (!this.isAdmin) {
      const userCustomerId = user?.currentCustomerId || user?.customerId;
      this.form.get('customerId').setValue(userCustomerId);
      this.customerId = userCustomerId;
    } else {
      this.customerApi
        .getCustomers()
        .pipe(
          takeUntil(this.ngUnsubscribe),
          indicateLoading(this.loading$.init)
        )
        .subscribe((data) => (this.customers = data));
    }
  }

  private initForm(screenGroup?: ScreenGroup) {
    this.form = this.formBuilder.group({
      id: screenGroup?.id || null,
      humanUniqueIdentifier: [
        screenGroup?.humanUniqueIdentifier || '',
        Validators.required,
      ],
      customerId: screenGroup?.customerId || null,
      information: screenGroup?.information || '',
    });

    this.detailsForm = this.formBuilder.group({
      orientation: [screenGroup?.orientation, Validators.required],
      screenIds: [
        this.screensWithOrientation?.map((x) => x.id),
        Validators.required,
      ],
    });
    if (this.editMode) {
      this.detailsForm.get('orientation').disable();
      this.form.get('customerId').disable();
      this.detailsForm.get('screenIds').setValue(this.selectedScreens);
    }
  }

  getScreensWithOrientation(event, orientationId) {
    const customerId = this.customerId || this.form.get('customerId').value;
    let params: any = {};
    if (customerId) params.customerId = customerId;
    if (orientationId) params.screenOrientationEnum = orientationId;
    if (event.isUserInput && customerId) {
      this.statisticsApi
        .getScreensWithOrientation(params)
        .subscribe((data: any) => (this.screensWithOrientation = data));
    }
  }

  selectDeselectAllScreens(selectAll: boolean): void {
    if (this.screenGroup && !this.screenGroup.canBeDeleted) {
      return;
    }

    if (selectAll)
      this.detailsForm
        .get('screenIds')
        .setValue(this.screensWithOrientation.map((l) => l.id));
    else this.detailsForm.get('screenIds').setValue([]);
  }

  setValidationMessages(formGroup: FormGroup) {
    const validationErrors = [];
    Object.keys(formGroup.controls).forEach((controlKey: any) => {
      const control = formGroup.get(controlKey);

      if (control instanceof FormGroup) {
        this.setValidationMessages(control);
      } else {
        if (control && control.errors) {
          for (const [key, value] of Object.entries(control.errors)) {
            if (key === 'required') {
              const isRequired =
                this.translateConfigService.instant(
                  `createScreen.validationErrors.${controlKey}`
                ) +
                ' ' +
                this.translateConfigService.instant(
                  `createScreen.validationErrors.requred`
                );
              validationErrors.push(isRequired);
            }
          }
        }
      }
    });

    return validationErrors;
  }

  onSaveClick() {
    if (!this.form.valid || !this.detailsForm.valid) {
      this.detailsValidationErrors = this.setValidationMessages(
        this.detailsForm
      );
      this.generalInfoValidationErrors = this.setValidationMessages(this.form);
      return;
    }

    const body = {
      ...this.form.getRawValue(),
      ...this.detailsForm.getRawValue(),
    };

    const screenGroup = { ...body, name: body.humanUniqueIdentifier };

    if (this.editMode) {
      this.screenGroupApi
        .updateScreenGroup(screenGroup)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          indicateLoading(this.loading$.save)
        )
        .subscribe((data) => {
          this.systemMessageService.success(` ${this.translateService.instant(
            'notifications.success.screenGroup'
          )} ${body.humanUniqueIdentifier}
      ${this.translateService.instant(
        'notifications.success.screenGroupEdited'
      )}`);
          this.router.navigate(['/screens']);
        });
    } else {
      this.screenGroupApi
        .saveScreenGroup(screenGroup)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          indicateLoading(this.loading$.save)
        )
        .subscribe((data) => {
          this.systemMessageService.success(`${this.translateService.instant(
            'notifications.success.screenGroup'
          )} ${body.humanUniqueIdentifier}
          ${this.translateService.instant(
            'notifications.success.screenGroupCreated'
          )}`);
          this.router.navigate(['/screens']);
        });
    }
  }

  selectedTabChange(index) {
    this.selectedIndex = index;
  }

  updateScreens(event) {
    if (event.isUserInput) {
      this.detailsForm.get('orientation').setValue([]);
      this.detailsForm.get('screenIds').setValue([]);
    }
  }


  onNextTab(number: number) {
    this.selectedIndex = number;
    if (this.selectedIndex == 1 && number == 1) {

      this.generalInfoValidationErrors = this.setValidationMessages(this.form);
      this.selectedIndex = number;
    } else if (this.selectedIndex == 0 && number == 0) {
      this.detailsValidationErrors = this.setValidationMessages(this.detailsForm);
      this.selectedIndex = number;
    } else this.selectedIndex = number;
  }
}
