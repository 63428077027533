<div class="flex items-center justify-between px-8 py-2 w-full bg-white border border-gray-100 fixed">
  <img src="../../../../assets/svg/logo.svg"
       class="w-[72px] cursor-pointer"
       (click)="redirectToHomePage()"
       alt="Logo">
  <div class="flex gap-4">
    <!-- We will leave this commented out for now until we implement logic for it! -->
    <!-- <button class="btn-round-primary">
      <app-icon
        fill="rgba(0,0,0,0)"
        iconName="question"
        size="md">
    </app-icon></button> -->
    <button class="btn-round font-normal" (click)="showSettings($event)">{{initials}}</button>
  </div>
</div>

<div flowClickOutside class="absolute z-20 top-[60px] right-4" *ngIf="showNavSettings" (clickOutside)="showNavSettings=false">
  <flow-nav-settings 
    [adminUser]="adminUser" 
    [currentUser]="currentUser" 
    [initials]="initials" 
    [customers]="customers"
    (closeNavSettings)="closeSettings($event)">
  </flow-nav-settings>
</div>
