<div class="w-full space-y-3">
    <div class="flex flex-col">
        <mat-label>{{ 'cleanUpMedia.lastUsedSelectLabel' | translate }}</mat-label>
        <mat-form-field>
          <mat-select [(ngModel)]="selectedLastUsedValue" (selectionChange)="onValueChange()" placeholder="{{'global.select' | translate}}">
            <mat-option *ngFor="let value of lastUsedMonthValues"
                        [value]="value.id">
                {{ value.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    <mat-checkbox color="primary"
                  id="include-nested-media"
                  [(ngModel)]="includeNestedMedia"
                  (change)="onValueChange()">
        <span>{{ 'cleanUpMedia.nestedFilesFilter' | translate }}</span>
    </mat-checkbox>
    <mat-checkbox color="primary"
                  id="include-never-used"
                  [(ngModel)]="includeNeverUsed"
                  (change)="onValueChange()">
        <span>{{ 'cleanUpMedia.neverUsedFilesFilter' | translate }}</span>
    </mat-checkbox>   
</div>