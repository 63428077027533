import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';

import { DateTime } from 'luxon';
import { TranslateConfigService } from 'src/app/core/translate-config.service';
import { StatisticsExposures } from 'src/app/models/statistics-exposures';
import { StatisticsFilterExposures } from 'src/app/models/statistics-filter-exposures';
import { FilterInputs, StatisticsFilterInputs } from 'src/app/models/statistics-filter-inputs';
import { StatisticsFilterValues } from 'src/app/models/statistics-filter-values';
import { EngagementTypeTranslations } from '../../engagement/engagement-type-translations';
import { StatisticsFilterComponent } from '../statistics-filter.component';
import { StatisticsService } from '../statistics.service';

import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ActiveReportEnum } from '@api/models/active-report-enum';
import { forkJoin, takeUntil, Subject } from 'rxjs';

import { HourlySeperatedAttentionTime } from 'src/app/models/hourly-separated-attention-time';
import { PermissionsService } from '@app/core/services/permissions.service';
import { MediaSubtitleData, SummaryCards, SummaryPeriods } from './views.model';
import { ChannelsApi } from '@api/services/channels-api';
import { Customer, CustomerApi, EngagementMediaFile, EngagementsAdminApi, EngagementTypeEnum, StatisticsApi, StatisticsEngagementMediaFiles } from '@api/index';
import { Client } from '@api/models/client';
import { TotalNumberOfExposures } from '@api/models/exposures/TotalNumberOfExposures.model';
import { HourlySeparatedTarget } from '@api/models/people-counter-statistics-response';
import { AuthService, UserRoleEnum } from '@app/core/auth/auth.service';
import { ClientApi } from '@api/services/client-api';
import { EngagementRequest } from '@api/models/engagement-request';
import { IdName } from '@api/models/idname';
import { EnumService } from '@app/core/services/enum.service';
import { BookingPageExposureDetails } from '@app/admin/engagement/bookings/model/bookings.model';

@Component({
  selector: 'flow-views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss']
})
export class ViewsComponent implements OnInit, OnDestroy {
  @ViewChildren('filtersComponent') statisticsFilterComponents: QueryList<StatisticsFilterComponent>;

  summaryPeriods: SummaryPeriods[] = [];
  chartPeriods: { startDT: DateTime, endDT: DateTime }[] = [];
  filter: StatisticsFilterValues;
  filterExposures: StatisticsFilterExposures;
  filterInputs: FilterInputs = { visibleInputs: new Map<string, StatisticsFilterInputs>(), hiddenInputs: new Map<string, StatisticsFilterInputs>() };
  filterValues: FilterInputs = { visibleInputs: new Map<string, StatisticsFilterInputs>(), hiddenInputs: new Map<string, StatisticsFilterInputs>() };
  selected?: EngagementMediaFile;
  customers: Customer[] = [];
  clients: Client[] = [];
  isFilterDataReady: boolean = false;
  SummaryCards = SummaryCards;
  isMediaReady: boolean = false;
  mediaSubtitleData: MediaSubtitleData;
  mediaFiles: StatisticsEngagementMediaFiles[] = [];
  engagements: any;
  EngagementTypeTranslations = EngagementTypeTranslations;
  filterDataEngament;
  panelOpenState = false;
  prepopulatedFilters = false;
  isBookingStillRunning = false;
  isAdmin: boolean;
  customerId: number;
  totalNumberOfExposures: TotalNumberOfExposures[];
  distributonOfDailyHoursReport: Array<HourlySeparatedTarget>;
  hourlySeperatedAttentionTime: Array<HourlySeperatedAttentionTime>;
  exposedWeeks: any[];
  engagementTypes = [];
  selectedIndex: number = 0;
  consentKey = 'exposures-consent';
  consentGiven = false;
  channels: any;
  screens: any;
  currentPage: number = 0;
  finalEngagements: any = { items: [] };
  ActiveReportEnum = ActiveReportEnum;
  responseData: any;
  disableExposuresAnalytics: boolean = false;
  disableExposuresReportsAnalytics: boolean = false;
  engagementDays: string[] = [];
  destroy$ = new Subject<void>();

  constructor(
    private customerApi: CustomerApi,
    private channelsApi: ChannelsApi,
    private engagementsAdminApi: EngagementsAdminApi,
    private statisticsApi: StatisticsApi,
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private translateConfigService: TranslateConfigService,
    public statisticsService: StatisticsService,
    private formBuilder: FormBuilder,
    private clientApi: ClientApi,
    private enumService: EnumService,
    private permissionsService: PermissionsService
  ) {
    this.statisticsService.form = this.formBuilder.group({});
    this.engagementTypes = this.enumService.engagementTypes;
    this.mediaSubtitleData = {
      mediaName: '',
      engagementName: '',
      engagementType: 1,
      start: '',
      end: ''
    }
  }

  ngOnInit(): void {
    if (!this.permissionsService.hasPermissions(['ViewExposuresAnalytics'])) {
      this.disableExposuresAnalytics = true;
    }
    if (!this.permissionsService.hasPermissions(['ViewExposuresReportsAnalytics'])) {
      this.disableExposuresReportsAnalytics = true;
    }
    if (this.disableExposuresAnalytics && !this.disableExposuresReportsAnalytics) this.selectedIndex = 1;
    this.checkConsent();
    this.initStatisticsForm();
    this.getActivatedRoute();
  }

  getActivatedRoute(): void {
    this.activatedRoute
    .data.pipe(takeUntil(this.destroy$))
    .subscribe((data) => {
      const preselectedEngagements: BookingPageExposureDetails = data?.engagement;
      const isReport = history.state.reports || false; 
      if (isReport) {
        this.selectedIndex = 1;
      } else {
        this.getData(preselectedEngagements);
      }
    });
  }

  getData(preselectedEngagements?: BookingPageExposureDetails) {
    this.customerId = this.authService?.userData?.currentCustomerId || this.authService?.userData?.customerId;
    const userRoleId = this.authService.userData.roleId;
    this.isAdmin = (userRoleId === UserRoleEnum.Admin) && !this.authService.userData.currentCustomerId;
    forkJoin([
      this.isAdmin ? this.engagementsAdminApi.getScreens() : this.engagementsAdminApi.getScreens({ channelIds: this.channels, customerIds: [this.customerId] }),
      this.isAdmin ? this.customerApi.getCustomers()
        : this.customerApi.getCustomerById(this.customerId),
      this.isAdmin ? this.channelsApi.getChannels() : this.channelsApi.getChannels({ customerIds: [this.customerId] }),
      this.clientApi.getClients(this.isAdmin ? undefined : [this.customerId])
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([screens, customers, channels, clients]) => {
        this.customers = (Array.isArray(customers) ? customers : [customers]) as Customer[];
        this.channels = channels;
        this.screens = screens;
        this.clients = clients;
        
        if (this.customers.length > 0) {
          this.engagementsAdminApi.getStatisticsEngagements({ body: { 
            customerIds: preselectedEngagements ? [preselectedEngagements?.customer?.id] : this.customers.map(c => c.id), 
            types: ['Playlist', 'Segment'], pageNumber: 0 } })
            .pipe(takeUntil(this.destroy$))
            .subscribe(engagements => {
              this.engagements = engagements;
              if (preselectedEngagements) {
                this.preselectedEngagements(preselectedEngagements);
              }
              
              this.fillInputData('customerIds', this.customers, 'hiddenInputs');
              this.fillInputData('channelIds', this.channels, 'hiddenInputs');
              this.fillInputData('screenIds', this.screens, 'hiddenInputs');
              this.fillInputData('clientIds', this.clients, 'visibleInputs');
              this.fillInputData('bookingNumber', this.engagements, 'visibleInputs');
              this.fillInputData('types', this.engagementTypes, 'hiddenInputs');
              this.isFilterDataReady = true;
            })
        }
      });
  }

  preselectedEngagements(preselectedEngagements: BookingPageExposureDetails): void {
    this.prepopulatedFilters = true;

    const uniqueScreens = preselectedEngagements?.screens;
    const uniqueChannels = preselectedEngagements?.channels;
    const types = [{ id: preselectedEngagements.engagementType, name: EngagementTypeEnum[preselectedEngagements.engagementType] }];

    this.filterInputs.hiddenInputs.get('customerIds').value = [preselectedEngagements.customer];
    this.filterInputs.hiddenInputs.get('channelIds').value = uniqueChannels;
    this.filterInputs.hiddenInputs.get('screenIds').value = uniqueScreens;
    this.filterInputs.visibleInputs.get('bookingNumber').value = preselectedEngagements?.booking?.id;
    this.filterInputs.hiddenInputs.get('types').value = types;
    this.getMediaFiles([preselectedEngagements?.booking?.id], []);
    this.panelOpenState = true;              
  }

  ngOnDestroy(): void {
    this.statisticsService.form = this.formBuilder.group({});
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkIfBookingIsStillRunning(endDateToCompare: string) {
    let endDate = new Date(endDateToCompare);
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    this.isBookingStillRunning = endDate > currentDate
  }

  getCustomers(values) {
    if (this.isAdmin) {
      this.customerApi.getCustomers()
        .pipe(takeUntil(this.destroy$)).subscribe((customers) => {
          this.fillInputData('customerIds', customers, 'hiddenInputs');
          this.filterExposures.customerIds = customers ? customers.map(c => c.id) : [];
          values.form.get(values.type).setValue(this.filterExposures.customerIds);
      })
    } else {
      this.customerApi.getCustomerById(this.customerId)
        .pipe(takeUntil(this.destroy$)).subscribe((customer) => {
          this.fillInputData('customerIds', [customer], 'hiddenInputs');
          this.filterExposures.customerIds = [customer.id];
          values.form.get('customerIds').setValue([customer.id]);
      });
    }
  }
  getChannels(customerIds: number[], values): void {
    if (customerIds && customerIds.length > 0) {
      this.channelsApi.getChannels({ customerIds })
        .pipe(takeUntil(this.destroy$)).subscribe((channels) => {
          this.fillInputData('channelIds', channels, 'hiddenInputs');
          this.filterExposures.channelIds = channels ? channels.map(c => c.id) : [];
          if (values) values.form.get('channelIds').setValue(this.filterExposures.channelIds);
          this.getScreens(this.filterExposures.channelIds, values);
      });
    } else {
      this.fillInputData('channelIds', [], 'hiddenInputs');
      this.fillInputData('screenIds', [], 'hiddenInputs');
    }
  }
  getScreens(channelIds: number[], values, customerIds?: number[]) {
    if (channelIds && channelIds.length > 0) {
      this.engagementsAdminApi.getScreens({ channelIds, customerIds: customerIds || null, isSensor: false })
      .pipe(takeUntil(this.destroy$)).subscribe((screens) => {
        this.screens = screens;
        this.fillInputData('screenIds', screens, 'hiddenInputs');
        this.filterExposures.screenIds = screens ? screens.map(s => s.id) : [];
        if (values) values.form.get('screenIds').setValue(this.filterExposures.screenIds);
        this.getEngagements();
      });
    } else {
      this.fillInputData('screenIds', [], 'hiddenInputs');
    }

  }
  getClients(customerIds: number[]): void {
    if (this.statisticsService.form.get('clientIds'))
      this.statisticsService.form.get('clientIds').setValue(null);
    this.fillInputData('clientIds', [], 'visibleInputs');
    if (customerIds.length === 0) return;


    this.clientApi.getClients(customerIds).pipe(takeUntil(this.destroy$)).subscribe((clients) => {
      if (clients.length) {
        this.fillInputData('clientIds', clients, 'visibleInputs');
        this.filterExposures.clientIds = clients ? clients.map(c => c.id) : [];
        this.getEngagements(); 
      }      
    });
  }
  getEngagements() {
    if (!this.filterExposures || this.filterExposures?.customerIds.length === 0 || this.filterExposures?.channelIds.length === 0
      || this.filterExposures?.screenIds.length === 0 || this.filterExposures?.types.length === 0) {
      this.engagements = [];
      this.fillInputData('bookingNumber', [], 'visibleInputs');
      this.getMediaFiles([], []);
      return;
    }
    let body: EngagementRequest = {
      customerIds: this.filterExposures?.customerIds || [],
      startDate: null,
      endDate: null,
      channelIds: this.filterExposures?.channelIds || [],
      screenIds: this.filterExposures?.screenIds || [],
      clientIds: this.filterExposures?.clientIds || [],
      name: "",
      types: this.filterExposures?.types.map(t => EngagementTypeEnum[t]) || [],
      placements: []
    };

      this.engagementsAdminApi.getStatisticsEngagements({ body }).pipe(takeUntil(this.destroy$))
      .subscribe((engagements) => {
        this.engagements = engagements.items ? engagements.items : engagements;
        this.fillInputData('bookingNumber', this.engagements, 'visibleInputs');
        this.getMediaFiles(this.engagements.map(m => m.id), this.filterExposures?.clientIds as number[] || []);
      })
  }
  getMediaFiles(engagementIds: number[], clientIds: number[]) {
    if (engagementIds.length === 0) {
      this.mediaFiles = [];
      this.fillInputData('mediaFile', [], 'visibleInputs');
      return;
    }

    let params: any = {
      engagementIds
    }

    if (clientIds.length > 0) {
      params.clientIds = clientIds
    }

    this.statisticsApi.getEngagementMediaFiles(params).pipe(takeUntil(this.destroy$))
      .subscribe((medias) => {
        this.mediaFiles = medias;
        this.fillInputData('mediaFile', medias, 'visibleInputs');
        if (this.prepopulatedFilters) {
          this.isFilterDataReady = true;
          if (medias?.length) {
            this.statisticsFilterComponents.forEach(component => component.updateMediaFileField(medias[0]?.id));
            this.submitForm();
          }
          else {
            this.statisticsFilterComponents.forEach(component => component.triggerMediaAnimation());
          }
          this.prepopulatedFilters = false;
        }
      })
  }

  private setSummaryPeriods(data: StatisticsExposures): void {
    this.summaryPeriods[SummaryCards.Total] = {
      title: 'exposures.totalKPI',
      type: 0,
      data: data.totalExposures || 0,
      hasTooltip: false
    };
    this.summaryPeriods[SummaryCards.Primary] = {
      title: 'exposures.primaryExposures',
      type: EngagementTypeEnum.Segment,
      data: data.primaryExposures || 0,
      hasTooltip: false,
      display: EngagementTypeEnum.Segment === this.mediaSubtitleData.engagementType
    };
    this.summaryPeriods[SummaryCards.Secondary] = {
      title: 'exposures.secondaryExposures',
      type: EngagementTypeEnum.Segment,
      data: data.secondaryExposures || 0,
      hasTooltip: false,
      display: EngagementTypeEnum.Segment === this.mediaSubtitleData.engagementType
    };
    this.summaryPeriods[SummaryCards.ShareOfVoice] = {
      title: 'exposures.shareOfVoice',
      type: EngagementTypeEnum.Playlist,
      data: data.shareOfVoice || 0,
      hasTooltip: true,
      display: EngagementTypeEnum.Playlist === this.mediaSubtitleData.engagementType
    };
    this.summaryPeriods[SummaryCards.AirTime] = {
      title: 'exposures.airTime',
      type: 0,
      data: data.airTime || '',
      hasTooltip: false
    };
    this.summaryPeriods[SummaryCards.AttentionTime] = {
      title: 'exposures.attentionTime',
      type: 0,
      data: data.attentionTime || '',
      hasTooltip: false
    };
    this.summaryPeriods[SummaryCards.Impressions] = {
      title: 'exposures.impressions',
      type: 0,
      data: data.impressions || 0,
      hasTooltip: false
    };
    this.summaryPeriods = this.summaryPeriods.filter(summary => summary.display !== false);
  }
  // Statistics form START
  initStatisticsForm(resetForm?: boolean) {
    if (resetForm) {
      this.statisticsService.form = this.formBuilder.group({});
      this.filterInputs = { visibleInputs: new Map<string, StatisticsFilterInputs>(), hiddenInputs: new Map<string, StatisticsFilterInputs>() };
      this.filterValues = { visibleInputs: new Map<string, StatisticsFilterInputs>(), hiddenInputs: new Map<string, StatisticsFilterInputs>() };
    }
    if (this.selectedIndex === 0) {
      this.filterInputs.visibleInputs.set('clientIds', {
        label: 'exposures.form.client',
        formControlName: 'clientIds',
        data: [],
        type: 'dropdown',
        isMultipleDropdown: true,
        isRequired: false,
        isChecked: null
      });
      this.filterInputs.visibleInputs.set('bookingNumber', {
        label: 'reports.form.bookingNumberName',
        formControlName: 'bookingNumber',
        data: [],
        search: true,
        extractionWords: [EngagementTypeEnum[EngagementTypeEnum.Playlist], EngagementTypeEnum[EngagementTypeEnum.Segment]],
        type: 'dropdown',
        isRequired: true,
        isChecked: null
      });
      this.filterInputs.visibleInputs.set('mediaFile', {
        label: 'reports.form.mediaFile',
        data: [],
        type: 'dropdown',
        formControlName: 'mediaFile',
        search: true,
        isRequired: true,
        isChecked: null
      });
      this.filterInputs.hiddenInputs.set('customerIds', {
        label: 'reports.form.selectCustomers',
        data: [],
        type: 'dropdown',
        formControlName: 'customerIds',
        isMultipleDropdown: true,
        isRequired: true,
        isChecked: null,
        hasSelectAll: true,
        isFilled: true
      });
      this.filterInputs.hiddenInputs.set('channelIds', {
        label: 'reports.form.selectChannels',
        data: [],
        type: 'dropdown',
        formControlName: 'channelIds',
        isRequired: true,
        isChecked: null,
        isMultipleDropdown: true,
        hasSelectAll: true,
        isFilled: true
      });
      this.filterInputs.hiddenInputs.set('screenIds', {
        label: 'reports.form.selectScreens',
        data: [],
        type: 'dropdown',
        formControlName: 'screenIds',
        isMultipleDropdown: true,
        isRequired: true,
        isChecked: null,
        hasSelectAll: true,
        isFilled: true
      });
      this.filterInputs.hiddenInputs.set('types', {
        label: 'reports.form.bookingType',
        data: [],
        type: 'dropdown',
        formControlName: 'types',
        isMultipleDropdown: true,
        isRequired: true,
        isChecked: null,
        hasSelectAll: true,
        isTranslatable: true,
        isFilled: true
      });
    } else {
      this.filterInputs.visibleInputs.set('clientIds', {
        label: 'exposures.form.client',
        formControlName: 'clientIds',
        data: [],
        type: 'dropdown',
        isMultipleDropdown: true,
        isRequired: false,
        isChecked: null
      });
      this.filterInputs.visibleInputs.set('bookingNumbers', {
        label: 'reports.form.bookingNumberName',
        formControlName: 'bookingNumbers',
        data: [],
        // search: true,
        type: 'dropdown',
        isRequired: true,
        isChecked: null
      });
      this.filterInputs.visibleInputs.set('bookingTypes', {
        label: 'reports.form.bookingNumberName',
        formControlName: 'bookingTypes',
        data: [],
        extractionWords: [EngagementTypeEnum[EngagementTypeEnum.Playlist], EngagementTypeEnum[EngagementTypeEnum.Segment]],
        type: 'dropdown',
        isRequired: true,
        isChecked: null
      });
      this.filterInputs.visibleInputs.set('mediaFiles', {
        label: 'reports.form.mediaFile',
        data: [],
        type: 'dropdown',
        formControlName: 'mediaFiles',
        search: true,
        isRequired: true,
        isChecked: null
      });
      this.filterInputs.hiddenInputs.set('customerIds', {
        label: 'reports.form.selectCustomers',
        data: [],
        type: 'dropdown',
        formControlName: 'customerIds',
        isMultipleDropdown: true,
        isRequired: true,
        isChecked: null,
        hasSelectAll: true,
        isFilled: true
      });
      this.filterInputs.hiddenInputs.set('channelIds', {
        label: 'reports.form.selectChannels',
        data: [],
        type: 'dropdown',
        formControlName: 'channelIds',
        isRequired: true,
        isChecked: null,
        isMultipleDropdown: true,
        hasSelectAll: true,
        isFilled: true
      });
      this.filterInputs.hiddenInputs.set('screenIds', {
        label: 'reports.form.selectScreens',
        data: [],
        type: 'dropdown',
        formControlName: 'screenIds',
        isMultipleDropdown: true,
        isRequired: true,
        isChecked: null,
        hasSelectAll: true,
        isFilled: true
      });
    }

  }
  fillInputData(formControlName: string, data: any, inputType: string) {
    const response = data?.items ? data?.items : data;
    if (formControlName === 'bookingNumber') {
      this.responseData = response;
    }   
    const transformedArray: IdName[] = (response || []).map((item) => {
      const { id, name, humanUniqueIdentifier } = item;
      return {
        id: id,
        name: formControlName === 'bookingNumber'
          ? `<span class='engagement-style'>${this.translateConfigService.instant(EngagementTypeTranslations[item.engagementType])}</span> ${id}: ${name}`
          : humanUniqueIdentifier || name || ''
      };
    });
    let item = this.filterInputs[inputType].get(formControlName);    
    item.data = transformedArray;
    item.filteredData = transformedArray;
  }
  onFilterChange(values: { filter: number[] | string[] | number | string, type: string, form: FormGroup, allFilters: StatisticsFilterExposures }): void {
    this.filterExposures = values.allFilters;
    this.isMediaReady = false;
    if (values.type === 'clientIds') {
      this.filterExposures.clientIds = values.filter as number[];
      this.filterExposures.bookingNumber = null;
      this.filterExposures.mediaFile = null;
      this.getEngagements();
    } else if (values.type === 'bookingNumber') {
      this.filterExposures.bookingNumber = values.filter as number;
      this.filterExposures.mediaFile = null;
      this.getMediaFiles([values.filter as number], this.filterExposures.clientIds as number[] || []);
    } else if (values.type === 'types') {
      this.filterExposures.types = values.filter as string[];
      this.filterExposures.bookingNumber = null;
      this.filterExposures.mediaFile = null;
      this.getEngagements();
    } else if (values.type === 'screenIds') {
      this.filterExposures.screenIds = values.filter as number[];
      this.filterExposures.bookingNumber = null;
      this.filterExposures.mediaFile = null;
      this.fillInputData('types', this.engagementTypes, 'hiddenInputs');
      this.getEngagements();
    } else if (values.type === 'channelIds') {
      this.filterExposures.channelIds = values.filter as number[];
      this.filterExposures.bookingNumber = null;
      this.filterExposures.mediaFile = null;
      this.fillInputData('types', this.engagementTypes, 'hiddenInputs');
      this.getScreens(this.filterExposures.channelIds, this.filterExposures.customerIds.length > 1 ? null : this.filterExposures.customerIds);
    } else if (values.type === 'customerIds') {
      this.filterExposures.customerIds = values.filter as number[];
      this.filterExposures.channelIds = [];
      this.filterExposures.screenIds = [];
      this.filterExposures.bookingNumber = null;
      this.filterExposures.mediaFile = null;
      this.getChannels(values.filter as number[], values);
      this.getClients(values.filter as number[]);
    } else if (values.type === 'mediaFile') {
      this.filterExposures.mediaFile = values.filter as number;
      if ((values.filter as number[]).length !== 0) {
        this.mediaSubtitleData.mediaName = this.mediaFiles.filter(media => media.id === values.filter)[0].name;
        const engagementItems = this.finalEngagements.items.length ? this.finalEngagements.items : this.engagements.items ? this.engagements.items : this.engagements;
        this.mediaSubtitleData.engagementName = engagementItems.filter(engagement => engagement.id === this.filterExposures.bookingNumber)[0].name;
        this.mediaSubtitleData.engagementType = engagementItems.filter(engagement => engagement.id === this.filterExposures.bookingNumber)[0].engagementType;
      }
    }
  }

  submitForm() {
    if (this.filterExposures.mediaFile && !Array.isArray(this.filterExposures.mediaFile)) {
      if (!this.filterExposures.customerIds || !this.filterExposures.channelIds || !this.filterExposures.screenIds || !this.filterExposures.types) return;
      this.statisticsApi.getExposuresStatistics({ body: this.filterExposures }).pipe(takeUntil(this.destroy$))
        .subscribe((kpis: StatisticsExposures) => {
          this.totalNumberOfExposures = kpis.totalNumberOfExposures;
          this.engagementDays = kpis?.engagementDays;
          this.distributonOfDailyHoursReport = kpis.distributonOfDailyHoursReport;
          this.hourlySeperatedAttentionTime = kpis.hourlySeperatedAttentionTime;
          this.setExposureTitleDates(kpis.engagementDays);
          this.checkIfBookingIsStillRunning(this.mediaSubtitleData.end);
          this.setSummaryPeriods(kpis);
          this.isMediaReady = true;
        });
    }
  }

  setExposureTitleDates(engagementDays: string[]) {    
    const startDate = engagementDays[0];
    const endDate = engagementDays[engagementDays.length - 1] || startDate;
  
    this.mediaSubtitleData.start = DateTime.fromISO(startDate).toFormat('yyyy-MM-dd');
    this.mediaSubtitleData.end = DateTime.fromISO(endDate).toFormat('yyyy-MM-dd');
  }
  

  fetchNextBookings() {
    if (this.engagements.hasNext) {
      this.engagementsAdminApi.getStatisticsEngagements({ body: { customerIds: this.customers.map(c => c.id), types: ['Playlist', 'Segment'], pageNumber: this.engagements.currentPage + 1 } })
      .pipe(takeUntil(this.destroy$))  
        .subscribe(engagements => {
          if (this.finalEngagements.items.length) {
            this.finalEngagements = { ...this.finalEngagements, items: [...this.finalEngagements.items, ...engagements.items] }
          } else {
            this.finalEngagements = { ...this.engagements, items: [...this.engagements.items, ...engagements.items] }
          }

          this.engagements = engagements;
          this.fillInputData('customerIds', this.customers, 'hiddenInputs');
          this.fillInputData('channelIds', this.channels, 'hiddenInputs');
          this.fillInputData('screenIds', this.screens, 'hiddenInputs');
          this.fillInputData('clientIds', this.clients, 'visibleInputs');
          this.fillInputData('bookingNumber', this.finalEngagements, 'visibleInputs');
          this.fillInputData('types', this.engagementTypes, 'hiddenInputs');

          this.isFilterDataReady = true;
          this.currentPage = this.engagements.currentPage + 1;
        })
    }
  }

  searchBookings(value) {
    this.engagementsAdminApi.getStatisticsEngagements({ body: { customerIds: this.customers.map(c => c.id), types: ['Playlist', 'Segment'], pageNumber: 0, text: value } })
    .pipe(takeUntil(this.destroy$))  
      .subscribe(engagements => {
        this.finalEngagements = engagements;
        this.engagements = engagements;
        this.fillInputData('customerIds', this.customers, 'hiddenInputs');
        this.fillInputData('channelIds', this.channels, 'hiddenInputs');
        this.fillInputData('screenIds', this.screens, 'hiddenInputs');
        this.fillInputData('clientIds', this.clients, 'visibleInputs');
        this.fillInputData('bookingNumber', this.finalEngagements, 'visibleInputs');
        this.fillInputData('types', this.engagementTypes, 'hiddenInputs');

        this.isFilterDataReady = true;
      })
  }
  // Statistics form END

  onTabChange(event) {
    if (event.index === 0) {
      if (this.customers && this.customers.length > 0) {
        this.initStatisticsForm(true);
        this.fillInputData('customerIds', this.customers, 'hiddenInputs');
        this.fillInputData('channelIds', this.channels, 'hiddenInputs');
        this.fillInputData('screenIds', this.screens, 'hiddenInputs');
        this.fillInputData('clientIds', this.clients, 'visibleInputs');
        this.fillInputData('bookingNumber', this.engagements, 'visibleInputs');
        this.fillInputData('types', this.engagementTypes, 'hiddenInputs');
        this.isFilterDataReady = true;
      } else this.getData();
    } else {
      this.isFilterDataReady = false;
    }
  }

  checkConsent(): void {
    const consent = localStorage.getItem(this.consentKey);
    if (consent) {
      this.consentGiven = true;
    }
  }
}

