import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup } from "@angular/forms";
import { switchMap } from "rxjs/operators";
import { forkJoin, of, takeUntil } from "rxjs";
import { unsubscribeMixin } from "@core/unsubscribe";
import { TagsApi } from '@api/services/tags-api.service';
import { Category } from '@api/models/category';
import { Customer, StatisticsApi, Tag } from '@api/index';

@Component({
  selector: 'flow-poi-filters-modal',
  templateUrl: './poi-filters-modal.component.html',
  styleUrls: ['./poi-filters-modal.component.scss']
})
export class PoiFiltersModalComponent extends unsubscribeMixin() implements OnInit {
  categories: Category[];
  filterForm: FormGroup;
  customers: Customer[];
  tags: Tag[];
  isAdmin: boolean;
  selectedCustomer: number

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<PoiFiltersModalComponent>,
    private statisticsServiceApi: StatisticsApi,
    private tagsApi: TagsApi
  ) {
    super();
  }

  ngOnInit(): void {
    this.categories = this.data.categories
    this.filterForm = this.data.filterForm
    this.customers = this.data.customerIds
    this.tags = this.data.tags
    this.isAdmin = this.data.isAdmin
    this.selectedCustomer = this.filterForm.get('customerIds').getRawValue();

    if (this.selectedCustomer) {
      forkJoin([
        this.selectedCustomer ? this.statisticsServiceApi.getStatisticsCategories({ customerIds: [this.selectedCustomer] }) : of(null),
        this.selectedCustomer ? this.tagsApi.getTags({ customerIds: [this.selectedCustomer] }) : of(null)
      ]).pipe(takeUntil((this.ngUnsubscribe)))
        .subscribe(([categories, tags]) => {
          this.categories = categories
          this.tags = tags
        });
    }

    this.filterForm.get('customerIds').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      switchMap(customerId => forkJoin([
        customerId ? this.statisticsServiceApi.getStatisticsCategories({ customerIds: [customerId] }) : of(null),
        customerId ? this.tagsApi.getTags({ customerIds: [customerId] }) : of(null)
      ])),
    ).subscribe(([categories, tags]) => {
      this.categories = categories
      this.tags = tags
    });
  }

  clearDatePicker() {
    this.filterForm.get('created').get('start').setValue(null);
    this.filterForm.get('created').get('end').setValue(null)
  }

  clearForm() {
    this.filterForm.reset();
    this.dialogRef.close({ filter: true });
  }

  filter() {
    this.dialogRef.close({ filter: true })
  }
}
