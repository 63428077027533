<form [formGroup]="form">
  <mat-card class="form-container">
    <mat-form-field class="w-full">
      <mat-label>{{'createMedia.name' | translate}}</mat-label>
      <input matInput
             type="text"
             formControlName="name">
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>{{'createMedia.client' | translate}}</mat-label>
      <mat-select formControlName="client">
        <mat-option *ngFor="let client of clients"
                    [value]="client.id">
          {{client.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>{{'createMedia.tags' | translate}}</mat-label>
      <flow-multi-select-autocomplete formControlName="tags"
                                      [options]="tags"
                                      [displayFn]="displayTag"
                                      [newOptionFn]="newTag"></flow-multi-select-autocomplete>
    </mat-form-field>

    <mat-form-field *ngIf="isAdmin && !this.isModal && !this.customerIdModal"
                    class="w-full">
      <mat-label>{{'createMedia.customer' | translate}}</mat-label>
      <mat-select formControlName="customerId" (selectionChange)="getTagsAndClientsByCustomer()">
        <mat-option *ngFor="let customer of customers"
                    [value]="customer.id">
          {{customer.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="upload-field"
                    *ngIf="!media?.imageUrl">
      <div>
        <mat-toolbar>
          <input matInput
                 [value]="selectedFile ? selectedFile.name : 'createMedia.selectFile' | translate" />
          <!-- <mat-divider [vertical]="true"></mat-divider> -->
          <button *ngIf="selectedFile"
                  mat-icon-button
                  color="warn"
                  (click)="clearFile()">
            <mat-icon>clear</mat-icon>
          </button>

          <input type="file"
                 id="fileInput"
                 name="fileInput"
                 accept="image/*, video/*"
                 [ngClass]="{ 'clear-visible' : !!selectedFile }"
                 (change)="selectFile($event)" />
        </mat-toolbar>
      </div>
    </mat-form-field>
    <mat-card *ngIf="!mediaId" class="alert alert--info mb-3 mt-3 p-3" style="margin-top: 0 !important;">
      <span>{{'createMedia.infoImageAndVideo' | translate}}</span>
    </mat-card>
    <div class="preview">
      <ng-container *ngIf="filePreview">
        <img *ngIf="format === 'image'"
             [src]="filePreview">

        <mat-card *ngIf="format ==='video'"
                  class="alert alert--info mb-3">{{'createMedia.noPreviewMessage' | translate}}</mat-card>
        <!-- <video *ngIf="format ==='video'"
               [src]="filePreview"
               controls></video> -->
      </ng-container>
      <img *ngIf="!filePreview && media?.type === MediaTypeEnum.Image && media?.imageUrl"
           [src]="media.imageUrl">
      <video *ngIf="!filePreview && media?.type === MediaTypeEnum.Video && media?.imageUrl"
             [src]="media.imageUrl"
             controls></video>
    </div>
  </mat-card>

  <mat-toolbar class="toolbar-form-actions"
               *ngIf="!isModal">
    <div class="actions">
      <button type="button"
              mat-stroked-button
              class="btn-cancel"
              [routerLink]="['/medias']">
        {{'global.cancel' | translate}}
      </button>
    </div>
  </mat-toolbar>

  <div *ngIf="isModal"
       class="flex items-center justify-end mt-8">
    <button mat-button
            type="button"
            (click)="onCancelClick()">{{'global.cancel' | translate}}</button>
    <button mat-raised-button
            type="button"
            [disabled]="!form.valid"
            (click)="onSaveClick()"
            color="primary"
            cdkFocusInitial>{{'global.save' | translate}}</button>
  </div>
</form>