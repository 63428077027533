import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { BookingApiService } from '@api/services/booking-api.service';
import { catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookingsResolverService implements Resolve<any> {

  constructor(private bookingApiService: BookingApiService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const engagementIdParam = this.router.getCurrentNavigation().extras.state?.id;
    const engagementId = engagementIdParam ? Number(engagementIdParam) : null;

    if (!engagementId || isNaN(engagementId)) {
      return of(null);
    }

    return this.bookingApiService.getExposureDetails(engagementId).pipe(
      catchError(() => {
        return of(null);
      })
    )
  }
}
