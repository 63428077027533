<div class="flex justify-end items-center gap-2">
    <app-icon [stroke]="iconColor" 
        [fill]="calculateUsedStoragePercentage() === 100 ? iconColor : 'none'" 
        size="md" iconName="cloud">
    </app-icon>
    <div class="text-xs font-normal leading-none" [ngClass]="textColorClass">
        {{ "media.usedData" | translate : { usedBytes: sizeOfMediaAndFolders | filesize, totalBytes: storageLimitSize } }}
    </div>
</div>
<div *ngIf="showSizeOfFolder" class="text-xs font-normal leading-none mt-1" [ngClass]="textColorClass">
    {{ 'media.currentFolderSize' | translate }} {{ sizeOfFolder | filesize }}
</div>
