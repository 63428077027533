import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ScreenApi, Screen } from '@api/index';
import { unsubscribeMixin } from '@app/core/unsubscribe';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'flow-bulk-update-ai-add',
  templateUrl: './bulk-update-ai-add.component.html',
  styleUrls: ['./bulk-update-ai-add.component.scss']
})
export class BulkUpdateAiAddComponent extends unsubscribeMixin() implements OnInit {
  aiVersions: string[];
  advertiserVersions: string[];
  form: FormGroup;
  screenIds: number[];
  selectedScreens: Screen[];

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BulkUpdateAiAddComponent>,
    private screenApi: ScreenApi
  ) {
    super();
   }

  ngOnInit(): void {
    this.screenIds = this.data.screenIds
    this.selectedScreens = this.data.selectedScreens
    this.initForm();
    this.screenApi.versions()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(versions => {
        this.aiVersions = versions.aiVersions;
        this.advertiserVersions = versions.advertiserVersions;
    })
  }

  onSaveClick(){
    const body = this.form.getRawValue()
    const screensToUpdate = this.data.screens.map((s: Screen) => {
     return {
       ...s,
       advertiserVersion: body.advertiserVersion || s.advertiserVersion,
       aiVersion: body.aiVersion || s.aiVersion,
       customerId: s.customer.id
     }
    })

  this.screenApi.editScreen(screensToUpdate)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => this.dialogRef.close({save: true}))
  }

  onCancel(){
    this.dialogRef.close()
  }

  private initForm(){
    this.form = this.formBuilder.group({
      aiVersion: ['', [Validators.required]],
      advertiserVersion: ['', [Validators.required]],
    })
  }

  selectAI(version: string) {
    if(version){
      this.form.get('advertiserVersion').clearValidators()
      this.form.get('advertiserVersion').updateValueAndValidity()
    }
  }


  selectAd(version: string) {
    if(version){
      this.form.get('aiVersion').clearValidators()
      this.form.get('aiVersion').updateValueAndValidity()
    }
  }
}
