import { IdName } from "@api/models/idname";
import { Media } from "@app/core/services/media.service";
import { DateTime } from "luxon";

export interface MediaFilterValues {
    text?: string;
    filterTypes?: number[];
    tags?: number[];
    customerIds?: number[];
    clientIds?: number[];
    filteredStartDate?: DateTime;
    filteredEndDate?: DateTime;
    created? : {
      start?: DateTime;
      end?: DateTime;
    };
    lastUsed?: {
      start?: DateTime;
      end?: DateTime;
    };
    showExtendedDetails?: boolean;
  }
  
  export interface MediaListItem extends Media {
    isSelected?: boolean;
    openFolder?: boolean;
    impressions?: number;
    typeText?: string;
    customerName?: string;
    clientName?: string;
    filesizeFormat?: string;
    resolution?: string;
    tagsName?: string;
    folderName?: string;
    formattedDuration?: string;
  }

  export enum MediaUploadStatusEnum {
    Approved = 1,
    Pending = 2,
    Created = 3
  }

  export interface MediaDetails {
    created: string;
    createdOrApprovedBy: string; 
    customer: IdName
    id: number;
    name: string; 
    duration?: number;
    formattedDuration: string;
    impressions: number; 
    mediaUploadStatus: MediaUploadStatusEnum; 
    screenLayout?: string; 
    size?: number; 
  }