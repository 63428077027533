<div mat-dialog-title class="media-details-title">
    <h3 class="font-semibold">{{'media.filters.title' | translate}}</h3>
    <a class="cursor-pointer" (click)="close()">
      <app-icon stroke="#202020" size="md" iconName="x"></app-icon>
    </a>
</div>
<div class="media-details-content">
    <div class="w-fit">
        <span class="media-details-label">{{'createMedia.customer' | translate}}:</span>
        <span class="media-details-value"> {{data.customer.name}}</span>
    </div>
    <div class="w-fit" *ngIf="data.type !== 3 && (data.resolution || data.screenLayout)">
        <span class="media-details-label">{{'media.table.layout' | translate}}:</span>
        <span class="media-details-value"> {{data.resolution || data.screenLayout}}</span>
    </div>
    <div class="w-fit">
        <span class="media-details-label">{{'media.table.filesize' | translate}}:</span>
        <span class="media-details-value"> {{data.filesizeFormat}}</span>
    </div>
    <div class="w-fit" *ngIf="data.duration">
        <span class="media-details-label">{{'media.table.duration' | translate}}:</span>
        <span class="media-details-value"> {{data.duration}}</span>
    </div>
    <div class="w-fit" *ngIf="data.type != 3">
        <span class="media-details-label">{{'media.table.impressions' | translate}}:</span>
        <span class="media-details-value"> {{data.impressions}}</span>
    </div>
    <div class="w-fit">
        <span class="media-details-label">{{'media.table.created' | translate}}:</span>
        <span class="media-details-value"> {{ data.created }}</span>
    </div>
    <div class="w-fit flex gap-1" *ngIf="data.createdOrApprovedBy">
        <span class="media-details-label">{{'media.table.createdOrApprovedBy' | translate}}:</span>
        <span class="media-details-value"> {{ data.createdOrApprovedBy }}</span>
        <span *ngIf="data.mediaUploadStatus === mediaUploadStatusEnum.Approved">
            <app-icon size="sm"
            stroke="#202020"
            iconName="approved-by"></app-icon>
        </span>
    </div>
</div>