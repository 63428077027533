import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, forkJoin, of, takeUntil} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {AuthService, UserRoleEnum} from "@core/auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import { unsubscribeMixin } from '@app/core/unsubscribe';
import { Customer, CustomerApi, PersonOfInteresetApi } from '@api/index';
import { ContactPerson } from '@api/models/contact-person';
import { SystemMessageService } from '@app/core/services/system-message.service';
import { Category } from '@api/models/category';

@Component({
  selector: 'flow-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss']
})
export class CreateCategoryComponent extends unsubscribeMixin() implements OnInit {
  editMode: boolean;
  customerId: number;
  isAdmin: boolean;
  customers: Customer[];
  form: FormGroup;
  contactPersons: ContactPerson[]
  canSendSms = false;
  categoryId: number;
  data: any

  loading$ = {
    init: new BehaviorSubject(true),
    save: new BehaviorSubject(false),
    upload: new BehaviorSubject(false)
  }

  constructor(
    private customerApi: CustomerApi,
    private poiApi: PersonOfInteresetApi,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    public systemMessageService: SystemMessageService,
    private translateService: TranslateService,
  ) {
    super();
    this.categoryId = +this.activatedRoute.snapshot.params['id'] || null;
    this.customerId = +this.activatedRoute.snapshot.params['customerId'] || null;
    this.initForm();
  }


  ngOnInit(): void {
    this.editMode = !!this.categoryId
    const user = this.authService.userData;
    if (!this.editMode) {
      this.customerId = user?.currentCustomerId || user?.customerId;
    }
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId && !user.customerId;

    forkJoin([
      this.customerApi.getCustomers(),
      this.categoryId ? this.poiApi.getCategoryDetails(this.categoryId, this.customerId) : of(null),
    ]).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([customers, category]) => {
        this.customers = customers;
        this.canSendSms = category?.shouldSendSmsNotifications
        this.initForm(category)
      })

    if (this.customerId) {
      this.poiApi.getContactPerson(this.customerId)
        .pipe(
          takeUntil(this.ngUnsubscribe))
        .subscribe((data: any) => {
          this.contactPersons = data
        })
    }
  }

  changeCustomer(customerId, event) {
    if (event.isUserInput) {
      this.customerId = customerId
      this.poiApi.getContactPerson(customerId)
        .pipe(
          takeUntil(this.ngUnsubscribe))
        .subscribe((data: any) => this.contactPersons = data)
    }
  }

  onSave() {
    this.form.markAllAsTouched();
    const category = this.form.getRawValue();
    const inboundSms = this.form.get("shouldSendSmsForInboundScreens").value
    const outboundSms = this.form.get("shouldSendSmsForOutboundScreens").value
    const sensorSms = this.form.get("shouldSendSmsForNotApplicableScreens").value
    const sendSms = this.form.get("shouldSendSmsNotifications").value

    if (sendSms) {
      if ((!inboundSms && !outboundSms && !sensorSms)) {
        this.systemMessageService.error(
          this.translateService.instant('notifications.error.noSelection'))
        return;
      }
    }

    if (this.editMode && this.form.valid) {
      this.poiApi.updateCategory(category).subscribe(data => {
        this.router.navigate(['/person-of-interest'], {state: {page: 1}})
      })

    }
    if (!this.editMode && this.form.valid) {
      this.poiApi.saveCategory(category).subscribe(data => {
        this.router.navigate(['/person-of-interest'], {state: {page: 1}})
      })
    }
  }

  shouldSendSms() {
    this.canSendSms = !this.canSendSms
    this.form.get('shouldSendSmsForInboundScreens').patchValue(true)
    this.addRemoveValidation()
  }

  onClose() {
    this.router.navigate(['/person-of-interest'])
  }

  private addRemoveValidation() {
    if (this.canSendSms) {
      this.form.get("contactPersonIds").setValidators([Validators.required])
      this.form.get("contactPersonIds").updateValueAndValidity()

      this.form.get("maxMinutes").setValidators([Validators.required])
      this.form.get("maxMinutes").patchValue(this.data?.category?.maxMinutes || 15)
      this.form.get("maxMinutes").updateValueAndValidity()

      this.form.get("maxSms").setValue(this.data?.category?.maxSms || 2)
      this.form.get("maxSms").setValidators([Validators.required])
      this.form.get("maxSms").updateValueAndValidity()
    } else {
      this.form.get("contactPersonIds").clearValidators()
      this.form.get("contactPersonIds").setValue([])
      this.form.get("contactPersonIds").updateValueAndValidity()
      this.form.get("maxMinutes").clearValidators()
      this.form.get("maxMinutes").setValue(null)
      this.form.get("maxMinutes").updateValueAndValidity()
      this.form.get("maxSms").clearValidators()
      this.form.get("maxSms").setValue(null)
      this.form.get("maxSms").updateValueAndValidity()
      this.form.get("shouldSendSmsForInboundScreens").setValue(false)
      this.form.get("maxSms").updateValueAndValidity()
      this.form.get("shouldSendSmsForOutboundScreens").setValue(false)
      this.form.get("maxSms").updateValueAndValidity()
      this.form.get("shouldSendSmsForNotApplicableScreens").setValue(false)
      this.form.get("maxSms").updateValueAndValidity()
    }
  }

  private initForm(category?: Category): void {

    this.form = this.formBuilder.group({
      id: category?.id,
      name: [category?.name, Validators.required],
      customerId: this.customerId,
      contactPersonIds: [category?.contactPersons.map((x: ContactPerson) => x.id)] || [],
      shouldSendSmsForInboundScreens: category?.shouldSendSmsForInboundScreens || true,
      shouldSendSmsForOutboundScreens: category?.shouldSendSmsForOutboundScreens || false,
      shouldSendSmsForNotApplicableScreens: category?.shouldSendSmsForNotApplicableScreens || false,
      shouldSendSmsNotifications: category?.shouldSendSmsNotifications || false,
      doNotCountInStatistics: typeof category?.doNotCountInStatistics == "undefined" ? true : category?.doNotCountInStatistics,
      maxMinutes: category?.maxMinutes,
      maxSms: category?.maxSms,
    });
  }

}


