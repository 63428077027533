import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MediaInUseModel } from "@api/models/media-in-use-model";
import { BackendErrorsTranslate } from "@app/core/be-errors-translations";
import { ContentData, CustomSnackbarComponent } from "@app/shared/custom-snackbar/custom-snackbar.component";
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class MediaNotificationService {
    constructor(private translateService: TranslateService) {}
    
    initializeMessage(): ContentData {
     return {
       title: '',
       content: '',
       preText: '',
       listContent: '',
     };
   }

   processMediaList(
     medias: MediaInUseModel[] | undefined,
     singleMedia: MediaInUseModel | undefined,
     message: ContentData,
     errorType: string,
     buildMedia: (media: MediaInUseModel, message: ContentData, errorType: string) => void
   ): void {
     if (medias && Array.isArray(medias)) {
       medias.forEach((media) => buildMedia(media, message, errorType));
     } else {
         buildMedia(singleMedia, message, errorType);
     }
   }

   buildMedia(media: MediaInUseModel, message: ContentData, errorType: string) {
       let mediaName = media.mediaName;
       message.title = 'media.cannotDelete';
       message.content = this.translateService.instant(BackendErrorsTranslate[errorType]);
       message.preText = this.translateService.instant('media.usedMediasList');
       media.mediaUsedAtEngagements.filter((engagement: any) => {
         let type =
           engagement.engagementType === 1
             ? 'editSegment.segmentName'
             : 'editPlaylist.playlistName';
           message.listContent +=
           '<li><strong>' +
           mediaName +
           ':</strong> ' +
           this.translateService.instant(type) +
           ' <strong>' +
           engagement.engagementName +
           '</strong></li>';
       });
       media.mediaUsedAsADefaultMediaAtScreens.filter((screen: any) => {
         message.listContent +=
           '<li><strong>' +
           mediaName +
           ':</strong> ' +
           this.translateService.instant('media.defaultMediaForScreen') +
           ' <strong>' +
           screen +
           '</strong></li>';
       });
       media.mediaUsedAsADefaultMediaAtSegments.filter((segment: any) => {
         message.listContent +=
           '<li><strong>' +
           mediaName +
           ':</strong> ' +
           this.translateService.instant('media.defaultMediaForSegment') +
           ' <strong>' +
           segment +
           '</strong></li>';
       });
    }

   openErrorDialog(dialog: MatDialog, message: ContentData): void {
     dialog.open(CustomSnackbarComponent, {
       width: '600px',
       data: { msg: message },
     });
   }
}
