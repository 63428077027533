import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { IdName } from '@api/models/idname';
import { MediaFilterRequest } from '@api/models/medias/medias';
import { BookingApi } from '@api/services/bookings-api';
import { BookingsFiltersComponent } from '@app/admin/engagement/bookings/bookings-filters/bookings-filters.component';
import { EnumService } from '@app/core/services/enum.service';
import { MediaService } from '@app/core/services/media.service';
import { DateTime } from 'luxon';
import { Observable, startWith, Subject, switchMap, take, takeUntil } from 'rxjs';

@Component({
  selector: 'flow-media-filters',
  templateUrl: './media-filters.component.html',
  styleUrls: ['./media-filters.component.scss']
})
export class MediaFiltersComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  @ViewChild(MatAccordion) accordion: MatAccordion;
  showPeriod = true;
  customers: IdName[] = [];
  clients$: Observable<{ id: number, name: string }[]>;
  tags$: Observable<{ id: number, name: string }[]>;
  tags: IdName[];
  bookingsTotal = 0;
  customerId: number;
  today: string = new Date().toISOString();
  types: IdName[] = [];
  isReady: boolean = false;

  form = this.fb.group({
    currentMonth: [null],
    filterTypes: [null],
    tags: [null]
  })

  constructor(
    private fb: FormBuilder,
    private bookingApi: BookingApi,
    private mediaService: MediaService,
    private enumService: EnumService,
    public dialogRef: MatDialogRef<BookingsFiltersComponent>,
  ) {}

  ngOnInit(): void {
    this.getSelectedFilters();
    this.getTags();
  }

  formUpdate() {
    this.form.valueChanges.pipe(
      startWith({
        filteredStartDate: DateTime.local().toISODate(),
        filteredEndDate: DateTime.local().toISODate()
      }),
      switchMap((filters: any) => this.bookingApi.getBookingTotal(filters)),
      takeUntil(this.destroy$)
    ).subscribe({
      next: (total: number) => {
        this.bookingsTotal = total
      },
      error: (error) => { console.log(error) }
    })
  }

  getSelectedFilters(): void {
    this.mediaService.selectedFilters$
      .pipe(take(1))
      .subscribe((filters: MediaFilterRequest) => {
        if (filters) {
          this.form.patchValue(filters)
        }
      })
  }

  getTags(): void {
    this.mediaService.customerTags$
    .pipe(take(1))
    .subscribe((tags: IdName[]) => {
      this.types = this.enumService.mediaTypes;
      if (tags.length){
        this.tags = tags;
        this.form.get('tags').enable();
      } else {
        this.form.get('tags').disable();
      }
    })
  }

  clearEndDate() {
    this.form.get('filteredEndDate').reset()
  }

  togglePeriod() {
    this.showPeriod = !this.showPeriod;
  }

  clearAll() {
    this.mediaService.setFilters(null);
    this.form.reset({
      currentMonth: null,
      filterTypes: null,
      tags: null
    });
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.mediaService.setFilters(this.form.value);
    this.dialogRef.close(this.form.value);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
