import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, delay, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { UserApi, UserLoginResponse } from 'src/_api';
import { STORAGE_KEY_AUTH } from '../constants';
import { AuthService } from './auth/auth.service';
import { SystemMessageService } from './services/system-message.service';
import { TranslateService } from '@ngx-translate/core';
import { BackendErrorsService } from './backend-errors.service';
import { BackendErrorsTranslate } from './be-errors-translations';
import { LoaderService } from './loader/loader.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  genericErrorMessage = 'notifications.error.techIssue';
  currentSnackbar: MatSnackBarRef<SimpleSnackBar>;
  currentErrorEndpoint: string;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private authService: AuthService,
    private systemMessageService: SystemMessageService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private backendErrors: BackendErrorsService,
    private router: Router,
    public dialog: MatDialog,
    public userApi: UserApi
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const auth: UserLoginResponse = JSON.parse(
      localStorage.getItem(STORAGE_KEY_AUTH)
    );
    
    if (req.url.includes('/profile') && req.method === 'POST') {
      if (auth?.token && !this.authService.tokenExpired(auth)) {
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + auth.token,
            RefreshToken: auth.refreshToken,
          },
        });
      }
    }
    if (auth?.token && !this.authService.tokenExpired(auth)) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
    }
    this.loaderService.show();
    return next.handle(req).pipe(
      delay(this.loaderService.requestDelay),
      finalize(() => this.loaderService.hide()),
      tap({
        next: (x) => x,
        error: (err: HttpErrorResponse) => { },
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (this.authService.tokenExpired()) {
            return this.handle401Error(req, next);
          } else {
            this.systemMessageService.error(
              'Unauthorized call. Please log in again to use the system.'
            );
            return this.authService.handleUnauthorized(error);
          }
        }
        if (!error) {
          return throwError(
            () =>
              new Error(this.translateService.instant(this.genericErrorMessage))
          );
        }

        if (error.status === 400) {
          if (error.error.errorType === 'MEDIA_FILE_TO_LARGE')
            this.systemMessageService.error(
              this.translateService.instant('notifications.error.fileToLarge')
            );
          else if (error.error.errorType === 'MEDIAS_IN_USE') {
          }
        }
        const backendResponse = error.error;
        if (backendResponse?.errors && typeof backendResponse.errors === 'object' ) {
          let errors: string[] = [];
          const keys = Object.keys(backendResponse.errors);
          keys.forEach((key) => {
            const error = backendResponse.errors[key];
            if (typeof error === 'string') {
              errors.push(error);
            } else if (error?.length) {
              errors = errors.concat(error);
            }
          });
          const msg = errors.join(' ');
          this.systemMessageService.error(msg);
        } else if (typeof backendResponse === 'string') {
          this.systemMessageService.error(backendResponse.slice(0, 500));
        } else if (backendResponse?.length) {
          let errors: string[] = [];
          backendResponse.forEach((error: any) => {
            if (typeof error === 'string') {
              errors.push(error);
            } else if (error?.length) {
              errors = errors.concat(error);
            } else if (error?.description) {
              errors.push(error.description);
            }
          });
          const msg = errors.join(' ');
          this.systemMessageService.error(msg);
        } else if (backendResponse?.errorType && BackendErrorsTranslate[backendResponse?.errorType]) {
          this.backendErrors.handleErrors(error);
        } else if (error.status === 403) {
          return throwError(() => backendResponse);
        }else {
          this.systemMessageService.error( this.translateService.instant(this.genericErrorMessage));
          return throwError(() => new Error(this.translateService.instant(this.genericErrorMessage)));
        }
        return throwError(() => backendResponse);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = JSON.parse(localStorage.getItem(STORAGE_KEY_AUTH));
      return this.userApi
        .refreshToken({ body: { refreshToken: token?.refreshToken } })
        .pipe(
          switchMap((response: any) => {
            let authToken: UserLoginResponse = JSON.parse(
              localStorage.getItem(STORAGE_KEY_AUTH)
            );
            const decoded = jwtDecode(response.token);
            const dateObj = new Date(decoded['exp'] * 1000);
            const formattedDate = this.authService.formatExpireDate(dateObj);
            authToken = {
              ...authToken,
              token: response.token,
              expires: String(formattedDate),
            };
            localStorage.setItem(STORAGE_KEY_AUTH, JSON.stringify(authToken));
            this.isRefreshing = false;

            // this.tokenService.saveToken(token.accessToken);
            this.refreshTokenSubject.next(token.token);

            return next.handle(this.addTokenHeader(request, token.token));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.authService.logout();
            this.router.navigate(['/login']);
            return throwError(err);
          })
        );
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token),
    });
  }
}
