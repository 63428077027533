<div class="flex items-center justify-between">
        <h2 class="reach-section-title">{{ "exposures.totalNumberOfExposures" | translate }}</h2>
</div>
<mat-divider></mat-divider>
<div class="flex items-baseline justify-between selected-day">
        <p class="m-0">{{ mediaSubtitleData?.start | date: 'yyyy-MM-dd' }} - {{ mediaSubtitleData?.end | date: 'yyyy-MM-dd' }}</p>
</div>
<mat-divider *ngIf="totalNumberOfExposures"></mat-divider>
<br>
<div *ngIf="noChartData; else chartTemplate"
     class="flex justify-center flex-col items-center mt-5">
        <img src="../../../../../assets/no-chart-data.png" />
        <p class="mt-5">{{ "reach.noDataAvailable" | translate }}</p>
        <canvas baseChart
                height="50"></canvas>
</div>

<ng-template #chartTemplate>
        <canvas baseChart
                [data]="chartData"
                [type]="'bar'"
                [options]="chartOptions"
                [plugins]="chartPlugins"
                height="90vh">
        </canvas>
</ng-template>