import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MediaFile, MediaTypeEnum, StandardMedia } from '@api/index';
import { PaginatorParams } from '@api/models/paginator';
import { EnumService } from '@app/core/services/enum.service';
import { MediaModalService } from '@app/core/services/media-modal.service';
import { FilesizePipe } from '@app/shared/filesize-pipe/filesize.pipe';

@Component({
  selector: 'flow-media-clean-up-table',
  templateUrl: './media-clean-up-table.component.html',
  providers: [FilesizePipe],

})
export class MediaCleanUpTableComponent implements OnInit, OnChanges {
  pageSizeOptions = [5, 10, 25, 100];

  dataSource: MatTableDataSource<MediaFile>;
  mappedData: any[] = [];   
  selectedMediaIds: number[] = [];

  isReady: boolean = false;
  allSelected : boolean = true; 
  isAnySelected: boolean = true;

  mediaTypeEnum = MediaTypeEnum;

  columns = [
    { key: 'lastUsed', label: 'lastUsed'},
    { key: 'thumbnail', label: 'file'},
    { key: 'folder', label: 'folder' },
    { key: 'name', label: 'name'},
    { key: 'typeDescription', label: 'type'},
    { key: 'tags', label: 'tags' },
    { key: 'filename', label: 'filename'},
    { key: 'client', label: 'client'},
    { key: 'resolution', label: 'resolution'},
    { key: 'size', label: 'filesize'},
    { key: 'duration', label: 'duration' },
    { key: 'impressions', label: 'impressions' },
    { key: 'created', label: 'created'},
    { key: 'createdOrApprovedBy', label: 'createdOrApprovedBy'}
  ];

  displayedColumns: string[];

  @Input() data: MediaFile[];
  @Input() totalCount: number;
  @Input() params:PaginatorParams;
  @Output() paramsChange = new EventEmitter<PaginatorParams>();
  @Output() selectedMedia = new EventEmitter<number[]>();

  constructor(
    private enumService: EnumService, 
    private filesizePipe: FilesizePipe,    
    private mediaModalService: MediaModalService) { }

  ngOnInit(): void {
    this.displayedColumns = ['checkbox', ...this.columns.map((column) => column.key)];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.data && changes.data.currentValue){
      this.prepareData();
    }
  }

  toggleAllSelection(): void {
    this.allSelected = !this.allSelected;
    this.mappedData.forEach(row => (row.isSelected = this.allSelected));
    if( this.allSelected ){
      this.selectedMediaIds = this.mappedData.map(item => item.id);
    } else {
      this.selectedMediaIds = [];
    }
    this.isAnySelected = this.mappedData.some(row => row.isSelected);
  }

  toggleRowSelection(row: any): void {
    row.isSelected = !row.isSelected;
    if (row.isSelected) {
      this.selectedMediaIds.push(row.id);
    } else {
      this.selectedMediaIds = this.selectedMediaIds.filter(id => id !== row.id);
    }    
    this.isAnySelected = this.mappedData.some(row => row.isSelected);
  }

  getMediaType(value: number): string {
    return MediaTypeEnum[value] || '';
  }

  onParamsChange(){
    this.paramsChange.emit(this.params)
  }

  handlePageEvent(e: PageEvent) {
     if (this.isReady) {
      this.params = {
        ...this.params,
        pageNumber: e.pageIndex,
        pageSize: e.pageSize,
      };
      this.onParamsChange();      
    }
  }

  handleSortEvent(sort: Sort) {
    this.params = {
      ...this.params,
      pageNumber: 0,
      sortBy: sort?.active,
      sortDirection: sort.direction || (this.params.sortDirection == 'asc' ? 'desc' : 'asc') || 'asc'
    };
    this.onParamsChange();
  }

  openViewMedia(standardMedia: StandardMedia, $event): void {
      $event.preventDefault();
      $event.stopPropagation();
      if (standardMedia.type !== MediaTypeEnum.Folder)
      { this.mediaModalService.openViewMediaModal(standardMedia); }
  }

  onDelete(){
    this.selectedMedia.emit(this.selectedMediaIds);
  }

  prepareData() : void{
    this.transformData(this.data);
    this.selectedMediaIds = this.mappedData.map(item => item.id);
    this.dataSource = new MatTableDataSource(this.mappedData);
    this.isReady = true;
  }

  transformData(data: any[]): void {
    this.mappedData = data.map(item => ({
      ...item,
      isSelected: true,
      folder: item.folder?.name,
      client: item.client?.name,
      size: this.filesizePipe.transform(item.size),
      resolution: item.width && item.height ? item.width + 'x' + item.height : '',
      typeDescription: this.getMediaType(item.type),     
    }));
  }
}
