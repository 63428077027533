<div class="flex flex-col">
    <mat-label>{{ label | translate }}</mat-label>
    <mat-form-field color="red">
        <input 
        matInput 
        type="text" 
        [value]="value" 
        (input)="onInputChange($event)" 
        />
    </mat-form-field>
</div>