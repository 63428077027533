import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';

import { StatisticsApi } from '@api/index';
import { EnumService } from '@app/core/services/enum.service';
import { StatisticsFilterExposures } from '@app/models/statistics-filter-exposures';
import { TotalNumberOfExposures } from '@api/models/exposures/TotalNumberOfExposures.model';
import { StatisticsService } from '../../statistics.service';
import { MediaSubtitleData } from '../views.model';

@Component({
  selector: 'flow-view-chart-total-exposures',
  templateUrl: './view-chart-total-exposures.component.html',
  styleUrls: ['./view-chart-total-exposures.component.scss'],
  providers: [DatePipe]
})
export class ViewChartTotalExposuresComponent implements OnChanges {
  @Input() filter: StatisticsFilterExposures;
  @Input() totalNumberOfExposures: TotalNumberOfExposures[];
  @Input() mediaSubtitleData: MediaSubtitleData;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public chartPlugins = [DataLabelsPlugin];
  public weekDays = this.enumService.weekDays;
  public chartData: ChartData<'bar', number[]> = null;
  noChartData: boolean = false;
  public chartOptions: ChartConfiguration['options'] = {};

  constructor(protected statisticsApi: StatisticsApi,
    protected statisticsService: StatisticsService,
    protected translateService: TranslateService,
    protected enumService: EnumService
  ) {

    this.setChartOptions();
    this.translateService.onLangChange.subscribe(() => {
      this.setChartOptions();
      this.updateChartData();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.totalNumberOfExposures)
      this.updateChartData();
  }
  
  updateChartData() {
    if (!this.totalNumberOfExposures) {
      this.noChartData = true;
      return;
    }

    const dataSetWeek = {
      label: `${this.mediaSubtitleData?.start} - ${this.mediaSubtitleData?.end}`,
      data: this.totalNumberOfExposures ? this.totalNumberOfExposures.map(entry => entry.numberOfVisitors) : [],
      backgroundColor: '#FA6200',
      barPercentage: 0.5
    }

    this.chartData = {
      labels: this.totalNumberOfExposures ? this.totalNumberOfExposures.map((w) => this.translateService.instant(this.weekDays[w.day].name)) : [],
      datasets: [dataSetWeek]
    }
  }

  private setChartOptions() {
    this.chartOptions = {

      scales: {
        x: {
          type: 'category', // This line is important for X-axis to recognize labels
          title: {
            display: false,
          },
          grid: {
            display: true,
            tickColor: 'white',
          },
        },
        y: {
          grace: '5%',
          title: {
            display: false,
          },
          grid: {
            display: true,
            tickColor: 'white',
          },
        }

      },
      plugins: {
        legend: {
          position: 'left',
          align: 'start',
          labels: {
            boxWidth: 3,
            boxHeight: 30,
            textAlign: 'left',
            padding: 4
          },
        },
        datalabels: {
          anchor: 'end',
          align: 'end'
        }
      }
    }
  }
}
