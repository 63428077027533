<div class="w-full h-full flex flex-col" *ngIf="isReady">
  <div class="flex gap-10">
    <div class="self-stretch py-2 rounded flex items-center gap-2 min-w-[324px]">
      <app-icon
        iconName="annotation-info"
        size="md"
        class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
        stroke="#598fae">
      </app-icon>
      <span class="text-gray-900 text-sm font-semibold leading-[14px]">
        {{ 'book.generalInformation' | translate }}
      </span>
    </div>
    <div class="w-full flex justify-between items-start">
      <div class="p-4 bg-white rounded flex flex-col gap-2">
        <div class="px-2 border-l-4 border-chathams-blue-600 w-[330px] text-gray-800 text-xs font-normal leading-[18px]">
          {{ 'global.note' | translate }}
        </div>
        <div class="w-[330px] text-gray-800 text-xs font-normal leading-[18px]">
          {{ (mediaId ? 'editMedia.note' : 'createMedia.addMediaNote') | translate }}
        </div>   
      </div>
      <div class="flex items-start gap-4">
        <button
          (click)="onCancelClick()"
          class="p-4 bg-white rounded border border-gray-600 text-gray-800 text-sm font-medium leading-[14px] flex items-center gap-2 cursor-pointer">
          {{ 'global.cancel' | translate }}
        </button>
        <button
          (click)="onSaveClick()"
          [ngClass]="{ 'disabled': disableUpload || (mediaId ? form.invalid : false) }"
          class="p-4 bg-surface-surface-brand rounded text-white text-sm font-medium leading-[14px] flex items-center gap-2 cursor-pointer">
          {{ (mediaId ? 'global.save' : 'global.add') | translate }}
        </button>
      </div>
    </div>
  </div>
    <div class="h-full flex-col justify-start items-start gap-10 inline-flex">
      <form [formGroup]="form" class="grid lg:grid-cols-2 gap-10">
        <div class="w-[324px] flex-col items-start gap-4 flex">
          <div *ngIf="!mediaId" class="justify-start items-start gap-2 inline-flex">
            <mat-checkbox color="primary" formControlName="isClientBoxChecked" class="client-checkbox" [disabled]="uploadError"></mat-checkbox>
            <span class="text-gray-900 text-[0.75rem] font-normal ">{{'createMedia.moveClientMediaToFolder' | translate}}</span>
          </div>
          <div *ngIf="isAdmin" class="input-dimensions">
            <mat-label>{{ 'media.form.customer' | translate }} *</mat-label>
            <mat-form-field class="w-full">
              <mat-select formControlName="customerId"
                          [disabled]="uploadError"
                          placeholder="{{'media.form.customerPlaceholder' | translate}}"
                          (selectionChange)="getDataByCustomer()">
                <mat-option *ngFor="let customer of customers"
                            [value]="customer.id">
                  {{ customer.name | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <ng-container *ngIf="mediaId">
            <div class="input-dimensions">
              <mat-label>{{'media.table.name' | translate}} *</mat-label>
              <mat-form-field class="w-full bg-white rounded-md">
                <input matInput
                       type="text"
                       formControlName="name">
              </mat-form-field>
            </div>
            <div class="input-dimensions">
              <mat-label>{{ 'media.form.client' | translate }}</mat-label>
              <mat-form-field class="w-full bg-white rounded-md">
                <mat-select formControlName="client"
                            placeholder="{{'media.form.clientPlaceholder' | translate}}">
                  <mat-option *ngFor="let client of clients"
                              [value]="client.id">
                    {{ client.name | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="input-dimensions">
              <mat-label>{{ 'media.form.tags' | translate }}</mat-label>
              <mat-form-field class="w-full bg-white rounded-md">
                <flow-multi-select-autocomplete formControlName="tags"
                                                [options]="tags"
                                                [displayFn]="displayTag"
                                                [newOptionFn]="newTag"></flow-multi-select-autocomplete>
              </mat-form-field>
            </div>
            <div class="grid-folder-container bg-gray-50" (click)="openViewMedia(media, $event)">
              <img *ngIf="media.type === MediaTypeEnum.Image && media.imageUrl"
                    class="self-center"
                    [src]="apiBaseUrl + media.imageUrl"
                    [width]="media.renderedWidth"
                    [height]="media.renderedHeight"
                    [alt]="media.name">
              <div *ngIf="media.type === MediaTypeEnum.Video" class="thumbnail-placeholder flex justify-center">
                <mat-icon>play_circle</mat-icon>
              </div>
              <div *ngIf="media.type === MediaTypeEnum.Image && !media.imageUrl" class="thumbnail-placeholder flex justify-center">
                <mat-icon>visibility</mat-icon>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!mediaId && (form.get('customerId').value || customerId)" formArrayName="medias">
            <div *ngFor="let mediaGroup of mediasFormArray?.controls; let i = index"
                [formGroupName]="i"
                [ngClass]="{'flex flex-col p-4 gap-4 rounded': !mediaId, 'bg-gray-50': !mediaId && mediaGroup.value?.id != selectedMedia?.id, 'bg-chathams-blue-400': !mediaId && mediaGroup.value?.id == selectedMedia?.id,
                'hidden': (!selectedMedia && mediaGroup.value.name && mediaGroup.value.selectedFile) || (selectedMedia && mediaGroup.value?.id != selectedMedia.id)}">
              <div class="create-input-dimensions">
                <mat-label>{{'media.table.name' | translate}} *</mat-label>
                <mat-form-field class="w-full bg-white rounded-md">
                  <input matInput
                         type="text"
                         formControlName="name">
                </mat-form-field>
              </div>
              <div class="create-input-dimensions">
                <mat-label>{{ 'media.form.client' | translate }}</mat-label>
                <mat-form-field class="w-full bg-white rounded-md">
                  <mat-select formControlName="client"
                              placeholder="{{'media.form.clientPlaceholder' | translate}}">
                    <mat-option *ngFor="let client of clients"
                                [value]="client.id">
                      {{ client.name | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="create-input-dimensions">
                <mat-label>{{ 'media.form.tags' | translate }}</mat-label>
                <mat-form-field class="w-full bg-white rounded-md">
                  <flow-multi-select-autocomplete formControlName="tags"
                                                  [options]="tags"
                                                  [displayFn]="displayTag"
                                                  [newOptionFn]="newTag"></flow-multi-select-autocomplete>
                </mat-form-field>
              </div>

              <div *ngIf="!mediaId"
                   class="create-input-dimensions flex-col justify-start items-start gap-2 flex cursor-pointer"
                   [ngClass]="{ 'clear-visible' : !!selectedFile }">
                <div class="text-gray-900 text-sm font-normal leading-[18px]">{{'createMedia.selectMedia' | translate}}</div>

                <div class="flex gap-4 items-end">
                  <flow-drag-to-upload (selectedFile)="selectFile($event, mediaGroup.value?.id)"></flow-drag-to-upload>
                  <div (click)="selectedMedia ? saveMediaRow() : addMediaRow()" class="w-fit h-fit p-2 bg-surface-surface-brand rounded justify-center items-center gap-2 inline-flex">
                    <app-icon stroke="#fafafa" size="md" [iconName]="selectedMedia ? 'checked' : 'plus'"></app-icon>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="!mediaId" class="w-full h-full flex flex-col gap-4 lg:mt-[4.5rem]" 
              [ngClass]="{ 'lg:mt-[4.5rem]' : isAdmin, 'lg:mt-20' : !isAdmin }">
          <div *ngFor="let mediaGroup of mediasFormArray?.controls; let i = index"
               class="flex-col w-[365px] inline-flex p-1 rounded border-2 border-gray-200"
                [ngClass]="{
                    'bg-gray-50': mediaGroup.value?.id != selectedMedia?.id,
                    'bg-chathams-blue-400': mediaGroup.value?.id == selectedMedia?.id,
                    'hidden': (selectedMedia && !mediaGroup.value.selectedFile) || (!selectedMedia && !mediaGroup.value.selectedFile),
                    'border-red-500 bg-red-50': mediaGroup.value?.status === 'error' && uploadingDone,
                    'border-green-500 bg-green-50': mediaGroup.value?.status === 'success' && uploadingDone
                }">

            <div class="flex flex-row justify-between mb-1">
              <div class="justify-center items-center gap-2 flex">
                <div class="w-[60px] h-[49px] rounded flex justify-center items-center">
                  <img *ngIf="mediaGroup.value?.selectedFile?.format === 'image'"
                      class="max-w-[60px] max-h-[49px] rounded"
                      [src]="mediaGroup.value?.selectedFile?.filePreview" />
                  <mat-icon *ngIf="mediaGroup.value?.selectedFile?.format === 'video'">play_circle</mat-icon>
                </div>
                <div class="flex-col justify-center items-start gap-1 inline-flex">
                  <p class="text-gray-900 text-sm font-normal leading-[14px] mb-0">{{mediaGroup.value?.name}}</p>
                  <p class="w-[102px] text-gray-800 text-xs font-normal leading-none mb-0">{{mediaGroup.value?.selectedFile?.fileSize |
                    filesize}}
                  </p>
                </div>
              </div>
              <div *ngIf="!mediaId"
                  class="flex gap-2">
                <div class="p-2 bg-gray-50 rounded justify-center items-center gap-2 flex"
                  *ngIf="mediaGroup.value?.status !== 'success'"
                  (click)="selectMedia(mediaGroup.value)">
                  <app-icon [ngStyle]="{cursor:'pointer'}"
                            iconName="edit-pencil"
                            size="md"
                            stroke="#202020">
                  </app-icon>
                </div>
                <div class="p-2 bg-gray-50 rounded justify-center items-center gap-2 flex"
                  *ngIf="mediaGroup.value?.status !== 'success'"
                  (click)="onMediaFileSettingsRow(mediaGroup, i)">
                  <app-icon [ngStyle]="{cursor:'pointer'}"
                            iconName="x"
                            size="md"
                            stroke="#202020">
                  </app-icon>
                </div>
              </div>
            </div>
            <div class="flex w-full">
              <mat-progress-bar mode="determinate"
                                [value]="mediaGroup.value?.uploadProgress"
                                class="w-full rounded">
              </mat-progress-bar>
             </div>
          </div>
        </div>
      </form>
    </div>
</div>